/* eslint-disable no-mixed-operators */
function addPrefix(name: string): string {
	return `icon-${name}`;
}

export function setCookie(name: string, value: string, days: number): void {
	const cookieName: string = addPrefix(name);
	let expires = '';
	if (days) {
		const date: Date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = `; expires=${date.toUTCString()}`;
	}
	document.cookie = `${cookieName}=${value || ''}${expires}; path=/`;
}

export function getCookie(name: string): string | null {
	const cookieName: string = addPrefix(name);
	const cookieNameEquals = `${cookieName}=`;
	const ca: string[] = document.cookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c: string = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(cookieNameEquals) === 0) {
			return c.substring(cookieNameEquals.length, c.length);
		}
	}
	return null;
}

export function deleteCookie(name: string): void {
	const cookieName: string = addPrefix(name);
	document.cookie = `${cookieName}=; Max-Age=-99999999;`;
}
