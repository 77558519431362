/* eslint-disable indent */
import { createAction, handleActions, Action } from 'redux-actions';
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import { ROUTE_PATHS } from 'routes';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { OrderListResource, OrderResource } from 'util/api/swaggerApi/data-contracts';
import { getEcUserByStorage, removeItem, setItem } from 'util/storage';
import { gtmCancelOrder } from 'util/gtmEvent';
import { ProductData } from 'components/molecules/CartTable';

import { PaymentType } from 'enums/paymentType';
import { StorageKey } from 'enums/storageKey';
import { ApiError, BasePayload } from 'types/apiError';

import { modifiedCurrentUrl } from 'util/pushHistory';
import { State as GlobalState } from './reducers';
import { checkErrorStatus } from './signin';
import { RedirectFnType, RedirectInfo, RedirectType } from './redirect';
import { ModalTypes, openModal } from './modal';

export const MockUserOrderList: OrderListResource[] = [
	{
		id: 1,
		orderNo: 'string',
		orderStatus: 0,
		orderStatusLabel: '待付款',
		paidAmount: 10000,
		createdAt: '2023.05.05',
	},
	{
		id: 2,
		orderNo: 'string',
		orderStatus: 0,
		orderStatusLabel: '待付款',
		paidAmount: 10000,
		createdAt: '2023.05.05',
	},
	{
		id: 3,
		orderNo: 'string',
		orderStatus: 0,
		orderStatusLabel: '待付款',
		paidAmount: 10000,
		createdAt: '2023.05.05',
	},
];

export const MockUserDetail: OrderResource = {
	id: 0,
	userId: 0,
	orderNo: '訂單編號',
	orderStatus: 0,
	cathayAtmNo: 'string',
	tradeNo: '交易編號',
	cancellingAt: 'string',
	createdAt: '訂單建立時間',
	originAmount: 0,
	paidAmount: 0,
	paymentType: 'string',
	paymentStatus: 'string',
	paymentAt: 'string',
	paymentCount: 0,
	retryPaidAt: 'string',
	shipStatus: 0,
	shipNo: '1318142545',
	shipAmount: 0,
	shipType: 'string',
	storeNo: 'string',
	storeName: 'string',
	recipient: 'string',
	recipientMobile: 'string',
	recipientTelephone: 'string',
	expectedDeliveryTime: 'string',
	islandsType: 'string',
	shipAddress: 'string',
	shipNote: 'string',
	invoiceStatus: 'string',
	invoiceType: 'string',
	invoiceNo: 'string',
	invoiceAmount: 0,
	invoiceAddress: 'string',
	invoicingAt: 'string',
	carrierType: 'string',
	carrierNo: 'string',
	loveCode: 'string',
	taxName: 'string',
	taxNum: 'string',
	invoiceRandomNum: 'string',
	invoiceDetailData: 'string',
	invoiceFailureInfo: 'string',
	levelDiscount: 0.85,
	levelDiscountAmount: 0,
	discountAmount: 0,
	couponDiscountAmount: 0,
	orderNote: 'string',
	siteNote: 'string',
	csNote: 'string',
	items: [
		{
			id: 0,
			productableId: 0,
			type: 'string',
			name: '商品一',
			materialNo: 'string',
			image: 'string',
			originPrice: 900,
			paidPrice: 700,
			quantity: 3,
			detail: [
				{
					id: 0,
					name: 'string',
					materialNo: '自建料號(Mapping ERP)',
					quantity: 0,
				},
			],
			discounts: [
				{
					id: 1,
					title: '買百送千',
					type: 'DISCOUNT_PRICE',
					x: 1000,
					y: 100,
				},
			],
		},
		{
			id: 1,
			productableId: 0,
			type: 'string',
			name: 'string',
			materialNo: 'string',
			image: 'string',
			originPrice: 0,
			paidPrice: 0,
			quantity: 0,
			detail: [
				{
					id: 0,
					name: 'string',
					materialNo: '自建料號(Mapping ERP)',
					quantity: 0,
				},
			],
			discounts: [
				{
					id: 1,
					title: '買千送百',
					type: 'DISCOUNT_PRICE',
					x: 100,
					y: 100,
				},
			],
		},
	],
	coupons: [
		{
			id: 1,
			status: 'RECEIVED',
			note: '',
			archivedAt: '2023-01-01 00:00:00',
			receivedAt: '2023-01-01 00:00:00',
			usedAt: '2023-01-01 00:00:00',
			couponEvent: [
				{
					id: 1,
					name: '優惠券名稱',
					title: '優惠券標題',
					subTitle: '優惠券副標題',
					code: 'CODE123',
					briefDescription: '優惠券簡述',
					hashtag: '優惠券標籤',
					rule: '優惠券使用規則',
					notice: '優惠券使用注意事項',
					typeName: 'TYPE_SHIPPING_FREE',
					discountPrice: 100,
					discountPercent: 80,
					discountPercentLimitPrice: 100,
					minimunPrice: 100,
					count: 1000,
					limitCount: 1,
					receivedCount: 1,
					usedCount: 1,
					currentVisibilityRule: 'ALWAYS_ONLINE',
					eventBeginAt: '2023-01-01',
					eventEndAt: '2023-01-01',
					gifts: [
						{
							id: 1,
							name: '贈品名稱',
							briefDescription: '贈品簡述',
							type: '贈品類型',
							materialNo: '自建料號(Mapping ERP)',
							volume: 1.1,
							price: 100,
							image: 'gifts/xxx.png',
							visibilityRule: 'ALWAYS_ONLINE',
							currentVisibilityRule: 'ONLINE',
						},
					],
				},
			],
		},
	],
	freeShippings: [
		{
			id: 1,
			title: '免運活動名稱',
			briefDescription: '免運活動簡述',
			rule: '免運活動規則',
			notice: '免運活動注意事項',
			threshold: 100,
			type: 'FREE_SHIIPPING_OVER_X_AMOUNT',
			islandAllowed: true,
			eventBeginAt: '2023-01-01 00:00:00',
			eventEndAt: '2023-01-01 00:00:00',
			currentVisibilityRule: 'ONLINE',
		},
		{
			id: 2,
			title: '免運活動名稱',
			briefDescription: '免運活動簡述',
			rule: '免運活動規則',
			notice: '免運活動注意事項',
			threshold: 100,
			type: 'FREE_SHIIPPING_OVER_X_AMOUNT',
			islandAllowed: true,
			eventBeginAt: '2023-01-01 00:00:00',
			eventEndAt: '2023-01-01 00:00:00',
			currentVisibilityRule: 'ONLINE',
		},
	],
	thresholdRewards: [
		{
			id: 1,
			title: '滿額贈標題',
			briefDescription: '滿額贈簡述',
			rule: '滿額贈使用規則',
			notice: '滿額贈注意事項',
			type: 'GIFT',
			threshold: 100,
			eventBeginAt: '2023-01-01',
			eventEndAt: '2023-01-01',
			currentVisibilityRule: 'ONLINE',
			gifts: [
				{
					id: 1,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
				{
					id: 2,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
			],
		},
		{
			id: 2,
			title: '滿額贈標題',
			briefDescription: '滿額贈簡述',
			rule: '滿額贈使用規則',
			notice: '滿額贈注意事項',
			type: 'GIFT',
			threshold: 100,
			eventBeginAt: '2023-01-01',
			eventEndAt: '2023-01-01',
			currentVisibilityRule: 'ONLINE',
			gifts: [
				{
					id: 1,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
				{
					id: 2,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
			],
		},
	],
};

interface OrderListPayload {
	userOrderList: OrderListResource[];
}

interface OrderDetailPayload {
	userOrderDetail: OrderResource;
}

export interface State {
	loading: boolean;
	userOrderList: OrderListResource[];
	userOrderDetail: OrderResource;
	retryPaymentError: BasePayload;
}

export const defaultState: State = {
	loading: false,
	userOrderList: [],
	userOrderDetail: {},
	retryPaymentError: {
		status: -1,
	},
};

/**
 * 取得會員訂單
 */
export const getUserOrderList = createAction(
	'GET_USER_ORDER_LIST',
	() => async (dispatch: Dispatch) => {
		const { v1UserOrdersList } = api;
		try {
			const { data, status } = await v1UserOrdersList();
			if (status === 200) {
				const list = data?.data || [];
				return {
					userOrderList: list
						.sort((pre, next) => dayjs(next.createdAt).valueOf() - dayjs(pre.createdAt).valueOf())
						.map(order => ({
							...order,
							createdAt: dayjs(order.createdAt).isValid()
								? dayjs(order.createdAt).format('YYYY.MM.DD')
								: '',
						})),
				};
			}
			return { userOrderList: [] };
		} catch (error) {
			dispatch(checkErrorStatus(error as ApiError));
			return { userOrderList: [] };
		}
	},
);

export const getUserOrderDetailById = createAction<
	(dispatch: Dispatch) => Promise<OrderDetailPayload>,
	number
>('GET_USER_ORDER_DETAIL_BY_ID', id => async (dispatch: Dispatch) => {
	const { v1UserOrderDetail } = api;
	try {
		const { data, status } = await v1UserOrderDetail(id);
		if (status === 200) {
			const orderDetail = data?.data || {};
			const userOrderDetail: OrderResource = {
				...orderDetail,
				createdAt: dayjs(orderDetail.createdAt).isValid()
					? dayjs(orderDetail.createdAt).format('YYYY.MM.DD HH:mm')
					: '',
				invoicingAt: dayjs(orderDetail.invoicingAt).isValid()
					? dayjs(orderDetail.invoicingAt).format('YYYY.MM.DD HH:mm')
					: '',
				paymentAt: dayjs(orderDetail.paymentAt).isValid()
					? dayjs(orderDetail.paymentAt).format('YYYY.MM.DD HH:mm')
					: '',
				paidExpiredAt: dayjs(orderDetail.paidExpiredAt).isValid()
					? dayjs(orderDetail.paidExpiredAt).format('YYYY.MM.DD HH:mm')
					: '',
			};
			return {
				userOrderDetail,
			};
		}
		return { userOrderDetail: {} };
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return { userOrderDetail: {} };
	}
});

/**
 * 取消訂單
 */
const cancelOrderById = createAction(
	'CANCEL_ORDER_BY_ID',
	(id: number, totalPrice: number, items: ProductData[]) => async (dispatch: Dispatch) => {
		const { v1UserOrderCancelUpdate } = api;
		try {
			const { status } = await v1UserOrderCancelUpdate(id);
			if (status === 200) {
				await dispatch(getUserOrderDetailById(id));
				gtmCancelOrder(id, totalPrice, items);
				window.scrollTo(0, 0);
			}
		} catch (error) {
			dispatch(checkErrorStatus(error as ApiError));
			console.log(error);
		}
	},
);

export const clearUserOrderDetail = createAction('CLEAR_USER_ORDER_DETAIL');

const setRetryPaymentError = createAction(
	'SET_RETRY_PAYMENT_ERROR',
	(error: ApiError) => (dispatch: Dispatch) => {
		const { status } = error.error;

		if (status === 400) {
			dispatch(openModal(ModalTypes.ErrorModal));
			return { ...error.error };
		}

		return { ...defaultState.retryPaymentError };
	},
);

/**
 * 重新付款
 */
export const retryPayment = createAction(
	'RETRY_PAYMENT',
	(id: number, paymentType: PaymentType) => async (dispatch: Dispatch) => {
		const { v1UserOrderPaymentCreate } = api;

		const redirectInfo: RedirectInfo<unknown> = {
			type:
				paymentType === PaymentType.CATHAY_CREDIT_CARD
					? RedirectType.CATHAY_CREDIT_CARD_PAY
					: RedirectType.LINE_PAY,
			featureType: RedirectFnType.MEMBER_ORDER_DETAIL,
			backPath: `/${ROUTE_PATHS.memberOrderDetail}/${id}`,
			otherParams: modifiedCurrentUrl().searchParams.toString(),
		};

		setItem(StorageKey.REDIRECT_INFO, JSON.stringify(redirectInfo));

		try {
			// const format: RequestParams =
			// 	paymentType === PaymentType.CATHAY_CREDIT_CARD ? { format: 'text' } : { format: 'json' };

			const paymentData = await v1UserOrderPaymentCreate({
				order_id: id,
			}); // 此api回傳值需參考swagger

			const ecToken = getEcUserByStorage();

			switch (paymentType) {
				case PaymentType.CATHAY_CREDIT_CARD:
					// eslint-disable-next-line no-case-declarations
					// const winUrl = URL.createObjectURL(
					// 	new Blob([paymentData as string], { type: 'text/html' }),
					// );
					window.location.href = paymentData.view;
					break;
				case PaymentType.LINE_PAY:
					setItem(StorageKey.EC_USER_TEMP, ecToken);
					window.location.href = paymentData.data?.data?.data?.info?.paymentUrl?.web;
					break;
				default:
					break;
			}

			// if (status === 200) {
			// 	await dispatch(getUserOrderDetailById(id));
			// 	window.scrollTo(0, 0);
			// }
		} catch (error) {
			removeItem(StorageKey.REDIRECT_INFO);
			dispatch(checkErrorStatus(error as ApiError));
			dispatch(setRetryPaymentError(error as ApiError));
		}
	},
);

export const reducer = {
	order: handleActions<State, any>(
		{
			GET_USER_ORDER_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_ORDER_LIST_FULFILLED: (state, action: Action<OrderListPayload>) => ({
				...state,
				userOrderList: action.payload.userOrderList,
				loading: false,
			}),
			CLEAR_USER_ORDER_DETAIL: state => ({
				...state,
				userOrderDetail: {
					...defaultState.userOrderDetail,
				},
			}),
			GET_USER_ORDER_DETAIL_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_ORDER_DETAIL_BY_ID_FULFILLED: (state, action: Action<OrderDetailPayload>) => ({
				...state,
				userOrderDetail: action.payload.userOrderDetail,
				loading: false,
			}),
			SET_RETRY_PAYMENT_ERROR: (state, action: Action<BasePayload>) => ({
				...state,
				retryPaymentError: action.payload,
			}),
			RETRY_PAYMENT_PENDING: state => ({
				...state,
				loading: true,
			}),
			RETRY_PAYMENT_FULFILLED: state => ({
				...state,
				loading: false,
			}),
		},
		defaultState,
	),
};

const orderActionsMap = {
	getUserOrderList,
	cancelOrderById,
	retryPayment,
	getUserOrderDetailById,
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.order.loading,
	userOrderList: state.order.userOrderList,
	userOrderDetail: state.order.userOrderDetail,
	retryPaymentError: state.order.retryPaymentError,
});

type orderSelector = ReturnType<typeof mapHooksToState>;
type orderActionsMap = typeof orderActionsMap;

export const useOrder = () =>
	useRedux<orderSelector, orderActionsMap>(mapHooksToState, orderActionsMap);
