/**
 * 發票類型之列舉
 *
 * @export
 * @enum {number}
 */
export enum InvoiceType {
	/**
	 * 個人發票
	 */
	PERSON = 'PERSON',
	/**
	 * 公司發票
	 */
	COMPANY = 'COMPANY',
}
