import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { FlashSaleResource } from 'util/api/swaggerApi/data-contracts';
import { getImageFromStorage } from 'util/hook/getImageFromStorage';

import { VisibilityRuleType } from 'enums/visibilityRuleType';

import { State as GlobalState } from './reducers';

interface FlashSalesPayload {
	flashSales: FlashSaleResource[];
}

export const getFlashSales = createAction<Promise<FlashSalesPayload>>(
	'GET_FLASH_SALES',
	async () => {
		try {
			const { v1FlashSalesList } = api;
			const { data } = await v1FlashSalesList();
			const flashSales = data?.data
				?.map(flash => ({
					...flash,
					imageFirst: flash.imageFirst ? getImageFromStorage(flash.imageFirst) : '',
					imageSecond: flash.imageSecond ? getImageFromStorage(flash.imageSecond) : '',
				}))
				.filter(
					flash => flash.currentVisibilityRule === VisibilityRuleType.ONLINE,
				) as FlashSaleResource[];

			return { flashSales };
		} catch (e) {
			return { flashSales: [] };
		}
	},
);

// For Global State usage
export interface State {
	loading: boolean;
	flashSales: FlashSaleResource[];
}

export const defaultState: State = {
	loading: false,
	flashSales: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	flashSales: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_FLASH_SALES_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_FLASH_SALES_FULFILLED: (state, action: Action<FlashSalesPayload>) => ({
				...state,
				flashSales: action.payload.flashSales,
				loading: false,
			}),
		},
		defaultState,
	),
};

const flashSalesActionsMap = {
	getFlashSales,
};

const mapHooksToState = (state: GlobalState) => ({
	flashSales: state.flashSales.flashSales,
});

type flashSalesSelector = ReturnType<typeof mapHooksToState>;
type flashSalesActionsMap = typeof flashSalesActionsMap;

export const useFlashSales = () =>
	useRedux<flashSalesSelector, flashSalesActionsMap>(mapHooksToState, flashSalesActionsMap);
