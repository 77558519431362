import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import Link, { LinkType } from 'components/atoms/Link';

import styles from './index.module.css';

interface DecoratedLinkProperty {
	name: string;
	className?: string;
	type?: LinkType;
	to?: string;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	isActive?: boolean;
}

const DecoratedLink: React.FC<DecoratedLinkProperty> = ({
	name,
	to,
	onClick,
	type = 'internal',
	isActive = false,
	className,
}) => (
	<Link
		className={classnames(styles.decoratedLink, className, {
			[styles.active]: isActive,
		})}
		type={type}
		onClick={onClick}
		to={to}
	>
		{name}
	</Link>
);

export default DecoratedLink;
