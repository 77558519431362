import { StorageKey } from 'enums/storageKey';
import { SigninSuccessCallbackInfo } from 'models/signin';
import { getItem } from './storage';
import { parseQueryString } from './parseQueryString';

interface LineLoginState {
	info: SigninSuccessCallbackInfo;
	query: string;
}

const { LINE_LOGIN_CLIENT_ID, SELF_HOST_ENDPOINT, NODE_ENV } = process.env;

const redirectUrl =
	NODE_ENV === 'development'
		? 'https://icon-revision-sit.25demo.com/line-auth'
		: `${SELF_HOST_ENDPOINT}/line-auth` || 'https://icon-revision-sit.25demo.com/line-auth';

export const storeInfoForLineState = (query: string) => {
	// TODO: 若之後要交付 #550 的需求，請將下面的註解打開，並刪除 15 行代碼
	const info = getItem(StorageKey.SIGNIN_CALLBACK_INFO);
	const state: LineLoginState = {
		info: info ? JSON.parse(info) : {},
		query,
	};

	return encodeURIComponent(JSON.stringify(state));
};

export const parseStateFromLineQuery = (): LineLoginState => {
	const { state } = parseQueryString();
	const defaultResult: LineLoginState = {
		info: {
			backPath: '',
		},
		query: '',
	};

	if (!state) {
		return defaultResult;
	}

	const decodeQuery = decodeURIComponent(state);
	const stateData = JSON.parse(decodeQuery) as LineLoginState;
	return stateData;
};

export const lineLoginLink = (query: string) =>
	`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_LOGIN_CLIENT_ID}&redirect_uri=${redirectUrl}&state=${storeInfoForLineState(
		query,
	)}&scope=profile%20openid%20email`;
