import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { MessageBarResource } from 'util/api/swaggerApi/data-contracts';

import { VisibilityRuleType } from 'enums/visibilityRuleType';

import { State as GlobalState } from './reducers';

interface MessageBarPayload {
	messageBars: MessageBarResource[];
}

/**
 * @description 取得 MessageBar
 */
export const getMessageBar = createAction<Promise<MessageBarPayload>>(
	'GET_MESSAGE_BAR',
	async () => {
		try {
			const { v1MessageBarsList } = api;
			const { data } = await v1MessageBarsList();
			let list = data?.data || [];
			list = list.filter(item => item.currentVisibilityRule === VisibilityRuleType.ONLINE);
			return { messageBars: list };
		} catch (e) {
			return { messageBars: [] };
		}
	},
);

// For Global State usage
export interface State {
	loading: boolean;
	messageBars: MessageBarResource[];
}

export const defaultState: State = {
	loading: false,
	messageBars: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	messageBars: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_MESSAGE_BAR_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_MESSAGE_BAR_FULFILLED: (state, action: Action<MessageBarPayload>) => ({
				...state,
				messageBars: action.payload.messageBars,
				loading: false,
			}),
		},
		defaultState,
	),
};

const messageBarActionsMap = {
	getMessageBar,
};

const mapHooksToState = (state: GlobalState) => ({
	messageBars: state.messageBars.messageBars,
});

type messageBarSelector = ReturnType<typeof mapHooksToState>;
type messageBarctionsMap = typeof messageBarActionsMap;

/**
 * @description 公告訊息相關 hook
 * @returns
 */
export const useMessageBar = () =>
	useRedux<messageBarSelector, messageBarctionsMap>(mapHooksToState, messageBarActionsMap);
