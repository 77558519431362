import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import ProductsInnerRoute from './Inner';

const routes: CustomRoute = {
	path: '/products',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'products' */ './component')],
			render: ([Products]) => <Products />,
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
		},
		ProductsInnerRoute,
	],
};
export default routes;
