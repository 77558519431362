import { Action, createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';
import { State as GlobalState } from './reducers';

const { v1SystemMaintainSettingsList } = api;

export const getSystemMaintain = createAction('GET_SYSTEM_MAINTAIN', async () => {
	try {
		const { data } = await v1SystemMaintainSettingsList();
		const systemMaintain: Record<string, boolean | undefined> = {};
		data?.data?.forEach(system => {
			if (system?.type) {
				systemMaintain[system?.type] = system.isMaintained;
			}
		});
		return systemMaintain;
	} catch (e) {
		return {};
	}
});

export interface State {
	system: Record<string, boolean | undefined>;
}

export const defaultState: State = {
	system: {},
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	system: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_SYSTEM_MAINTAIN_FULFILLED: (
				state,
				action: Action<Record<string, boolean | undefined>>,
			) => ({
				...state,
				system: action.payload,
			}),
		},
		defaultState,
	),
};

const systemActionsMap = {
	getSystemMaintain,
};

const mapHooksToState = (state: GlobalState) => ({
	system: state.system.system,
});

type SystemSelector = ReturnType<typeof mapHooksToState>;
type SystemActionsMap = typeof systemActionsMap;

export const useSystem = () =>
	useRedux<SystemSelector, SystemActionsMap>(mapHooksToState, systemActionsMap);
