import React from 'react';
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

import { HeaderDropdownData } from 'components/organisms/HeaderDropdown';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import {
	UserInvoiceResource,
	UserRecipientResource,
	UserResource,
	V1UserInvoiceCreateRequestPayload,
	V1UserInvoiceUpdateRequestParams,
	V1UserRecipientCreateRequestPayload,
	V1UserRecipientUpdateRequestParams,
	V1UserUpdateRequestParams,
} from 'util/api/swaggerApi/data-contracts';

import { MemberFeatureType } from 'enums/memberFeatureType';

import Account from 'images/icon/account.inline.svg';
import Coupon from 'images/icon/coupon-icon.inline.svg';
import Order from 'images/icon/order.inline.svg';

import { ApiError } from 'types/apiError';
import { GetState, State as GlobalState } from './reducers';
import { closeModal } from './modal';
import { toast } from './toast';
import { getUserCouponList } from './coupon';
import { getUserOrderList } from './order';
import { checkErrorStatus } from './signin';

/**
 * 會員功能列表
 */
export const memberFeatureList: HeaderDropdownData[] = [
	{
		id: MemberFeatureType.ACCOUNT,
		name: '我的帳戶',
		icon: <Account />,
		to: `/member-centre?type=${MemberFeatureType.INFO}`,
		externalLink: '',
		expandData: [
			{
				id: MemberFeatureType.SPENDING_ACCUMULAYES,
				name: '消費累金',
				to: `/member-centre?type=${MemberFeatureType.SPENDING_ACCUMULAYES}`,
				externalLink: '',
				expandData: [],
			},
			{
				id: MemberFeatureType.INFO,
				name: '會員資料',
				to: `/member-centre?type=${MemberFeatureType.INFO}`,
				externalLink: '',
				expandData: [],
			},
			{
				id: MemberFeatureType.COMMON_RECIPIENT_INFORMATION,
				name: '常用收件人資料',
				to: `/member-centre?type=${MemberFeatureType.COMMON_RECIPIENT_INFORMATION}`,
				externalLink: '',
				expandData: [],
			},
			{
				id: MemberFeatureType.COMMON_INVOICE_INFORMATION,
				name: '常用發票資料',
				to: `/member-centre?type=${MemberFeatureType.COMMON_INVOICE_INFORMATION}`,
				externalLink: '',
				expandData: [],
			},
		],
	},
	{
		id: MemberFeatureType.ORDERS,
		name: '我的訂單',
		to: `/member-centre?type=${MemberFeatureType.ORDERS}`,
		externalLink: '',
		icon: <Order />,
		expandData: [],
	},
	{
		id: MemberFeatureType.COUPONS,
		name: '我的優惠券',
		to: `/member-centre?type=${MemberFeatureType.COUPONS}`,
		externalLink: '',
		icon: <Coupon />,
		expandData: [],
	},
];

interface UserInfoPayload {
	userInfo: UserResource;
}

interface RecipientInfoPayload {
	homeRecipientList: UserRecipientResource[];
	storeRecipientList: UserRecipientResource[];
}

interface InvoiceInfoPayload {
	personInvoiceList: UserInvoiceResource[];
	companyInvoiceList: UserInvoiceResource[];
}

export interface LevelRule {
	level?: number | undefined;
	money?: string | undefined;
	levelDiscount?: number | undefined;
}

// For Global State usage
export interface State {
	loading: boolean;

	/**
	 * 登入後取得的 token，可根據此來判斷是否登入
	 *
	 * @type {string}
	 * @memberof State
	 */
	ecUserToken: string;

	/**
	 * 會員資訊
	 *
	 * @type {UserResource}
	 * @memberof State
	 */
	userInfo: UserResource;

	/**
	 * 宅配收件人資訊
	 *
	 * @type {UserRecipientResource[]}
	 * @memberof UserInfoPayload
	 */
	homeRecipientList: UserRecipientResource[];

	/**
	 * 超商取貨收件人資訊
	 *
	 * @type {UserRecipientResource[]}
	 * @memberof UserInfoPayload
	 */
	storeRecipientList: UserRecipientResource[];

	/**
	 * 個人發票資訊
	 *
	 * @type {UserInvoiceResource[]}
	 * @memberof State
	 */
	personInvoiceList: UserInvoiceResource[];

	/**
	 * 公司用發票資訊
	 *
	 * @type {UserInvoiceResource[]}
	 * @memberof State
	 */
	companyInvoiceList: UserInvoiceResource[];

	levelRules: LevelRule[];
}

export const defaultState: State = {
	loading: false,
	ecUserToken: '',
	userInfo: {},
	homeRecipientList: [],
	storeRecipientList: [],
	personInvoiceList: [],
	companyInvoiceList: [],
	levelRules: [],
};

/**
 * 根據會員中心頁面取得相對應資料
 */
const getMemberCentreDataByType = createAction<void, MemberFeatureType>(
	'GET_MEMBER_CENTRE_DATA_BY_TYPE',
	type => (dispatch: Dispatch) => {
		switch (type) {
			case MemberFeatureType.INFO:
			case MemberFeatureType.SPENDING_ACCUMULAYES:
				dispatch(getUserInfo());
				dispatch(getLevelRules());
				break;
			case MemberFeatureType.COMMON_RECIPIENT_INFORMATION:
				dispatch(getRecipientList());
				break;
			case MemberFeatureType.COMMON_INVOICE_INFORMATION:
				dispatch(getInvoiceList());
				break;
			case MemberFeatureType.ORDERS:
				dispatch(getUserOrderList());
				break;
			case MemberFeatureType.COUPONS:
				dispatch(getUserCouponList());
				break;
			default:
				break;
		}
	},
);

/**
 * 取得會員資料
 */
export const getUserInfo = createAction('GET_USER_INFO', () => async (dispatch: Dispatch) => {
	const { v1UserList } = api;
	try {
		const { data } = await v1UserList();
		const userInfo = data?.data || {};
		const newUserInfo: UserResource = {
			...userInfo,
			birthday: dayjs(userInfo.birthday).isValid()
				? dayjs(userInfo.birthday).format('YYYY.MM.DD')
				: '',
		};
		return { userInfo: newUserInfo };
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return { userInfo: {} };
	}
});

/**
 * 清除會員資料
 */
export const clearUserInfo = createAction('CLEAR_USER_INFO');

/**
 * 更新會員資料
 */
export const updateUserInfo = createAction(
	'UPDATE_USER_INFO',
	(userInfo: V1UserUpdateRequestParams) => async (dispatch: Dispatch, getState: GetState) => {
		const { v1UserUpdate } = api;
		const {
			member: { userInfo: oldUserInfo },
		} = getState();

		const result: UserInfoPayload = {
			userInfo: oldUserInfo,
		};
		try {
			const { status, data } = await v1UserUpdate(userInfo);
			if (status === 200) {
				dispatch(closeModal());
				const id = nanoid();
				dispatch(toast({
					id,
					message: '資料編輯成功',
					type: 'success',
					color: 'green',
				}));
				const newUserInfo = data?.data || {};
				result.userInfo = {
					...newUserInfo,
					birthday: dayjs(newUserInfo.birthday).isValid()
						? dayjs(newUserInfo.birthday).format('YYYY.MM.DD')
						: '',
				};
			}
			return result;
		} catch (error) {
			console.log(error);
			return result;
		}
	},
);

/**
 * 取得常用收件人資料
 */
export const getRecipientList = createAction(
	'GET_RECIPIENT_LIST',
	() => async (dispatch: Dispatch) => {
		const { v1UserRecipientsList } = api;
		const defaultResult: RecipientInfoPayload = {
			homeRecipientList: [],
			storeRecipientList: [],
		};
		try {
			const { data, status } = await v1UserRecipientsList();

			if (status === 200) {
				const { HOME, STORE } = data?.data || {};
				return {
					homeRecipientList: HOME || [],
					storeRecipientList: STORE || [],
				};
			}
			return defaultResult;
		} catch (error) {
			dispatch(checkErrorStatus(error as ApiError));
			return defaultResult;
		}
	},
);

/**
 * 新增常用收件人資料
 */
export const createRecipientInfo = createAction(
	'CREATE_RECIPIENT_INFO',
	async (recipientInfo: V1UserRecipientCreateRequestPayload): Promise<void> => {
		const { v1UserRecipientCreate } = api;
		try {
			await v1UserRecipientCreate(recipientInfo);
		} catch (error) {
			console.log(error);
		}
	},
);

/**
 * 更新常用收件人資料
 */
export const updateRecipientInfo = createAction(
	'UPDATE_RECIPIENT_INFO',
	(recipientInfo: V1UserRecipientUpdateRequestParams) => async (dispatch: Dispatch) => {
		const { v1UserRecipientUpdate } = api;
		try {
			const { status } = await v1UserRecipientUpdate(recipientInfo);
			if (status === 200) {
				dispatch(closeModal());
				const id = nanoid();
				dispatch(toast({
					id,
					message: '資料編輯成功',
					type: 'success',
					color: 'green',
				}));
				dispatch(getRecipientList());
			}
		} catch (error) {
			console.log(error);
		}
	},
);

/**
 * 根據 id 刪除常用收人資料
 */
const deleteRecipientById = createAction(
	'DELETE_RECIPIENT_BY_ID',
	(recipientId: number) => async (dispatch: Dispatch) => {
		const { v1UserRecipientDelete } = api;
		try {
			const { status } = await v1UserRecipientDelete(recipientId);

			if (status === 200) {
				const id = nanoid();
				dispatch(toast({
					id,
					message: '資料刪除成功',
					type: 'success',
					color: 'green',
				}));
				dispatch(getRecipientList());
			}
		} catch (error) {
			console.log(error);
		}
	},
);

/**
 * 取得常用發票資料
 */
export const getInvoiceList = createAction('GET_INVOICE_LIST', () => async (dispatch: Dispatch) => {
	const { v1UserInvoicesList } = api;
	const defaultResult: InvoiceInfoPayload = {
		personInvoiceList: [],
		companyInvoiceList: [],
	};
	try {
		const { data, status } = await v1UserInvoicesList();
		if (status === 200) {
			const { PERSON, COMPANY } = data?.data || {};
			return {
				personInvoiceList: PERSON || [],
				companyInvoiceList: COMPANY || [],
			};
		}
		return defaultResult;
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return defaultResult;
	}
});

/**
 * 新增常用發票資料
 */
export const createInvoiceInfo = createAction(
	'CREATE_INVOICE_INFO',
	async (invoiceInfo: V1UserInvoiceCreateRequestPayload): Promise<void> => {
		const { v1UserInvoiceCreate } = api;
		try {
			await v1UserInvoiceCreate(invoiceInfo);
		} catch (error) {
			console.log(error);
		}
	},
);

/**
 * 更新常用發票資料
 */
export const updateInvoiceInfo = createAction(
	'UPDATE_INVOICE_INFO',
	(invoiceInfo: V1UserInvoiceUpdateRequestParams) => async (dispatch: Dispatch) => {
		const { v1UserInvoiceUpdate } = api;
		try {
			const { status } = await v1UserInvoiceUpdate(invoiceInfo);
			if (status === 200) {
				dispatch(closeModal());
				const id = nanoid();
				dispatch(toast({
					id,
					message: '資料編輯成功',
					type: 'success',
					color: 'green',
				}));
				dispatch(getInvoiceList());
			}
		} catch (error) {
			console.log(error);
		}
	},
);

/**
 * 根據 id 刪除常用發票資料
 */
const deleteInvoiceById = createAction(
	'DELETE_INVOICE_BY_ID',
	(invoiceId: number) => async (dispatch: Dispatch) => {
		const { v1UserInvoiceDelete } = api;
		try {
			const { status } = await v1UserInvoiceDelete(invoiceId);

			if (status === 200) {
				const id = nanoid();
				dispatch(toast({
					id,
					message: '資料刪除成功',
					type: 'success',
					color: 'green',
				}));
				dispatch(getInvoiceList());
			}
		} catch (error) {
			console.log(error);
		}
	},
);

export const getLevelRules = createAction('GET_LEVEL_RULES', () => async (dispatch: Dispatch) => {
	const { v1UserLevelRulesList } = api;
	try {
		const { data, status } = await v1UserLevelRulesList();
		if (status === 200) {
			return data?.data || [];
		}
		return [];
	} catch (error) {
		dispatch(checkErrorStatus(error as ApiError));
		return [];
	}
});

export const setEcUserToken = createAction<string, string>(
	'SET_EC_USER_TOKEN',
	token => token || '',
);

export const reducer = {
	member: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_INFO_FULFILLED: (state, action: Action<UserInfoPayload>) => ({
				...state,
				userInfo: action.payload.userInfo,
				loading: false,
			}),
			SET_EC_USER_TOKEN: (state, action: Action<string>) => ({
				...state,
				ecUserToken: action.payload,
			}),
			UPDATE_USER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			UPDATE_USER_INFO_FULFILLED: (state, action: Action<UserInfoPayload>) => ({
				...state,
				userInfo: action.payload.userInfo,
				loading: false,
			}),
			GET_RECIPIENT_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_RECIPIENT_LIST_FULFILLED: (state, action: Action<RecipientInfoPayload>) => ({
				...state,
				homeRecipientList: action.payload.homeRecipientList,
				storeRecipientList: action.payload.storeRecipientList,
				loading: false,
			}),
			GET_INVOICE_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_INVOICE_LIST_FULFILLED: (state, action: Action<InvoiceInfoPayload>) => ({
				...state,
				personInvoiceList: action.payload.personInvoiceList,
				companyInvoiceList: action.payload.companyInvoiceList,
				loading: false,
			}),
			GET_LEVEL_RULES_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LEVEL_RULES_FULFILLED: (state, action: Action<LevelRule[]>) => ({
				...state,
				levelRules: action.payload,
				loading: false,
			}),
			CLEAR_USER_INFO: state => ({
				...state,
				userInfo: {
					...defaultState.userInfo,
				}
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.member.loading,
	ecUserToken: state.member.ecUserToken,
	userInfo: state.member.userInfo,
	homeRecipientList: state.member.homeRecipientList,
	storeRecipientList: state.member.storeRecipientList,
	personInvoiceList: state.member.personInvoiceList,
	companyInvoiceList: state.member.companyInvoiceList,
	levelRules: state.member.levelRules,
	showIncompleteHint:
		Object.keys(state.member.userInfo).length === 0
			? false
			: !state.member.userInfo?.name || !state.member.userInfo?.mobileNumber,
});

const memberActionsMap = {
	getMemberCentreDataByType,
	deleteRecipientById,
	deleteInvoiceById,
	setEcUserToken,
	updateUserInfo,
};

type MemberSelector = ReturnType<typeof mapHooksToState>;
type MemberActionsMap = typeof memberActionsMap;

/**
 * @description 會員相關 hook
 * @returns
 */
export const useMember = () =>
	useRedux<MemberSelector, MemberActionsMap>(mapHooksToState, memberActionsMap);
