import { getProductCombinationById } from 'models/combination';
import { getFreeShippingEventList, getThresholdRewardsList } from 'models/discountEvent';
import { getProductById } from 'models/products';

import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/:id',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store, next, params }) => {
		const query = store.getState().routing.search;
		const id = Number(params.id);
		if (query.includes('combination')) {
			store.dispatch(getProductCombinationById(id));
		} else {
			store.dispatch(getProductById(id));
		}
		store.dispatch(getFreeShippingEventList());
		store.dispatch(getThresholdRewardsList());
		const children = await next();
		return children;
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'productsInner' */ './component')],
			render: ([ProductsInner]) => <ProductsInner />,
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
		},
	],
};
export default routes;
