import React from 'react';
import classnames from 'classnames';
import DropdownSvg from 'images/icon/dropdown.inline.svg';
import DecoratedLink from 'components/molecules/DecoratedLink';

import styles from './index.module.css';

interface HeaderDropdownLinkProperty {
	name: string;
	className?: string;
}
/**
 * 位於 Header 的下拉選單按鈕部分
 *
 * @param {*} { name, className }
 */
const HeaderDropdownLink: React.FC<HeaderDropdownLinkProperty> = ({ name, className }) => (
	<div className={classnames(styles.headerDropdownLink, className)}>
		<p>{name}</p>
		<DropdownSvg />
	</div>
);

export type HeaderDropdownData = {
	id: number;
	name: string;
	expandData: HeaderDropdownData[],
	icon?: React.JSX.Element;
	to: string;
	externalLink: string;
	isOpen?: boolean;
};

interface HeaderDropdownPanelProperty {
	data: HeaderDropdownData[];
	onClosePanel?: () => void;
	className?: string;
}
/**
 * 位於 Header 的下拉選單內容部分
 *
 * @param {*} {
 * 	data,
 * 	onClosePanel,
 * 	className,
 * }
 */
const HeaderDropdownPanel: React.FC<HeaderDropdownPanelProperty> = ({
	data,
	onClosePanel,
	className,
}) => (
	<div className={classnames(styles.headerDropdownPanel, className)}>
		<div className={styles.blockContainer}>
			{data.map(item => (
				<div className={styles.block} key={item.name}>
					<p className={classnames(styles.textTitle, styles.title)}>{item.name}</p>
					{item.expandData.map(subItem => (
						<DecoratedLink
							className={styles.subtitle}
							key={subItem.name}
							name={subItem.name}
							to={subItem.to}
							onClick={onClosePanel}
						/>
					))}
				</div>
			))}
		</div>
	</div>
);

interface HeaderDropdownProperty {
	name: string;
	data: HeaderDropdownData[];
	className?: string;
}
/**
 * 位於 Header 的下拉選單，為按鈕 + 內容的組合
 *
 * @param {*} { data, name, className }
 */
const HeaderDropdown: React.FC<HeaderDropdownProperty> = ({ data, name, className }) => (
	<div className={classnames(styles.headerDropdown, className)}>
		<HeaderDropdownLink name={name} />
		<HeaderDropdownPanel data={data} className={classnames(styles.panel, styles.group)} />
	</div>
);

export default HeaderDropdown;
