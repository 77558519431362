import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/about-us',
	components: () => [import(/* webpackChunkName: 'aboutUs' */ './component')],
	render: ([AboutUs]) => <AboutUs />,
};

export default routes;
