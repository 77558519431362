/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { ROUTE_PATHS } from 'routes';

import Button from 'components/atoms/Button';
import ProductLabel from 'components/atoms/ProductLabel';
import Link from 'components/atoms/Link';

import { useCart } from 'models/cart';
import { useRedirect } from 'models/redirect';

import useMedia from 'util/hook/useMedia';
import { useProductsForCart } from 'util/hook/useProducts';
import useToastTop from 'util/hook/useToastTop';
import { gtmAddToOrRemovefromCart } from 'util/gtmEvent';

import Trashcan from 'images/icon/trashcan.inline.svg';

import thousands from 'util/thousands';
import styles from './index.module.css';

interface ProductBlockProperty {
	id: number;
	productName: string;
	count: number;
	unitPrice: number;
	image: string;
	materialNo?: string;
	briefDescription?: string;
	stateLabel?: string;
	isCombination: boolean;
}

const ProductBlock: React.FC<ProductBlockProperty> = ({
	id,
	productName,
	count,
	unitPrice,
	image,
	stateLabel,
	materialNo = '',
	briefDescription = '',
	isCombination,
}) => {
	const [, { removeCartProductById }] = useCart();
	return (
		<div className={styles.productBlock}>
			<img src={image} alt="" />
			<div className={styles.info}>
				<div className={styles.top}>
					{stateLabel && (
						<ProductLabel
							text={stateLabel}
							textColor="#8C7DBE"
							borderColor="#8C7DBE"
							className={styles.productState}
							size="xs"
						/>
					)}
					<div className={styles.name}>{productName}</div>
				</div>
				<div className={styles.price}>
					{count} x ${thousands(unitPrice)}
				</div>
			</div>
			<button
				type="button"
				className={styles.trashcan}
				onClick={() => {
					gtmAddToOrRemovefromCart(-1, productName, materialNo, unitPrice, 0, briefDescription);
					removeCartProductById(id, isCombination);
				}}
			>
				<Trashcan />
			</button>
		</div>
	);
};
interface SideCartListProperty {
	className?: string;
	handleList?: (arg: boolean) => void;
}

const SideCartList: React.FC<SideCartListProperty> = ({ className, handleList }) => {
	const media = useMedia();
	const products = useProductsForCart();
	const [{ toastTopWithoutMargin }] = useToastTop();
	const [, { pushAuthGuardRoute }] = useRedirect();
	const [windowHeight, setWindowHeight] = useState(window.innerHeight);
	const desktopMaxHeight = useMemo(() => {
		if (media === 'mobile') {
			return '415px';
		}
		const bottonmMargin = 20;
		const maxHeight = window.innerHeight - toastTopWithoutMargin - bottonmMargin;

		return `${maxHeight}px`;
	}, [media, toastTopWithoutMargin, windowHeight]);

	useEffect(() => {
		const resizeHandler = () => {
			setWindowHeight(window.innerHeight);
		};
		window.addEventListener('resize', resizeHandler);

		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);

	return (
		<div
			className={classnames(styles.sideCartList, className, !products.length && styles.empty)}
			style={{
				maxHeight: desktopMaxHeight,
			}}
		>
			{products.length ? (
				<>
					<div className={styles.productContainer}>
						{products.map(item => (
							<ProductBlock {...item} key={item.productName} />
						))}
					</div>
					<footer className={styles.footer}>
						<Link>
							<Button
								name="前往結帳"
								handleClick={() => {
									const backPath = `/${ROUTE_PATHS.cart}`;
									pushAuthGuardRoute(backPath);
									if (handleList) handleList(false);
								}}
								className={styles.btn}
							/>
						</Link>
					</footer>
				</>
			) : (
				<div className={styles.emptyText}>您的購物車是空的</div>
			)}
		</div>
	);
};

export default SideCartList;
