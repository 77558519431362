/* eslint-disable no-restricted-syntax */
export function parseQueryString(): { [key: string]: string } {
	const queryString = window.location.search.split('?')[1];
	const params = new URLSearchParams(queryString);
	const result: { [key: string]: string } = {};

	params.forEach((value, key) => {
		result[key] = value;
	});

	return result;
}
