import { getEcUserByStorage } from 'util/storage';

import { HttpClient, RequestParams } from './swaggerApi/http-client';
import { Api } from './swaggerApi/Api';

// 引入該專案的 API domain
const { API_ENDPOINT } = process.env;
// 以 FEATURE_DOMAIN 引入懶人包的 banner 作為客製化範例
const FEATURE_DOMAIN = 'https://25sprout-backstagefeatures-sit.25demo.com';

// 發 request 前驗證
export const validate = (): RequestParams => {
	const userToken = getEcUserByStorage();
	const requestParams: RequestParams = {
		headers: {
			Authorization: userToken ? `Bearer ${userToken}` : '',
		},
	};

	return requestParams;
};

// 基礎設定
const PROJECT_API_CONFIG = {
	baseUrl: `${API_ENDPOINT}/backstage`,
	baseApiParams: {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	},
	securityWorker: validate,
};

// 客製化
const CUSTOM_API_CONFIG = {
	baseUrl: `${FEATURE_DOMAIN}/backstage`,
	baseApiParams: {
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	},
};

// 實體化可能用到的基本設定，可客製化
const client = new HttpClient(PROJECT_API_CONFIG);
const customClient = new HttpClient(CUSTOM_API_CONFIG);

export const api = new Api(client);
export const customApi = new Api(customClient);
