import React from 'react';
import classnames from 'classnames';
import Arrow from 'images/icon/dropdown.inline.svg';
import DropDown from '../DropDown';
import Link from '../Link';

import styles from './index.module.css';

interface MainComponentProperty {
	name: string;
	isOpen: boolean;
	handleClick?: () => void;
	className?: string;
}

const MainComponent: React.FC<MainComponentProperty> = ({
	name,
	isOpen,
	handleClick = () => {},
	className,
}) => (
	// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
	<div
		className={classnames(styles.mainComponent, styles.itemContainer, className, {
			[styles.open]: isOpen,
		})}
		onClick={handleClick}
	>
		<p>{name}</p>
		<Arrow />
	</div>
);

export type DropdownItemData = { name: string; to: string }[];

interface PanelComponentProperty {
	onClosePanel: () => void;
	handleClick?: () => void;
	data: DropdownItemData;
	className?: string;
}

const PanelComponent: React.FC<PanelComponentProperty> = ({
	onClosePanel,
	handleClick,
	data,
	className,
}) => (
	<div className={classnames(styles.panelComponent, className)}>
		{data.map(item => (
			<Link
				className={styles.itemContainer}
				key={item.name}
				to={item.to}
				onClick={() => {
					onClosePanel();
					if (handleClick) handleClick();
				}}
			>
				{item.name}
			</Link>
		))}
	</div>
);

interface DropdownItemProperty {
	name: string;
	data: DropdownItemData;
	open?: boolean;
	handleMainClick?: () => void;
	handlePanelClick?: () => void;
	className?: string;
	autoClose?: boolean;
}

const DropdownItem: React.FC<DropdownItemProperty> = ({
	name,
	data,
	handleMainClick,
	handlePanelClick,
	open,
	autoClose = true,
	className,
}) => (
	<DropDown
		className={classnames(styles.dropdownItem, className)}
		renderMainComponent={({ isOpen }) => (
			<MainComponent isOpen={isOpen} name={name} handleClick={handleMainClick} />
		)}
		renderPanelComponent={({ onClosePanel }) => (
			<PanelComponent onClosePanel={onClosePanel} data={data} handleClick={handlePanelClick} />
		)}
		open={open}
		autoClose={autoClose}
	/>
);

export default DropdownItem;
