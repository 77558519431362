/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';

import { getImageFromStorage } from 'util/hook/getImageFromStorage';
import {
	ProductCombinationDiscountCollection,
	ProductCombinationDiscountResource,
	ProductCombinationResource,
	ProductCombinationCollection,
} from 'util/api/swaggerApi/data-contracts';
import { VisibilityRuleType } from 'enums/visibilityRuleType';
import { State as GlobalState } from './reducers';

const {
	v1ProductCombinationDiscountsList,
	v1ProductCombinationDiscountsDetail,
	v1ProductCombinationsList,
	v1ProductCombinationsDetail,
} = api;

export const getCombinationDiscounts = createAction('GET_COMBINATION_DISCOUNTS', async () => {
	try {
		const { data } = await v1ProductCombinationDiscountsList();
		return {
			combinationDiscount: data?.data?.filter(
				item => item.currentVisibilityRule === VisibilityRuleType.ONLINE,
			) as ProductCombinationDiscountResource[],
		};
	} catch (e) {
		return { combinationDiscount: [] };
	}
});

export const getCombinationDiscountById = createAction(
	'GET_COMBINATION_DISCOUNT_BY_ID',
	async (id: number) => {
		try {
			const { data } = await v1ProductCombinationDiscountsDetail(id);
			const selectDiscount: ProductCombinationDiscountCollection = {
				...data?.data,
				webImage: data?.data?.webImage && getImageFromStorage(data?.data?.webImage),
				mobileImage: data?.data?.mobileImage && getImageFromStorage(data.data.mobileImage),
				combinations: data?.data?.combinations?.map((prod: ProductCombinationCollection) => ({
					...prod,
					images: prod.images?.map((img: string) => getImageFromStorage(img)),
				})),
			};
			return { selectDiscount };
		} catch (e) {
			return { selectDiscount: {} };
		}
	},
);

export const getProductCombinations = createAction('GET_PRODUCT_COMBINATIONS', async () => {
	try {
		const { data } = await v1ProductCombinationsList();

		const combinations: ProductCombinationResource[] =
			data?.data?.map(product => ({
				...product,
				images: product.images?.map((img: string) => getImageFromStorage(img)),
				relateSelves: product.relateSelves?.map((p: ProductCombinationCollection) => ({
					...p,
					images: p?.images?.map((img: string) => getImageFromStorage(img)),
				})),
			})) || [];
		return { combinations };
	} catch (e) {
		return { combinations: [] };
	}
});

export const getProductCombinationById = createAction(
	'GET_PRODUCT_COMBINATION_BY_ID',
	async (id: number) => {
		try {
			const { data } = await v1ProductCombinationsDetail(id);
			const selectProduct: ProductCombinationResource = {
				...data?.data,
				images: data?.data?.images?.map((img: string) => getImageFromStorage(img)),
				relateSelves: data?.data?.relateSelves?.map((p: ProductCombinationCollection) => ({
					...p,
					images: p?.images?.map((img: string) => getImageFromStorage(img)),
				})),
			};
			return { selectProduct };
		} catch (e) {
			return { selectProduct: {} };
		}
	},
);

export const removeSelectCombination = createAction('REMOVE_SELECT_COMBINATION');

export interface State {
	loading: boolean;
	combinationDiscount: ProductCombinationDiscountResource[];
	selectDiscount: ProductCombinationDiscountCollection;
	combinations: ProductCombinationResource[];
	selectProduct: ProductCombinationResource;
}

export const defaultState: State = {
	loading: false,
	combinationDiscount: [],
	selectDiscount: {},
	combinations: [],
	selectProduct: {},
};

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	combination: handleActions<State, State>(
		{
			GET_COMBINATION_DISCOUNTS_FULFILLED: (state, action) => ({
				...state,
				combinationDiscount: action.payload.combinationDiscount,
			}),
			GET_COMBINATION_DISCOUNT_BY_ID_FULFILLED: (state, action) => ({
				...state,
				selectDiscount: action.payload.selectDiscount,
			}),
			GET_PRODUCT_COMBINATIONS_FULFILLED: (state, action) => ({
				...state,
				combinations: action.payload.combinations,
			}),
			GET_PRODUCT_COMBINATION_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_PRODUCT_COMBINATION_BY_ID_FULFILLED: (state, action) => ({
				...state,
				selectProduct: action.payload.selectProduct,
			}),
			REMOVE_SELECT_COMBINATION: state => ({
				...state,
				selectProduct: {},
			}),
		},
		defaultState,
	),
};

const combinationActionMap = {
	getCombinationDiscounts,
	getCombinationDiscountById,
	removeSelectCombination,
};

const mapHooksToState = (state: GlobalState) => ({
	combinationDiscount: state.combination.combinationDiscount,
	selectDiscount: state.combination.selectDiscount,
	combinations: state.combination.combinations,
	selectProduct: state.combination.selectProduct,
	loading: state.combination.loading,
});

type combinationSelector = ReturnType<typeof mapHooksToState>;
type combinationsActionsMap = typeof combinationActionMap;

export const useCombinations = () =>
	useRedux<combinationSelector, combinationsActionsMap>(mapHooksToState, combinationActionMap);
