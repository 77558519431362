import React from 'react';
import classnames from 'classnames';
import CartIcon from 'images/icon/cart.inline.svg';

import styles from './index.module.css';

interface CartProperty {
	quantity: number;
	className?: string;
}

const Cart: React.FC<CartProperty> = ({ quantity, className }) => (
	<div className={classnames(styles.cart, className)}>
		<CartIcon />
		{quantity !== 0 && <span className={styles.quantity}>{quantity}</span>}
	</div>
);

export default Cart;
