import { VALIDATION_RULES } from 'enums/validators';
import { FormErrors } from './formControl';

export class Validators {
	/**
	 * 必填檢核
	 *
	 * @static
	 * @param {D} value
	 * @memberof Validators
	 */
	static require = <D>(value: D): FormErrors => {
		const error = {
			[VALIDATION_RULES.REQUIRED]: true,
		};

		switch (typeof value) {
			case 'string':
			case 'number':
				return !value.toString().trim() ? error : null;
			default:
				return null;
		}
	};

	/**
	 * 正則檢核
	 *
	 * @static
	 * @param {RegExp} reg - 正則
	 * @memberof Validators
	 */
	static pattern =
		(reg: RegExp) =>
		<D>(value: D): FormErrors => {
			const newVal = String(value);

			if (!newVal) {
				return null;
			}

			const error = {
				[VALIDATION_RULES.PATTERN]: reg,
			};
			const result = reg.test(newVal);

			return result ? null : error;
		};
}
