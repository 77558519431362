import { VALIDATION_RULES, ERROR_MESSAGES } from 'enums/validators';

export const regexMap = {
	// 手機檢核
	[VALIDATION_RULES.MOBILE]: /^[0]{1}[9]{1}[0-9]{8}$/,

	// 市話檢核
	[VALIDATION_RULES.TELEPHONE]: /^[0]{1}[0-9]{1,3}-[0-9]{5,8}$/,

	// 自然人憑證檢核(2個大寫英文 + 14個數字)
	[VALIDATION_RULES.CERTIFICATE]: /^[A-Z]{2}[0-9]{14}$/,

	// 手機載具條碼檢核(斜線(/)加上7碼數字或英文)
	[VALIDATION_RULES.MOBILEBARCODE]: /^\/{1}[0-9A-Z-+.]{7}$/,

	// 台灣公司統一編號(由8位數字組成)
	[VALIDATION_RULES.TAXID]: /^[0-9]{8}$/,
};

export type ErrorObjectType = {
	[key in VALIDATION_RULES]?: {
		message: `${ERROR_MESSAGES}`;
	};
};

const regexValidator = (regex: RegExp, msg: ERROR_MESSAGES) => (value: string) =>
	regex.test(value) ? null : msg;

const validators: {
	[key in VALIDATION_RULES]: (v: string, sec?: string) => ERROR_MESSAGES | null;
} = {
	[VALIDATION_RULES.REQUIRED]: (value: string) =>
		value.trim() === '' ? ERROR_MESSAGES.REQUIRED : null,
	[VALIDATION_RULES.EMAIL]: regexValidator(
		/\S+@\S+\.\S+/, // 電子信箱正則表達式
		ERROR_MESSAGES.EMAIL,
	),
	[VALIDATION_RULES.ACCOUNT]: regexValidator(
		/^(?:\S+@\S+\.\S+|\S{4,})$/, // 帳號：帳號（4 個以上英文「或」數字，表示可以全數字 / 全英文 / 英數混雜）或者檢查是否為 e-mail
		ERROR_MESSAGES.ACCOUNT,
	),
	[VALIDATION_RULES.EMAIL_VERIFY]: regexValidator(
		/^\d{4}$/, // email 驗證碼：四位數字
		ERROR_MESSAGES.EMAIL_VERIFY,
	),
	[VALIDATION_RULES.PASSWORD]: regexValidator(
		/^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9]{8,}$/, // 密碼：8 碼以上，英數混雜
		ERROR_MESSAGES.PASSWORD,
	),
	[VALIDATION_RULES.LOGIN_PASSWORD]: regexValidator(
		/^[a-zA-Z0-9]{8,}$/, // 密碼：8 個以上英文「或」數字，表示可以全數字 / 全英文 / 英數混雜
		ERROR_MESSAGES.PASSWORD,
	),
	[VALIDATION_RULES.COMFIRM_PASSWORD]: (value: string, secondValue?: string) =>
		value === secondValue ? null : ERROR_MESSAGES.COMFIRM_PASSWORD, // 確認兩者密碼是否相同
	[VALIDATION_RULES.MOBILE]: regexValidator(
		/^[0]{1}[9]{1}[0-9]{8}$/, // 手機號碼驗證
		ERROR_MESSAGES.MOBILE,
	),
	[VALIDATION_RULES.TELEPHONE]: regexValidator(
		/^[0]{1}[0-9]{1,3}-[0-9]{5,8}$/, // 市話號碼驗證
		ERROR_MESSAGES.TELEPHONE,
	),
	[VALIDATION_RULES.MOBILEBARCODE]: regexValidator(
		/^\/{1}[0-9A-Z-+.]{7}$/, // 載具條碼驗證(斜線(/)加上7碼數字或大寫字母)
		ERROR_MESSAGES.MOBILEBARCODE,
	),
	[VALIDATION_RULES.CERTIFICATE]: regexValidator(
		/^[a-zA-Z]{2}[0-9]{14}$/, // 自然人憑證驗證(2碼英文字母加上14碼數字)
		ERROR_MESSAGES.CERTIFICATE,
	),
	[VALIDATION_RULES.TAXID]: regexValidator(
		/^[0-9]{8}$/, // 台灣公司統一編號(由8位數字組成)
		ERROR_MESSAGES.TAXID,
	),
	[VALIDATION_RULES.PATTERN]: regexValidator(
		/^[0-9]{8}$/, // 台灣公司統一編號(由8位數字組成)
		ERROR_MESSAGES.PATTERN,
	),
};

export type ValidateFn = (value: string, secondValue?: string) => ErrorObjectType | null;

export const useValidators = (rules: VALIDATION_RULES[]) => {
	const validate: ValidateFn = (value, secondValue?) => {
		const errObj: ErrorObjectType = {};
		rules.forEach(rule => {
			const err = validators[rule](value, secondValue);
			if (err) {
				errObj[rule] = {
					message: err,
				};
			}
		});
		return Object.keys(errObj).length ? errObj : null;
	};

	return [validate];
};
