/**
 * 載具種類之列舉，發票為個人發票時使用
 *
 * @export
 * @enum {number}
 */
export enum CarrierType {
	/**
	 * 手機條碼
	 */
	PHONE = 'PHONE',

	/**
	 * 自然人憑證
	 */
	NATURAL_PERSON = 'NATURAL_PERSON',

	/**
	 * 會員發票
	 */
	MEMBER = 'MEMBER',

	/**
	 * 捐贈
	 */
	DONATE = 'DONATE',

	/**
	 * 無 : 公司發票
	 */
	NONE = 'NONE',
}

export const getCarrierTypeText = (carrierType: CarrierType): string => {
	switch (carrierType) {
		case CarrierType.PHONE:
			return '手機條碼載具';
		case CarrierType.NATURAL_PERSON:
			return '自然人憑證';
		case CarrierType.MEMBER:
			return '會員載具';
		case CarrierType.DONATE:
			return '捐贈';
		default:
			return '';
	}
};
