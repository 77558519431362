/**
 * 商品試用狀態之列舉
 *
 * @export
 * @enum {number}
 */
export enum ProductApplicableType {
	/**
	 * 全部商品
	 *
	 **/
	ALL_PRODUCTS = 'ALL_PRODUCTS',

	/**
	 * 部分一般商品
	 *
	 **/
	PARTIAL_PRODUCTS = 'PARTIAL_PRODUCTS',

	/**
	 * 部分組合商品
	 *
	 **/
	PARTIAL_COMBINATIONS = 'PARTIAL_COMBINATIONS',
}
