import React from 'react';
import { ROUTE_PATHS } from 'routes';

import { CustomRoute } from 'util/hook/useRouter';
import { parseQueryString } from 'util/parseQueryString';
import { getEcUserByStorage } from 'util/storage';

import { getCouponByCode } from 'models/coupon';
import { pushAuthGuardRoute } from 'models/redirect';

import { MemberFeatureType } from 'enums/memberFeatureType';
import { titleChange } from 'models/pageView';
import { DEFAULT_TITLE } from 'components/atoms/SEOHelmet';
import pushHistory from 'util/pushHistory';

const routes: CustomRoute = {
	path: '/coupon-receive',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'couponReceive' */ './component')],
			render: ([CouponReceive]) => <CouponReceive />,
			onEnter: async ({ next, store, history }) => {
				const { code } = parseQueryString();

				if (!code) {
					return null;
				}

				// 已登入則領取優惠券後直接到我的優惠券頁面
				if (getEcUserByStorage()) {
					await store.dispatch(getCouponByCode(code));
					store.dispatch(titleChange(DEFAULT_TITLE));
					pushHistory(history, `/${ROUTE_PATHS.memberCentre}?type=${MemberFeatureType.COUPONS}`);
					window.scrollTo(0, 0);

					return null;
				}

				store.dispatch(titleChange(DEFAULT_TITLE));
				const backPath = `/${ROUTE_PATHS.couponReceive}?code=${code}`;
				store.dispatch(pushAuthGuardRoute(backPath));

				const children = await next();
				return children;
			},
		},
	],
};

export default routes;
