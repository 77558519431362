import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';

import { api } from 'util/api';
import { insertScriptToHead } from 'util/external';
import { VideoResource } from 'util/api/swaggerApi/data-contracts';

import { VisibilityRuleType } from 'enums/visibilityRuleType';

import { State as GlobalState } from './reducers';

interface VideoPayload {
	videos: VideoResource[];
}

// For Global State usage
export interface State {
	loading: boolean;
	isYouTubeIframeAPIReady: boolean;
	videos: VideoResource[];
}

export const defaultState: State = {
	loading: false,
	isYouTubeIframeAPIReady: false,
	videos: [],
};

export const getVideo = createAction<Promise<VideoPayload>>('GET_VIDEO', async () => {
	try {
		const { v1VideosList } = api;
		const { data } = await v1VideosList();
		let list = data?.data || [];
		list = list.filter(item => item.currentVisibilityRule === VisibilityRuleType.ONLINE);
		return { videos: list };
	} catch (e) {
		return { videos: [] };
	}
});

export const initYouTubeIframeAPI = createAction('INIT_YOUTUBE_IFRAME_API', () => (dispatch: Dispatch) => {
	const YOUTUBE_IFRAME_ID = `youtube-iframe-api`;
	insertScriptToHead(YOUTUBE_IFRAME_ID, 'https://www.youtube.com/iframe_api');

	window.onYouTubeIframeAPIReady = () => {
		dispatch(setYouTubeIframeAPIReady(true));
	};
});

export const setYouTubeIframeAPIReady = createAction<boolean, boolean>(
	'SET_YOUTUBE_IFRAME_API_READY',
	isReady => isReady,
);

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	videos: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_VIDEO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_VIDEO_FULFILLED: (state, action: Action<VideoPayload>) => ({
				...state,
				videos: action.payload.videos,
				loading: false,
			}),
			SET_YOUTUBE_IFRAME_API_READY: (state, action: Action<boolean>) => ({
				...state,
				isYouTubeIframeAPIReady: action.payload,
			}),
		},
		defaultState,
	),
};

const videosActionsMap = {
	getVideo,
	setYouTubeIframeAPIReady,
};

const mapHooksToState = (state: GlobalState) => ({
	videos: state.videos.videos,
	isYouTubeIframeAPIReady: state.videos.isYouTubeIframeAPIReady,
});

type videosSelector = ReturnType<typeof mapHooksToState>;
type videosActionsMap = typeof videosActionsMap;

export const useVideos = () =>
	useRedux<videosSelector, videosActionsMap>(mapHooksToState, videosActionsMap);
