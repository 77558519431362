/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action, createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';
import { State as GlobalState } from './reducers';

type AllProductStockPayload = Map<number, number>;

interface ProductStockPayload {
	productStock: number;
}

const { v1SystemMaintainSettingsList, v1ProductsStocksList, v1GiftsStocksList } = api;

const isMaintained = async () => {
	try {
		const { data } = await v1SystemMaintainSettingsList();
		// Set isMaintained to true if the API is not working
		const maintaining = data?.data?.filter(({ type }) => type === 'YIPIN_ERP')[0].isMaintained;
		return maintaining !== undefined ? maintaining : true;
	} catch (e) {
		return true;
	}
};

/**
 * 取得輸入id的所有product庫存
 */
export const getAllProductStock = createAction<Promise<AllProductStockPayload>, number[]>(
	'GET_ALL_PRODUCT_STOCK',
	async ids => {
		const map = new Map<number, number>();
		const maintaining = await isMaintained();
		try {
			const { data } = await v1ProductsStocksList(ids.length ? { ids: ids.join(',') } : {});
			data?.data?.forEach(({ webStock, erpStock, id }) => {
				if (webStock === undefined || erpStock === undefined) {
					return;
				}
				const stock = maintaining ? webStock : erpStock;
				map.set(id!, stock);
			});
			return map;
		} catch (e) {
			return map;
		}
	},
);

/**
 * 比較出combination中最小的product庫存
 */
export const getProductStock = createAction<Promise<ProductStockPayload>, string>(
	'GET_PRODUCT_STOCK',
	async ids => {
		const maintaining = await isMaintained();

		try {
			const { data } = await v1ProductsStocksList({ ids });
			let minimum = Number.MAX_SAFE_INTEGER;
			data?.data?.forEach(({ webStock, erpStock }) => {
				if (!webStock || !erpStock) {
					return;
				}

				minimum = Math.min(minimum, maintaining ? webStock : erpStock);
			});

			return { productStock: minimum };
		} catch (e) {
			return { productStock: Number.MAX_SAFE_INTEGER };
		}
	},
);

export const getAllGiftStock = createAction<Promise<AllProductStockPayload>>(
	'GET_ALL_GIFT_STOCK',
	async () => {
		const map = new Map<number, number>();
		const maintaining = await isMaintained();
		try {
			const { data } = await v1GiftsStocksList({});
			data?.data?.forEach(({ webStock, erpStock, id }) => {
				if (webStock === undefined || erpStock === undefined) {
					return;
				}
				const stock = maintaining ? webStock : erpStock;
				map.set(id!, stock);
			});
			return map;
		} catch (e) {
			return map;
		}
	},
);
// For Global State usage
export interface State {
	loading: boolean;
	productStock: number;
	allProductStock: AllProductStockPayload;
	allGiftStock: AllProductStockPayload;
}

export const defaultState: State = {
	loading: false,
	productStock: -1,
	allProductStock: new Map(),
	allGiftStock: new Map(),
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	stock: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PRODUCT_STOCK_FULFILLED: (state, action: Action<ProductStockPayload>) => ({
				...state,
				productStock: action.payload.productStock,
				loading: false,
			}),
			GET_ALL_PRODUCT_STOCK_FULFILLED: (state, action: Action<AllProductStockPayload>) => ({
				...state,
				allProductStock: action.payload,
				loading: false,
			}),
			GET_ALL_GIFT_STOCK_FULFILLED: (state, action: Action<AllProductStockPayload>) => ({
				...state,
				allGiftStock: action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const stockActionsMap = {
	getProductStock,
};

const mapHooksToState = (state: GlobalState) => ({
	productStock: state.stock.productStock,
	allProductStock: state.stock.allProductStock,
	allGiftStock: state.stock.allGiftStock,
});

type StockSelector = ReturnType<typeof mapHooksToState>;
type StockActionsMap = typeof stockActionsMap;

export const useStock = () =>
	useRedux<StockSelector, StockActionsMap>(mapHooksToState, stockActionsMap);
