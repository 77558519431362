import React from 'react';
import classnames from 'classnames';

import { useBoolean } from 'util/hook';
import { getItem, setItem } from 'util/storage';

import { StorageKey } from 'enums/storageKey';
import { StorageType } from 'enums/storageType';

import Button from '../Button';
import Link from '../Link';

import styles from './index.module.css';

interface CookieCardProperty {
	className?: string;
}

const CookieCard: React.FC<CookieCardProperty> = ({ className }) => {
	const [isConsent, { setTrue }] = useBoolean({
		defaultBoolean: getItem(StorageKey.COOKIE_CONSENT, StorageType.SESSION_STORAGE)
			? JSON.parse(getItem(StorageKey.COOKIE_CONSENT, StorageType.SESSION_STORAGE) || '')
			: false,
		onTrue: () => {
			setItem(StorageKey.COOKIE_CONSENT, JSON.stringify(true), StorageType.SESSION_STORAGE);
		},
	});
	return !isConsent ? (
		<div className={classnames(styles.cookieCard, className)}>
			<p>
				我們紀錄 Cookies
				資訊，以提供客製化內容及優化使用者體驗，若繼續閱覽本網站內容，即表示您同意我們使用
				Cookies。更多關於隱私保護資訊，請閱覽我們的<Link to="/privacy-statement">隱私權聲明</Link>。
			</p>
			<Button
				pattern="primaryOutline"
				size="small"
				name="我瞭解了"
				handleClick={setTrue}
				className={styles.button}
			/>
		</div>
	) : null;
};

export default CookieCard;
