import { StorageKey } from 'enums/storageKey';
import { StorageType } from 'enums/storageType';

const prefix = (key: string) => `icon-${key}`;

export const useLocalStorage = <T>(suffix: string) => {
	const prefixKey = prefix(suffix);
	const setValue = (value: T) => {
		if (typeof value === 'string') {
			window.localStorage.setItem(prefixKey, value);
		} else {
			window.localStorage.setItem(prefixKey, JSON.stringify(value));
		}
	};

	const getValue = (): T | null => {
		const value = window.localStorage.getItem(prefixKey);
		if (value) {
			return JSON.parse(value);
		}
		return null;
	};

	const removeValue = () => {
		window.localStorage.removeItem(prefixKey);
	};

	return {
		setValue,
		getValue,
		removeValue,
	};
};

export const setItem = (key: string, value: string, type = StorageType.LOCAL_STORAGE) => {
	const prefixKey = prefix(key);
	let storage = window.localStorage;
	if (type === StorageType.SESSION_STORAGE) {
		storage = window.sessionStorage;
	}
	storage.setItem(prefixKey, value);
};

export const getItem = (key: string, type = StorageType.LOCAL_STORAGE) => {
	const prefixKey = prefix(key);
	let storage = window.localStorage;
	if (type === StorageType.SESSION_STORAGE) {
		storage = window.sessionStorage;
	}
	return storage.getItem(prefixKey);
};

export const removeItem = (key: string, type = StorageType.LOCAL_STORAGE) => {
	const prefixKey = prefix(key);
	let storage = window.localStorage;
	if (type === StorageType.SESSION_STORAGE) {
		storage = window.sessionStorage;
	}
	storage.removeItem(prefixKey);
};

export const getAutoCompleteByStorage = (): boolean => {
	let isRememberMe = false;
	const autoComplete = getItem(StorageKey.AUTO_COMPLETE);

	if (autoComplete) {
		isRememberMe = JSON.parse(autoComplete);
	}

	return isRememberMe;
}

export const setEcUserByStorage = (token: string) => {
	let type = StorageType.LOCAL_STORAGE;
	if (!getAutoCompleteByStorage()) {
		type = StorageType.SESSION_STORAGE;
	}
	setItem(StorageKey.EC_USER, token, type);
};

export const getEcUserByStorage = (): string => {
	let type = StorageType.LOCAL_STORAGE;
	if (!getAutoCompleteByStorage()) {
		type = StorageType.SESSION_STORAGE;
	}
	return getItem(StorageKey.EC_USER, type) || '';
};

export const removeEcUserByStorage = () => {
	removeItem(StorageKey.EC_USER, StorageType.LOCAL_STORAGE);
	removeItem(StorageKey.EC_USER, StorageType.SESSION_STORAGE);
};
