import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getBlogDetailById } from 'models/blog';
import { parseQueryString } from 'util/parseQueryString';

const routes: CustomRoute = {
	path: '/:newsId',
	components: () => [import(/* webpackChunkName: 'blogDetail' */ './component')],
	render: ([BlogDetail]) => <BlogDetail />,
	onEnter: async ({ next, store, params }) => {
		const { newsId } = params;
		const { categoryId } = parseQueryString();
		await store.dispatch(
			getBlogDetailById({
				id: parseInt(newsId as string, 10),
				sort_by: 'newest',
				has_tag: !!categoryId,
			}),
		);

		const children = await next();
		return children;
	},
};
export default routes;
