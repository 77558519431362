// Google Tag Manager
export const googleTagManager = i => {
	window['dataLayer'] = window['dataLayer'] || [];
	window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
	var f = document.getElementsByTagName('script')[0],
		j = document.createElement('script'),
		dl = 'dataLayer' != 'dataLayer' ? '&l=' + 'dataLayer' : '';
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
	f.parentNode.insertBefore(j, f);
};

// Bing Microsoft Advertising UET
export const bingTag = id => {
	var f, n, i;
	(window['uetq'] = window['uetq'] || []),
		(f = function () {
			var o = { ti: id, enableAutoSpaTracking: true };
			(o.q = window['uetq']), (window['uetq'] = new UET(o)), window['uetq'].push('pageLoad');
		}),
		(n = document.createElement('script')),
		(n.src = '//bat.bing.com/bat.js'),
		(n.async = 1),
		(n.onload = n.onreadystatechange =
			function () {
				var s = this.readyState;
				(s && s !== 'loaded' && s !== 'complete') ||
					(f(), (n.onload = n.onreadystatechange = null));
			}),
		(i = document.getElementsByTagName('script')[0]),
		i.parentNode.insertBefore(n, i);
};

// Dcard
export const dcardTag = id => {
	var d = function d(a) {
		for (var e = arguments.length, n = new Array(e > 1 ? e - 1 : 0), i = 1; i < e; i++)
			n[i - 1] = arguments[i];
		d.queue.push([a].concat(n));
	};
	(d.queue = []), (window.dadk = window.dadk || d);
	var a = document.createElement('script');
	(a.src =
		'undefined' != typeof URLSearchParams
			? 'https://unpkg.com/@dcard/web-ad-tracking-sdk/build/dadk.iife.js'
			: 'https://unpkg.com/@dcard/web-ad-tracking-sdk/build/outdated-dadk.iife.js'),
		document.head.appendChild(a),
		window.dadk('init', id),
		window.dadk('track', 'PageView', { url: window.location.href });
};

// Facebook
export const fbTag = (n, t, s) => {
	if (window.fbq) return;
	n = window.fbq = function () {
		n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
	};
	if (!window._fbq) window._fbq = n;
	n.push = n;
	n.loaded = !0;
	n.version = '2.0';
	n.queue = [];
	t = document.createElement('script');
	t.async = !0;
	t.src = 'https://connect.facebook.net/en_US/fbevents.js';
	s = document.getElementsByTagName('script')[0];
	s.parentNode.insertBefore(t, s);
};
export const Fbq = id => {
	fbq('init', id);
	fbq('track', 'PageView');
};

// Google Ads & GA4
export const gTag = id => {
	window.dataLayer = window.dataLayer || [];
	function gtag() {
		dataLayer.push(arguments);
	}
	gtag('js', new Date());
	gtag('config', id);
};

// Tittok
export const tiktokTag = id => {
	window.TiktokAnalyticsObject = 'ttq';
	var ttq = (window['ttq'] = window['ttq'] || []);
	(ttq.methods = [
		'page',
		'track',
		'identify',
		'instances',
		'debug',
		'on',
		'off',
		'once',
		'ready',
		'alias',
		'group',
		'enableCookie',
		'disableCookie',
	]),
		(ttq.setAndDefer = function (t, e) {
			t[e] = function () {
				t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
			};
		});
	for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
	(ttq.instance = function (t) {
		for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
			ttq.setAndDefer(e, ttq.methods[n]);
		return e;
	}),
		(ttq.load = function (e, n) {
			var i = 'https://analytics.tiktok.com/i18n/pixel/events.js';
			(ttq._i = ttq._i || {}),
				(ttq._i[e] = []),
				(ttq._i[e]._u = i),
				(ttq._t = ttq._t || {}),
				(ttq._t[e] = +new Date()),
				(ttq._o = ttq._o || {}),
				(ttq._o[e] = n || {});
			n = document.createElement('script');
			(n.type = 'text/javascript'), (n.async = !0), (n.src = i + '?sdkid=' + e + '&lib=' + 'ttq');
			e = document.getElementsByTagName('script')[0];
			e.parentNode.insertBefore(n, e);
		});

	ttq.load(id);
	ttq.page();
};

// Line
export const lineTag = o => {
	window._ltq = window._ltq || [];
	window._lt =
		window._lt ||
		function () {
			window._ltq.push(arguments);
		};
	var h = location.protocol === 'https:' ? 'https://d.line-scdn.net' : 'http://d.line-cdn.net';
	var s = document.createElement('script');
	s.async = 1;
	s.src = o || h + '/n/line_tag/public/release/v1/lt.js';
	var t = document.getElementsByTagName('script')[0];
	t.parentNode.insertBefore(s, t);
};
export const lt = id => {
	_lt('init', {
		customerType: 'lap',
		tagId: id,
	});
	_lt('send', 'pv', [id]);
	_lt(
		'send',
		'cv',
		{
			type: 'Conversion',
		},
		[id],
	);
};
