import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/signin',
	components: () => [import(/* webpackChunkName: 'signin' */ './component')],
	render: ([Signin]) => <Signin />,
};
export default routes;
