import { getUserCouponList } from 'models/coupon';
import { getFreeShippingEventList, getThresholdRewardsList } from 'models/discountEvent';
import { getInvoiceList, getRecipientList, getUserInfo } from 'models/member';
import { getAllGiftStock } from 'models/stock';
import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { setCartData, setConfirmationStatus } from 'models/cart';
import { parseQueryString } from 'util/parseQueryString';
import { OrderConfirmationType } from 'enums/orderConfirmationType';
import { getSystemMaintain } from 'models/system';
import { getItem } from 'util/storage';
import { getUserOrderDetailById } from 'models/order';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/checkout',
	components: () => [import(/* webpackChunkName: 'checkout' */ './component')],
	render: ([Checkout]) => <Checkout />,
	onEnter: async ({ next, store }) => {
		const children = await next();

		const query = parseQueryString();
		const redirect = query?.redirect;
		if (!redirect && getItem('paypage')) {
			await store.dispatch(setConfirmationStatus(OrderConfirmationType.PAYMENT_FAIL));
		}
		await store.dispatch(getUserCouponList());
		// 結帳頁資料須來自暫存訂單
		await store.dispatch(setCartData(true));
		store.dispatch(getThresholdRewardsList());
		store.dispatch(getFreeShippingEventList());
		store.dispatch(getAllGiftStock());
		store.dispatch(getRecipientList());
		store.dispatch(getInvoiceList());

		store.dispatch(getUserInfo());
		await store.dispatch(getSystemMaintain());
		const state = store.getState();
		const { orderId } = getItem('paypage') ? JSON.parse(getItem('paypage') || '') : '';
		await store.dispatch(getUserOrderDetailById(orderId || state.cart.order?.id));

		return children;
	},
};
export default routes;
