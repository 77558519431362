export enum ERROR_CODE {
	EMAIL_EXISTED = 'EMAIL_EXISTED',
	VERIFY_FAIL = 'VERIFY_FAIL',
	VERIFY_ERROR_LIMIT_REACHED = 'VERIFY_ERROR_LIMIT_REACHED',
	USER_NOT_FOUND = 'USER_NOT_FOUND',
	USER_EMAIL_NOT_FOUND = 'USER_NOT_FOUND',
	CODE_NOT_FOUND = 'CODE_NOT_FOUND',
	USER_IS_UNVERIFIED = 'USER_IS_UNVERIFIED',
	PASSWORD_ERROR = 'PASSWORD_ERROR',
	LOGIN_FAIL = 'LOGIN_FAIL',
	USER_IS_DISABLE = 'USER_IS_DISABLE',
	RECEIVED_REGISTRATION_FAILED = 'received_registration_failed',
}

export enum ERROR_MESSAGES {
	EMAIL_EXISTED = '此電子信箱已存在',
	VERIFY_FAIL = '驗證碼錯誤，請重新輸入',
	VERIFY_ERROR_LIMIT_REACHED = '驗證碼輸入錯誤達 5 次，請重新取得驗證碼',
	USER_NOT_FOUND = '此電子信箱或帳號尚未註冊',
	USER_EMAIL_NOT_FOUND = '此電子信箱尚未註冊',
	CODE_NOT_FOUND = '驗證碼已失效，請重新取得驗證碼',
	USER_IS_UNVERIFIED = '會員信箱尚未進行驗證',
	PASSWORD_ERROR = '密碼錯誤',
	LOGIN_FAIL = '帳號或密碼錯誤',
	USER_IS_DISABLE = '此會員帳號已被停用',
}
