export default function thousands(value: string | number | null | undefined) {
	if (value) {
		const stringifyValue = value.toString();
		const arr = stringifyValue.split('.');
		const re = /(\d{1,3})(?=(\d{3})+$)/g;

		return arr[0].replace(re, '$1,') + (arr.length === 2 ? `.${arr[1]}` : '');
	}
	return value;
}
