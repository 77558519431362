/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AdminUserResource,
	AdvanceSearchable,
	AdvanceSearchRequest,
	ApiResponseTemplate,
	BannerResource,
	BscoreBscoreAdminUserInitAccountCreateRequestPayload,
	BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
	BscoreDestroyAllCreateRequestPayload,
	BscoreExcelImportExampleFileCreateRequestPayload,
	BscoreExcelImportUploadUrlCreateRequestPayload,
	BscoreExportResult,
	BscoreForgetPasswordCreateRequestPayload,
	BscoreGetForgetMailInfoListRequestParams,
	BscoreGetUnlockMailInfoListRequestParams,
	BscoreGetVerifyMailInfoListRequestParams,
	BscoreImportResult,
	BscoreLoginCreateRequestPayload,
	BscoreResetPasswordCreateRequestPayload,
	BscoreResetPasswordFromMailCreateRequestPayload,
	BscoreSettingsUpdateRequestPayload,
	BscoreSubListDestroyAllCreateRequestPayload,
	BscoreSubListExcelImportExampleFileCreateRequestPayload,
	BscoreSubListExcelImportUploadUrlCreateRequestPayload,
	BscoreSubListUpdateListCreateRequestParams,
	BscoreSubListUpdateListCreateRequestPayload,
	BscoreSubListUpdateRowCreateRequestPayload,
	BscoreUnlockAccountCreateRequestPayload,
	BscoreUpdateListCreateRequestParams,
	BscoreUpdateListCreateRequestPayload,
	BscoreUpdateRowCreateRequestPayload,
	BscoreUploadUploadUrlCreateRequestPayload,
	BscoreVerifyAccountCreateRequestPayload,
	BsxListResource,
	CouponEventResource,
	DiscountEventResource,
	ErpOrderResource,
	ExcelSearchRequest,
	ExclusiveSaleResource,
	FirstPurchaseEventResource,
	FlashSaleResource,
	FreeShippingEventResource,
	GetDataCreate,
	GetDataUpdate,
	GetListDataPaginator,
	GiftResource,
	ImportRequest,
	IndexSeriesResource,
	LineCategoryResource,
	LineProductResource,
	MenuItemResource,
	MenuResource,
	MessageBarResource,
	NewsCategoriesCollection,
	NewsCategoriesResource,
	NewsResource,
	OrderListResource,
	OrderResource,
	ProductCombinationDiscountCollection,
	ProductCombinationDiscountResource,
	ProductCombinationResource,
	ProductNewReleaseResource,
	ProductNewReleaseShowResource,
	ProductResource,
	ProductSeriesResource,
	ProductSubSeriesResource,
	RegistrationCouponEventResource,
	SearchRequest,
	ShoppingCartResource,
	StockResource,
	SystemMaintainSettingResource,
	ThresholdRewardResource,
	UserCouponResource,
	UserInvoiceResource,
	UserOmniResource,
	UserRecipientResource,
	UserResource,
	V1AuthCheckUserEmailCreateRequestPayload,
	V1AuthLineGetTokenCreateRequestPayload,
	V1AuthLineLinkCreateRequestPayload,
	V1AuthLineVerifyCreateRequestPayload,
	V1AuthLoginCreateRequestPayload,
	V1AuthRegisterCreateRequestPayload,
	V1AuthResetPasswordCreateRequestPayload,
	V1AuthSendResetPasswordVerificationEmailCreateRequestPayload,
	V1AuthSendUserVerificationEmailCreateRequestPayload,
	V1AuthVerifyEmailCodeCreateRequestPayload,
	V1AuthVerifyPasswordCreateRequestPayload,
	V1DiscountEventsListRequestParams,
	V1ErpOrdersListRequestParams,
	V1ErpProductStocksUpdateRequestPayload,
	V1ErpShipmentStatusUpdateRequestPayload,
	V1GiftsStocksListRequestParams,
	V1LinePayConfirmListRequestParams,
	V1LineShopTrackingEventsNormalCreateRequestPayload,
	V1LineShopTrackingEventsProductsCreateRequestPayload,
	V1NewsDetailRequestParams,
	V1NewsListRequestParams,
	V1OmniGetUserInfoCreateRequestPayload,
	V1ProductsStocksListRequestParams,
	V1UserCouponsReceiveCreateRequestPayload,
	V1UserEcpayInvoiceCheckMobileBarcodeListRequestParams,
	V1UserInvoiceCreateRequestPayload,
	V1UserInvoiceUpdateRequestParams,
	V1UserOrderCheckoutCreateRequestPayload,
	V1UserOrderPaymentCreateRequestPayload,
	V1UserOrderShoppingCartUpdateRequestPayload,
	V1UserRecipientCreateRequestPayload,
	V1UserRecipientUpdateRequestParams,
	V1UserUpdateRequestParams,
	VideoResource,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexProductsDetail
	 * @summary 取得所有商品-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/products
	 * @secure
	 */
	backstagexProductsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/products`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexProductCombinationsDetail
	 * @summary 取得所有商品組合-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/product-combinations
	 * @secure
	 */
	backstagexProductCombinationsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/product-combinations`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexProductSubSeriesDetail
	 * @summary 取得所有子類別-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/product-sub-series
	 * @secure
	 */
	backstagexProductSubSeriesDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/product-sub-series`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexArchiveUpdate
	 * @summary 作廢活動-後台模組使用
	 * @request PUT:/api/backstagex/{menu_item_key}/{id}/archive
	 * @secure
	 */
	backstagexArchiveUpdate = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/${menuItemKey}/${id}/archive`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-Update
	 * @name BackstagexOrderOrderCancelUpdate
	 * @summary 取消訂單-後台模組使用
	 * @request PUT:/api/backstagex/order:order/{id}/cancel
	 * @secure
	 */
	backstagexOrderOrderCancelUpdate = (id: string, order: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/backstagex/order${order}/${id}/cancel`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Coupon-Event
	 * @name V1CouponEventsList
	 * @summary 取得優惠券活動資訊
	 * @request GET:/api/v1/coupon-events
	 */
	v1CouponEventsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CouponEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/coupon-events`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Coupon-Event
	 * @name V1CouponEventsDetail
	 * @summary 取得單一優惠券活動資訊
	 * @request GET:/api/v1/coupon-events/{code}
	 */
	v1CouponEventsDetail = (code: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CouponEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/coupon-events/${code}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Discount-Event
	 * @name V1DiscountEventsList
	 * @summary 取得折扣優惠資訊
	 * @request GET:/api/v1/discount-events
	 */
	v1DiscountEventsList = (query: V1DiscountEventsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: DiscountEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/discount-events`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Discount-Event
	 * @name V1DiscountEventsDetail
	 * @summary 取得單一折扣優惠資訊
	 * @request GET:/api/v1/discount-events/{id}
	 */
	v1DiscountEventsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: DiscountEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/discount-events/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證手機載具條碼是否存在
	 *
	 * @tags User
	 * @name V1UserEcpayInvoiceCheckMobileBarcodeList
	 * @summary 驗證手機載具條碼格式
	 * @request GET:/api/v1/user/ecpay/invoice/check-mobile-barcode
	 */
	v1UserEcpayInvoiceCheckMobileBarcodeList = (
		query: V1UserEcpayInvoiceCheckMobileBarcodeListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			{
				/** @example 200 */
				status?: number;
				/** @example "success" */
				message?: string;
				data?: {
					data?: {
						/**
						 * 驗證結果：Y(存在)｜N(不存在)
						 * @example "Y"
						 */
						is_exist?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/user/ecpay/invoice/check-mobile-barcode`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ERP
	 * @name V1ErpFixedTimeOrdersList
	 * @summary 取得固定時間內訂單資訊（目前為一小時內）
	 * @request GET:/api/v1/erp/fixed-time-orders
	 * @secure
	 */
	v1ErpFixedTimeOrdersList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 訂單成立 */
						created?: ErpOrderResource[];
						/** 取消訂單 */
						cancelled?: ErpOrderResource[];
					};
				};
			},
			any
		>({
			path: `/api/v1/erp/fixed-time-orders`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 若有輸入訂單編號則優先採用訂單編號搜尋，若用日期區間搜尋最多不能超過一個月
	 *
	 * @tags ERP
	 * @name V1ErpOrdersList
	 * @summary 根據輸入日期區間或訂單編號取得訂單資料
	 * @request GET:/api/v1/erp/orders
	 * @secure
	 */
	v1ErpOrdersList = (query: V1ErpOrdersListRequestParams, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/erp/orders`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 欲調整的商品資料 & 庫存(可一次傳入多筆資料)，每筆資料格式為 {'料號': 庫存}
	 *
	 * @tags ERP
	 * @name V1ErpProductStocksUpdate
	 * @summary 校正庫存數量
	 * @request PUT:/api/v1/erp/product-stocks
	 * @secure
	 */
	v1ErpProductStocksUpdate = (
		data: V1ErpProductStocksUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 成功料號 */
						success?: any[];
						/** 失敗料號 */
						failed?: {
							/** 失敗的錯誤訊息 */
							失敗料號?: string;
						};
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/erp/product-stocks`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 欲調整的訂單資訊(可一次傳入多筆資料)，每筆訂單資料其中含4個欄位，分別為「(required)訂單編號 order_no」、「(required)物流狀態 logistics_status（C:撿貨｜Y:出貨｜T:退貨)」、「物流貨況 shipment_status」、「物流單號 shipment_no」
	 *
	 * @tags ERP
	 * @name V1ErpShipmentStatusUpdate
	 * @summary 更新訂單物流狀態
	 * @request PUT:/api/v1/erp/shipment-status
	 * @secure
	 */
	v1ErpShipmentStatusUpdate = (
		data: V1ErpShipmentStatusUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 成功訂單編號 */
						success?: any[];
						/** 失敗訂單 */
						failed?: {
							/** 失敗的錯誤訊息 */
							失敗訂單編號?: string;
						};
					};
				};
			},
			{
				/** @example 422 */
				status?: number;
				/** @example "The given data was invalid." */
				message?: string;
				/** @example "validation_error" */
				error_code?: string;
				extra?: {
					orders?: string[];
				};
			}
		>({
			path: `/api/v1/erp/shipment-status`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Exclusive-Sale
	 * @name V1ExclusiveSalesList
	 * @summary 取得官網獨家優惠商品資訊
	 * @request GET:/api/v1/exclusive-sales
	 */
	v1ExclusiveSalesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ExclusiveSaleResource[];
				};
			},
			any
		>({
			path: `/api/v1/exclusive-sales`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags First-Purchase-Event
	 * @name V1FirstPurchaseEventsList
	 * @summary 取得首購禮優惠商品資訊
	 * @request GET:/api/v1/first-purchase-events
	 */
	v1FirstPurchaseEventsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FirstPurchaseEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/first-purchase-events`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 依權重大小、建立時間排序，僅回傳權重最大、建立時間最新的一筆資料
	 *
	 * @tags Flash-Sale
	 * @name V1FlashSalesList
	 * @summary 取得限時特賣資訊
	 * @request GET:/api/v1/flash-sales
	 */
	v1FlashSalesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FlashSaleResource[];
				};
			},
			any
		>({
			path: `/api/v1/flash-sales`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Free-Shipping-Event
	 * @name V1FreeShippingEventsList
	 * @summary 取得免運活動資訊
	 * @request GET:/api/v1/free-shipping-events
	 */
	v1FreeShippingEventsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FreeShippingEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/free-shipping-events`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Free-Shipping-Event
	 * @name V1FreeShippingEventsDetail
	 * @summary 取得單一免運活動資訊
	 * @request GET:/api/v1/free-shipping-events/{id}
	 */
	v1FreeShippingEventsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: FreeShippingEventResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/free-shipping-events/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Gift
	 * @name V1GiftsList
	 * @summary 取得贈品資訊
	 * @request GET:/api/v1/gifts
	 */
	v1GiftsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: GiftResource[];
				};
			},
			any
		>({
			path: `/api/v1/gifts`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Gift
	 * @name V1GiftsDetail
	 * @summary 取得單一贈品資訊
	 * @request GET:/api/v1/gifts/{id}
	 */
	v1GiftsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: GiftResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/gifts/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的庫存資料
	 *
	 * @tags Gift-Stock
	 * @name V1GiftsStocksList
	 * @summary 取得贈品庫存資訊
	 * @request GET:/api/v1/gifts/stocks
	 */
	v1GiftsStocksList = (query: V1GiftsStocksListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: StockResource[];
				};
			},
			any
		>({
			path: `/api/v1/gifts/stocks`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Index-Series
	 * @name V1IndexSeriesList
	 * @summary 取得商品系列特色資訊
	 * @request GET:/api/v1/index-series
	 */
	v1IndexSeriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: IndexSeriesResource[];
				};
			},
			any
		>({
			path: `/api/v1/index-series`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Index-Series
	 * @name V1IndexSeriesDetail
	 * @summary 取得商品系列特色資訊
	 * @request GET:/api/v1/index-series/{id}
	 */
	v1IndexSeriesDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: IndexSeriesResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/index-series/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 code 取得 id_token
	 *
	 * @tags Auth
	 * @name V1AuthLineGetTokenCreate
	 * @summary 取得 Line Token
	 * @request POST:/api/v1/auth/line/get-token
	 */
	v1AuthLineGetTokenCreate = (
		data: V1AuthLineGetTokenCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						id_token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/line/get-token`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為解析 id_token 取得 Line 個人資訊，若 LineID 已綁定則會直接登入，若未綁定則走綁定流程回傳相關資訊
	 *
	 * @tags Auth
	 * @name V1AuthLineVerifyCreate
	 * @summary Line 註冊/登入
	 * @request POST:/api/v1/auth/line/verify
	 */
	v1AuthLineVerifyCreate = (
		data: V1AuthLineVerifyCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/verify`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 為 Line Pay 回傳交易結果並更新訂單狀態
	 *
	 * @tags Line-Pay
	 * @name V1LinePayConfirmList
	 * @request GET:/api/v1/line-pay/confirm
	 */
	v1LinePayConfirmList = (query: V1LinePayConfirmListRequestParams, params: RequestParams = {}) =>
		this.http.request<any, void>({
			path: `/api/v1/line-pay/confirm`,
			method: 'GET',
			query: query,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Line-Shop
	 * @name V1LineShopProductFullList
	 * @summary 取得商品、贈品與組合商品完整資料
	 * @request GET:/api/v1/line-shop/product-full
	 */
	v1LineShopProductFullList = (params: RequestParams = {}) =>
		this.http.request<LineProductResource[], BscoreImportResult>({
			path: `/api/v1/line-shop/product-full`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Line-Shop
	 * @name V1LineShopProductPartialList
	 * @summary 取得一小時內有更新的商品、贈品與組合商品資料
	 * @request GET:/api/v1/line-shop/product-partial
	 */
	v1LineShopProductPartialList = (params: RequestParams = {}) =>
		this.http.request<LineProductResource[], BscoreImportResult>({
			path: `/api/v1/line-shop/product-partial`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Line-Shop
	 * @name V1LineShopCategoryFullList
	 * @summary 取得所有子類別與主系列的資料
	 * @request GET:/api/v1/line-shop/category-full
	 */
	v1LineShopCategoryFullList = (params: RequestParams = {}) =>
		this.http.request<LineCategoryResource[], BscoreImportResult>({
			path: `/api/v1/line-shop/category-full`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Line-Shop
	 * @name V1LineShopTrackingEventsNormalCreate
	 * @summary 回傳不含有商品資訊的事件資料
	 * @request POST:/api/v1/line-shop/tracking/events/normal
	 */
	v1LineShopTrackingEventsNormalCreate = (
		data: V1LineShopTrackingEventsNormalCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: object;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/line-shop/tracking/events/normal`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Line-Shop
	 * @name V1LineShopTrackingEventsProductsCreate
	 * @summary 回傳含有商品資訊的事件資料
	 * @request POST:/api/v1/line-shop/tracking/events/products
	 */
	v1LineShopTrackingEventsProductsCreate = (
		data: V1LineShopTrackingEventsProductsCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: object;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/line-shop/tracking/events/products`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Message-Bar
	 * @name V1MessageBarsList
	 * @summary 取得公告條目資訊
	 * @request GET:/api/v1/message-bars
	 */
	v1MessageBarsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MessageBarResource[];
				};
			},
			any
		>({
			path: `/api/v1/message-bars`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product-Combination
	 * @name V1ProductCombinationsList
	 * @summary 取得商品資訊
	 * @request GET:/api/v1/product-combinations
	 */
	v1ProductCombinationsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCombinationResource[];
				};
			},
			any
		>({
			path: `/api/v1/product-combinations`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product-Combination
	 * @name V1ProductCombinationsDetail
	 * @summary 取得單一商品資訊
	 * @request GET:/api/v1/product-combinations/{id}
	 */
	v1ProductCombinationsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCombinationResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/product-combinations/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product-Combination-Discount
	 * @name V1ProductCombinationDiscountsList
	 * @summary 取得組合優惠資訊
	 * @request GET:/api/v1/product-combination-discounts
	 */
	v1ProductCombinationDiscountsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCombinationDiscountResource[];
				};
			},
			any
		>({
			path: `/api/v1/product-combination-discounts`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product-Combination-Discount
	 * @name V1ProductCombinationDiscountsDetail
	 * @summary 取得單一組合優惠資訊
	 * @request GET:/api/v1/product-combination-discounts/{id}
	 */
	v1ProductCombinationDiscountsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductCombinationDiscountCollection;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/product-combination-discounts/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product
	 * @name V1ProductsList
	 * @summary 取得商品資訊
	 * @request GET:/api/v1/products
	 */
	v1ProductsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductResource[];
				};
			},
			any
		>({
			path: `/api/v1/products`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name V1ProductsDetail
	 * @summary 取得單一商品資訊
	 * @request GET:/api/v1/products/{id}
	 */
	v1ProductsDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/products/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的庫存資料
	 *
	 * @tags Product-Stock
	 * @name V1ProductsStocksList
	 * @summary 取得商品庫存資訊
	 * @request GET:/api/v1/products/stocks
	 */
	v1ProductsStocksList = (query: V1ProductsStocksListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: StockResource[];
				};
			},
			any
		>({
			path: `/api/v1/products/stocks`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product-New-Release
	 * @name V1ProductNewReleasesList
	 * @summary 取得新品上市資訊
	 * @request GET:/api/v1/product-new-releases
	 */
	v1ProductNewReleasesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductNewReleaseResource[];
				};
			},
			any
		>({
			path: `/api/v1/product-new-releases`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product-New-Release
	 * @name V1ProductNewReleasesDetail
	 * @summary 取得單一新品上市資訊
	 * @request GET:/api/v1/product-new-releases/{id}
	 */
	v1ProductNewReleasesDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductNewReleaseShowResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/product-new-releases/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product-Series
	 * @name V1ProductSeriesList
	 * @summary 取得商品主系列資訊
	 * @request GET:/api/v1/product-series
	 */
	v1ProductSeriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						series?: ProductSeriesResource[];
					};
				};
			},
			any
		>({
			path: `/api/v1/product-series`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product-Series
	 * @name V1ProductSeriesDetail
	 * @summary 取得單一商品主系列資訊
	 * @request GET:/api/v1/product-series/{id}
	 */
	v1ProductSeriesDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						series?: ProductSeriesResource;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/product-series/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Product-Sub-Series
	 * @name V1ProductSubSeriesList
	 * @summary 取得商品子類別資訊
	 * @request GET:/api/v1/product-sub-series
	 */
	v1ProductSubSeriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						sub_series?: ProductSubSeriesResource[];
					};
				};
			},
			any
		>({
			path: `/api/v1/product-sub-series`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product-Sub-Series
	 * @name V1ProductSubSeriesDetail
	 * @summary 取得單一商品子類別資訊
	 * @request GET:/api/v1/product-sub-series/{id}
	 */
	v1ProductSubSeriesDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ProductSubSeriesResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/product-sub-series/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Registration-Coupon-Event
	 * @name V1RegistrationCouponEventsList
	 * @summary 取得註冊禮券活動資訊
	 * @request GET:/api/v1/registration-coupon-events
	 */
	v1RegistrationCouponEventsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: RegistrationCouponEventResource[];
				};
			},
			any
		>({
			path: `/api/v1/registration-coupon-events`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Shopping-Cart
	 * @name V1UserOrderShoppingCartList
	 * @summary 取得暫存訂單
	 * @request GET:/api/v1/user/order/shopping-cart
	 * @secure
	 */
	v1UserOrderShoppingCartList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ShoppingCartResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/shopping-cart`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Shopping-Cart
	 * @name V1UserOrderShoppingCartUpdate
	 * @summary 建立或更新暫存訂單
	 * @request PUT:/api/v1/user/order/shopping-cart
	 * @secure
	 */
	v1UserOrderShoppingCartUpdate = (
		data: V1UserOrderShoppingCartUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: object;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/shopping-cart`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags System
	 * @name V1SystemMaintainSettingsList
	 * @summary 取得系統維護資訊
	 * @request GET:/api/v1/system/maintain-settings
	 */
	v1SystemMaintainSettingsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: SystemMaintainSettingResource[];
				};
			},
			any
		>({
			path: `/api/v1/system/maintain-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Threshold-Reward
	 * @name V1ThresholdRewardsList
	 * @summary 取得滿額贈資訊
	 * @request GET:/api/v1/threshold-rewards
	 */
	v1ThresholdRewardsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ThresholdRewardResource[];
				};
			},
			any
		>({
			path: `/api/v1/threshold-rewards`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Threshold-Reward
	 * @name V1ThresholdRewardsDetail
	 * @summary 取得單一滿額贈資訊
	 * @request GET:/api/v1/threshold-rewards/{id}
	 */
	v1ThresholdRewardsDetail = (id: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: ThresholdRewardResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/threshold-rewards/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRegisterCreate
	 * @summary 會員註冊
	 * @request POST:/api/v1/auth/register
	 */
	v1AuthRegisterCreate = (data: V1AuthRegisterCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						user_id?: number;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/register`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendUserVerificationEmailCreate
	 * @summary 寄送會員註冊驗證信件
	 * @request POST:/api/v1/auth/send-user-verification-email
	 */
	v1AuthSendUserVerificationEmailCreate = (
		data: V1AuthSendUserVerificationEmailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-user-verification-email`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * @description 此 API 用於驗證信箱驗證碼，透過 use_for 參數判斷使用情境(註冊/重設密碼)，根據情境不同做不同處理（註冊：會判斷是否已驗證過｜重設密碼：驗證成功後回傳 key）
	 *
	 * @tags Auth
	 * @name V1AuthVerifyEmailCodeCreate
	 * @summary 驗證信箱驗證碼
	 * @request POST:/api/v1/auth/verify-email-code
	 */
	v1AuthVerifyEmailCodeCreate = (
		data: V1AuthVerifyEmailCodeCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/verify-email-code`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLoginCreate
	 * @summary 會員一般登入
	 * @request POST:/api/v1/auth/login
	 */
	v1AuthLoginCreate = (data: V1AuthLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example "eyJ0e..." */
						token?: string;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/login`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthRefreshTokenCreate
	 * @summary Refresh Token
	 * @request POST:/api/v1/auth/refresh-token
	 * @secure
	 */
	v1AuthRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						token?: string;
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/auth/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLogoutCreate
	 * @summary 登出
	 * @request POST:/api/v1/auth/logout
	 * @secure
	 */
	v1AuthLogoutCreate = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/logout`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於單獨驗證密碼情境，驗證成功會回傳 key
	 *
	 * @tags Auth
	 * @name V1AuthVerifyPasswordCreate
	 * @summary 會員驗證密碼
	 * @request POST:/api/v1/auth/verify-password
	 */
	v1AuthVerifyPasswordCreate = (
		data: V1AuthVerifyPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
					}[];
			  }
		>({
			path: `/api/v1/auth/verify-password`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthLineLinkCreate
	 * @summary 會員綁定 Line 帳號
	 * @request POST:/api/v1/auth/line/link
	 */
	v1AuthLineLinkCreate = (data: V1AuthLineLinkCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/line/link`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthCheckUserEmailCreate
	 * @summary 確認 email 是否為會員
	 * @request POST:/api/v1/auth/check-user-email
	 */
	v1AuthCheckUserEmailCreate = (
		data: V1AuthCheckUserEmailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** 會員ID */
						user_id?: number;
						/** 會員信箱驗證狀態 */
						verified_status?: boolean;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					}[];
			  }
		>({
			path: `/api/v1/auth/check-user-email`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthSendResetPasswordVerificationEmailCreate
	 * @summary 寄送重設密碼驗證信
	 * @request POST:/api/v1/auth/send-reset-password-verification-email
	 */
	v1AuthSendResetPasswordVerificationEmailCreate = (
		data: V1AuthSendResetPasswordVerificationEmailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate,
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/send-reset-password-verification-email`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Auth
	 * @name V1AuthResetPasswordCreate
	 * @summary 重設會員密碼
	 * @request POST:/api/v1/auth/reset-password
	 */
	v1AuthResetPasswordCreate = (
		data: V1AuthResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/auth/reset-password`,
			method: 'POST',
			body: data,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserList
	 * @summary 取得會員資訊
	 * @request GET:/api/v1/user
	 * @secure
	 */
	v1UserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			any
		>({
			path: `/api/v1/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserUpdate
	 * @summary 更新會員資訊
	 * @request PUT:/api/v1/user
	 * @secure
	 */
	v1UserUpdate = (query: V1UserUpdateRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User
	 * @name V1UserLevelRulesList
	 * @summary 取得會員等級規則列表
	 * @request GET:/api/v1/user/level-rules
	 */
	v1UserLevelRulesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * Level
						 * @example "1"
						 */
						level?: number;
						/**
						 * 累金級距
						 * @example "$10,000 ~ $20,000"
						 */
						money?: string;
						/**
						 * 等級折扣
						 * @format float
						 * @example "0.95"
						 */
						level_discount?: number;
					}[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/level-rules`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Recipient
	 * @name V1UserRecipientsList
	 * @summary 取得會員常用收件人資料
	 * @request GET:/api/v1/user/recipients
	 * @secure
	 */
	v1UserRecipientsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						HOME?: UserRecipientResource[];
						STORE?: UserRecipientResource[];
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/recipients`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Recipient
	 * @name V1UserRecipientCreate
	 * @summary 新增會員常用收件人資料
	 * @request POST:/api/v1/user/recipient
	 * @secure
	 */
	v1UserRecipientCreate = (data: V1UserRecipientCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserRecipientResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						recipient_id?: string[];
					};
			  }
		>({
			path: `/api/v1/user/recipient`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Recipient
	 * @name V1UserRecipientUpdate
	 * @summary 變更會員常用收件人資料
	 * @request PUT:/api/v1/user/recipient/{recipientId}
	 * @secure
	 */
	v1UserRecipientUpdate = (
		{ recipientId, ...query }: V1UserRecipientUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserRecipientResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						recipient_id?: string[];
					};
			  }
		>({
			path: `/api/v1/user/recipient/${recipientId}`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Recipient
	 * @name V1UserRecipientDelete
	 * @summary 刪除會員常用收件人資料
	 * @request DELETE:/api/v1/user/recipient/{recipientId}
	 * @secure
	 */
	v1UserRecipientDelete = (recipientId: number, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/recipient/${recipientId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Invoice
	 * @name V1UserInvoicesList
	 * @summary 取得會員常用發票資料
	 * @request GET:/api/v1/user/invoices
	 * @secure
	 */
	v1UserInvoicesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						PERSON?: UserInvoiceResource[];
						COMPANY?: UserInvoiceResource[];
					};
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/invoices`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Invoice
	 * @name V1UserInvoiceCreate
	 * @summary 新增會員常用發票資料
	 * @request POST:/api/v1/user/invoice
	 * @secure
	 */
	v1UserInvoiceCreate = (data: V1UserInvoiceCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserInvoiceResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						type?: string[];
					};
			  }
		>({
			path: `/api/v1/user/invoice`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.UrlEncoded,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Invoice
	 * @name V1UserInvoiceUpdate
	 * @summary 變更會員常用發票資料
	 * @request PUT:/api/v1/user/invoice/{invoiceId}
	 * @secure
	 */
	v1UserInvoiceUpdate = (
		{ invoiceId, ...query }: V1UserInvoiceUpdateRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserInvoiceResource;
				};
			},
			| BscoreImportResult
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						invoice_id?: string[];
					};
			  }
		>({
			path: `/api/v1/user/invoice/${invoiceId}`,
			method: 'PUT',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Invoice
	 * @name V1UserInvoiceDelete
	 * @summary 刪除會員常用發票資料
	 * @request DELETE:/api/v1/user/invoice/{invoiceId}
	 * @secure
	 */
	v1UserInvoiceDelete = (invoiceId: number, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/invoice/${invoiceId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 欲查詢的會員ID (以「,」分隔，一次最多查詢 3000 筆)
	 *
	 * @tags User-Omni
	 * @name V1OmniGetUserInfoCreate
	 * @summary Omni 取得會員資料
	 * @request POST:/api/v1/omni/get-user-info
	 * @secure
	 */
	v1OmniGetUserInfoCreate = (
		data: V1OmniGetUserInfoCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserOmniResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/omni/get-user-info`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Coupon
	 * @name V1UserCouponsList
	 * @summary 取得會員持有的優惠券列表
	 * @request GET:/api/v1/user/coupons
	 * @secure
	 */
	v1UserCouponsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserCouponResource[];
				};
			},
			any
		>({
			path: `/api/v1/user/coupons`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Coupon
	 * @name V1UserCouponsReceiveCreate
	 * @summary 領取優惠券
	 * @request POST:/api/v1/user/coupons/receive
	 * @secure
	 */
	v1UserCouponsReceiveCreate = (
		data: V1UserCouponsReceiveCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserCouponResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/coupons/receive`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得會員的訂單列表資訊
	 *
	 * @tags User-Order
	 * @name V1UserOrdersList
	 * @summary 取得會員訂單列表
	 * @request GET:/api/v1/user/orders
	 * @secure
	 */
	v1UserOrdersList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OrderListResource[];
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/orders`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderDetail
	 * @summary 取得會員特定訂單的詳細資訊
	 * @request GET:/api/v1/user/order/{orderId}
	 * @secure
	 */
	v1UserOrderDetail = (orderId: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OrderResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/${orderId}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderCheckoutCreate
	 * @summary 建立正式訂單
	 * @request POST:/api/v1/user/order/checkout
	 * @secure
	 */
	v1UserOrderCheckoutCreate = (
		data: V1UserOrderCheckoutCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: OrderListResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/user/order/checkout`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderPaymentCreate
	 * @summary 進入付款流程
	 * @request POST:/api/v1/user/order/payment
	 * @secure
	 */
	v1UserOrderPaymentCreate = (
		data: V1UserOrderPaymentCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/order/payment`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags User-Order
	 * @name V1UserOrderCancelUpdate
	 * @summary 取消訂單
	 * @request PUT:/api/v1/user/order/{orderId}/cancel
	 * @secure
	 */
	v1UserOrderCancelUpdate = (orderId: number, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, BscoreImportResult>({
			path: `/api/v1/user/order/${orderId}/cancel`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 僅回傳狀態為上線的資料
	 *
	 * @tags Video
	 * @name V1VideosList
	 * @summary 取得 Youtube 影片資訊
	 * @request GET:/api/v1/videos
	 */
	v1VideosList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: VideoResource[];
				};
			},
			any
		>({
			path: `/api/v1/videos`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestEcpayInvoiceCheckBarCodeList
	 * @summary 【後端內部測試用】驗證手機載具條碼格式
	 * @request GET:/api/v1/test/ecpay-invoice/check-bar-code
	 */
	v1TestEcpayInvoiceCheckBarCodeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/ecpay-invoice/check-bar-code`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestEcpayInvoiceGenList
	 * @summary 【後端內部測試用】綠界開立發票
	 * @request GET:/api/v1/test/ecpay-invoice/gen
	 */
	v1TestEcpayInvoiceGenList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/ecpay-invoice/gen`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestKotsmsCheckPointsList
	 * @summary 【後端內部測試用】簡訊王-檢查剩餘點數
	 * @request GET:/api/v1/test/kotsms/check-points
	 */
	v1TestKotsmsCheckPointsList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/kotsms/check-points`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestKotsmsSendList
	 * @summary 【後端內部測試用】簡訊王-發送簡訊
	 * @request GET:/api/v1/test/kotsms/send
	 */
	v1TestKotsmsSendList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/kotsms/send`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestKotsmsCallbackList
	 * @summary 【後端內部測試用】簡訊王-發送簡訊
	 * @request GET:/api/v1/test/kotsms/callback
	 */
	v1TestKotsmsCallbackList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/kotsms/callback`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行 kmsgid=385509979，gid example: 385509979
	 *
	 * @tags Library-Test
	 * @name V1TestKotsmsCheckSmsDetail
	 * @summary 【後端內部測試用】簡訊王-發送結果查詢
	 * @request GET:/api/v1/test/kotsms/check-sms/{gid}
	 */
	v1TestKotsmsCheckSmsDetail = (gid: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/kotsms/check-sms/${gid}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLinePayConfirmList
	 * @summary 【後端內部測試用】LinePay 付款後 callback
	 * @request GET:/api/v1/test/line-pay/confirm
	 */
	v1TestLinePayConfirmList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-pay/confirm`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLinePayRequestList
	 * @summary 【後端內部測試用】LinePay 產生付款連結
	 * @request GET:/api/v1/test/line-pay/request
	 */
	v1TestLinePayRequestList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-pay/request`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLinePayCheckList
	 * @summary 【後端內部測試用】LinePay 確認付款狀態
	 * @request GET:/api/v1/test/line-pay/check
	 */
	v1TestLinePayCheckList = (transactionId: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-pay/check`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackList
	 * @summary 【後端內部測試用】LineLogin (只回傳 code 不做解析)
	 * @request GET:/api/v1/test/line-login/callback
	 */
	v1TestLineLoginCallbackList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						code?: string;
					};
				};
			},
			any
		>({
			path: `/api/v1/test/line-login/callback`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestLineLoginCallbackVerifyList
	 * @summary 【後端內部測試用】LineLogin 接收 code 解析
	 * @request GET:/api/v1/test/line-login/callback-verify
	 */
	v1TestLineLoginCallbackVerifyList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/line-login/callback-verify`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 非正式站環境才能使用被呼叫執行
	 *
	 * @tags Library-Test
	 * @name V1TestOmniSyncUserList
	 * @summary 【後端內部測試用】Omni 同步會員資料
	 * @request GET:/api/v1/test/omni/sync-user
	 */
	v1TestOmniSyncUserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: object;
			},
			any
		>({
			path: `/api/v1/test/omni/sync-user`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 依權重大小排序，僅回傳前三筆的資料
	 *
	 * @tags Banner
	 * @name V1BannersList
	 * @summary 取得 Banner 資訊
	 * @request GET:/api/v1/banners
	 */
	v1BannersList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource[];
				};
			},
			any
		>({
			path: `/api/v1/banners`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Banner
	 * @name V1BannersDetail
	 * @summary 取得單一 Banner 資訊
	 * @request GET:/api/v1/banners/{id}
	 */
	v1BannersDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource;
				};
			},
			BscoreImportResult
		>({
			path: `/api/v1/banners/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」的分類
	 *
	 * @tags News-Category
	 * @name V1NewsCategoriesNavList
	 * @summary 部落格文章分類
	 * @request GET:/api/v1/news-categories/nav
	 */
	v1NewsCategoriesNavList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsCategoriesResource[];
				};
			},
			any
		>({
			path: `/api/v1/news-categories/nav`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的部落格文章
	 *
	 * @tags News-Category
	 * @name V1NewsCategoriesList
	 * @summary 首頁各分類下的部落格文章列表
	 * @request GET:/api/v1/news-categories
	 */
	v1NewsCategoriesList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsCategoriesCollection[];
				};
			},
			any
		>({
			path: `/api/v1/news-categories`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexNewCategoriesDetail
	 * @summary 取得所有部落格文章分類-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/new-categories
	 * @secure
	 */
	backstagexNewCategoriesDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/new-categories`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的部落格文章
	 *
	 * @tags News
	 * @name V1NewsList
	 * @summary 部落格文章清單頁
	 * @request GET:/api/v1/news
	 */
	v1NewsList = (query: V1NewsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: NewsResource[];
					links?: {
						/**
						 * 第一頁網址
						 * @example "http://localhost/news?page=1"
						 */
						first?: string;
						/**
						 * 最後頁網址
						 * @example "http://localhost/news?page=1"
						 */
						last?: string;
						/**
						 * 前頁網址
						 * @example "null"
						 */
						prev?: string;
						/**
						 * 下頁網址
						 * @example "null"
						 */
						next?: string;
					};
					meta?: {
						/**
						 * 當前頁數
						 * @example 1
						 */
						current_page?: number;
						/**
						 * 當前頁數的起始資料在整體資料中位於第 N 筆
						 * @example 1
						 */
						from?: number;
						/**
						 * 最後一頁頁數
						 * @example 1
						 */
						last_page?: number;
						/**
						 * 查詢網址
						 * @example "http://localhost/news"
						 */
						path?: string;
						/**
						 * 每頁顯示筆數
						 * @example 6
						 */
						per_page?: number;
						/**
						 * 當前頁面的結束資料在整體資料中位於第 N 筆
						 * @example 6
						 */
						to?: number;
						/**
						 * 整體資料筆數
						 * @example 6
						 */
						total?: number;
						/** 連結 */
						links?: {
							/**
							 * 網址
							 * @example "null"
							 */
							url?: string;
							/**
							 * 標籤
							 * @example "&laquo; Previous"
							 */
							label?: string;
							/**
							 * 是否啟用
							 * @example false
							 */
							active?: boolean;
						}[];
					};
				};
			},
			any
		>({
			path: `/api/v1/news`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內的部落格文章
	 *
	 * @tags News
	 * @name V1NewsDetail
	 * @summary 部落格文章內頁
	 * @request GET:/api/v1/news/{id}
	 */
	v1NewsDetail = ({ id, ...query }: V1NewsDetailRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						news?: NewsResource;
						/**
						 * @format int64
						 * @example 1
						 */
						previous?: number;
						/**
						 * @format int64
						 * @example 1
						 */
						next?: number;
					};
				};
			},
			| BscoreImportResult
			| {
					/** @example "403" */
					status?: number;
					/** @example "preview key 不合法" */
					message?: string;
					/** @example "PREVIEW_KEY_IS_INVALID" */
					error_code?: string;
			  }
		>({
			path: `/api/v1/news/${id}`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 狀態「上架」且在「活動時間」內最新的部落格文章
	 *
	 * @tags News
	 * @name V1NewsNewestList
	 * @summary 部落格文章內頁
	 * @request GET:/api/v1/news/newest
	 */
	v1NewsNewestList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						news?: NewsResource;
					};
				};
			},
			any
		>({
			path: `/api/v1/news/newest`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Backstagex-List
	 * @name BackstagexNewTagsDetail
	 * @summary 取得所有部落格文章標籤-後台模組使用
	 * @request GET:/api/backstagex/{menu_item_key}/new-tags
	 * @secure
	 */
	backstagexNewTagsDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BsxListResource[];
				};
			},
			any
		>({
			path: `/api/backstagex/${menuItemKey}/new-tags`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 後台登入用，會取得 JWT Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreLoginCreate
	 * @request POST:/api/bscore/login
	 */
	bscoreLoginCreate = (data: BscoreLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/** 模組 route 列表，提供跳轉用 */
						default?: string[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/login`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Refresh Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreRefreshTokenCreate
	 * @request POST:/api/bscore/refresh-token
	 * @secure
	 */
	bscoreRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 變更密碼
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreResetPasswordCreate
	 * @request POST:/api/bscore/resetPassword
	 * @secure
	 */
	bscoreResetPasswordCreate = (
		data: BscoreResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/resetPassword`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_admin_user
	 * @name BscoreMeList
	 * @summary 取得目前使用者的資料
	 * @request GET:/api/bscore/me
	 * @secure
	 */
	bscoreMeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 重置使用者，並寄送驗證信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserInitAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/init-account
	 * @secure
	 */
	bscoreBscoreAdminUserInitAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserInitAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/init-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 寄送帳號解鎖信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserUnlockAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/unlock-account
	 * @secure
	 */
	bscoreBscoreAdminUserUnlockAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/unlock-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得帳號解鎖信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetUnlockMailInfoList
	 * @request GET:/api/bscore/get-unlock-mail-info
	 */
	bscoreGetUnlockMailInfoList = (
		query: BscoreGetUnlockMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-unlock-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 解鎖帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreUnlockAccountCreate
	 * @request POST:/api/bscore/unlock-account
	 */
	bscoreUnlockAccountCreate = (
		data: BscoreUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/unlock-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得信箱驗證信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetVerifyMailInfoList
	 * @request GET:/api/bscore/get-verify-mail-info
	 */
	bscoreGetVerifyMailInfoList = (
		query: BscoreGetVerifyMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-verify-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreVerifyAccountCreate
	 * @request POST:/api/bscore/verify-account
	 */
	bscoreVerifyAccountCreate = (
		data: BscoreVerifyAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/verify-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreForgetPasswordCreate
	 * @request POST:/api/bscore/forget-password
	 */
	bscoreForgetPasswordCreate = (
		data: BscoreForgetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						next_can_send?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/forget-password`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼資訊
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetForgetMailInfoList
	 * @request GET:/api/bscore/get-forget-mail-info
	 */
	bscoreGetForgetMailInfoList = (
		query: BscoreGetForgetMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-forget-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼
	 *
	 * @tags bscore_admin_user
	 * @name BscoreResetPasswordFromMailCreate
	 * @request POST:/api/bscore/reset-password-from-mail
	 */
	bscoreResetPasswordFromMailCreate = (
		data: BscoreResetPasswordFromMailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/reset-password-from-mail`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetModuleConfigDetail
	 * @summary 取得選單對應的模組 config
	 * @request GET:/api/bscore/{menu_item_key}/get-module-config
	 * @secure
	 */
	bscoreGetModuleConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/get-module-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetSearchableDetail
	 * @summary 取得進階搜尋欄位
	 * @request GET:/api/bscore/{menu_item_key}/get-searchable
	 * @secure
	 */
	bscoreGetSearchableDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<AdvanceSearchable[], any>({
			path: `/api/bscore/${menuItemKey}/get-searchable`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般搜尋
	 *
	 * @tags bscore
	 * @name BscoreSearchCreate
	 * @request POST:/api/bscore/{menu_item_key}/search
	 * @secure
	 */
	bscoreSearchCreate = (menuItemKey: string, data: SearchRequest, params: RequestParams = {}) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreAdvanceSearchCreate
	 * @summary 進階搜尋
	 * @request POST:/api/bscore/{menu_item_key}/advance-search
	 * @secure
	 */
	bscoreAdvanceSearchCreate = (
		menuItemKey: string,
		data: AdvanceSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/advance-search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreateDetail
	 * @summary 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/create
	 * @secure
	 */
	bscoreCreateDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreEditDetail
	 * @summary 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/edit
	 * @secure
	 */
	bscoreEditDetail = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreate
	 * @summary 新增
	 * @request POST:/api/bscore/{menu_item_key}
	 * @secure
	 */
	bscoreCreate = (menuItemKey: string, data: GetDataCreate, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdate
	 * @summary 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreUpdate = (
		menuItemKey: string,
		id: string,
		data: GetDataUpdate,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDelete
	 * @summary 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreDelete = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCopyCreate
	 * @summary 複製
	 * @request POST:/api/bscore/{menu_item_key}/copy
	 * @secure
	 */
	bscoreCopyCreate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/copy`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDestroyAllCreate
	 * @summary 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/destroy-all
	 * @secure
	 */
	bscoreDestroyAllCreate = (
		menuItemKey: string,
		data: BscoreDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateRowCreate
	 * @summary 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/update-row
	 * @secure
	 */
	bscoreUpdateRowCreate = (
		menuItemKey: string,
		data: BscoreUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateListCreate
	 * @summary reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/update-list
	 * @secure
	 */
	bscoreUpdateListCreate = (
		{ menuItemKey, ...query }: BscoreUpdateListCreateRequestParams,
		data: BscoreUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Menu Item 對應的 Dashboard Config
	 *
	 * @tags bscore_dashboard
	 * @name BscoreGetDashboardConfigDetail
	 * @request GET:/api/bscore/{menu_item_key}/get-dashboard-config
	 * @secure
	 */
	bscoreGetDashboardConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/get-dashboard-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Metric Key 對應的 Metric
	 *
	 * @tags bscore_dashboard
	 * @name BscoreMetricDetail
	 * @request GET:/api/bscore/metric/{metric_key}
	 * @secure
	 */
	bscoreMetricDetail = (metricKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/metric/${metricKey}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelExportCreate
	 * @summary 匯出
	 * @request POST:/api/bscore/{menu_item_key}/excel-export
	 * @secure
	 */
	bscoreExcelExportCreate = (
		menuItemKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelExportCreate
	 * @summary SubList 匯出
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-export
	 * @secure
	 */
	bscoreSubListExcelExportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportCreate
	 * @summary 匯入
	 * @request POST:/api/bscore/{menu_item_key}/excel-import
	 * @secure
	 */
	bscoreExcelImportCreate = (
		menuItemKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportUploadUrlCreate
	 * @summary 取得匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreExcelImportUploadUrlCreate = (
		menuItemKey: string,
		data: BscoreExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportCreate
	 * @summary SubList 匯入
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import
	 * @secure
	 */
	bscoreSubListExcelImportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportUploadUrlCreate
	 * @summary 取得 SubList 匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreSubListExcelImportUploadUrlCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportExampleFileCreate
	 * @summary 下載匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-example-file
	 * @secure
	 */
	bscoreExcelImportExampleFileCreate = (
		menuItemKey: string,
		data: BscoreExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportExampleFileCreate
	 * @summary 下載 SubList 匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-example-file
	 * @secure
	 */
	bscoreSubListExcelImportExampleFileCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description 列表頁側邊欄，列出 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreSubMenuNaviList
	 * @request GET:/api/bscore/sub-menu-navi
	 * @secure
	 */
	bscoreSubMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuResource[];
				};
			},
			any
		>({
			path: `/api/bscore/sub-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 列表頁側邊欄，沒有 Menu 的 Menuitem 會在此列出
	 *
	 * @tags bscore_menu
	 * @name BscoreMenuItemNaviList
	 * @request GET:/api/bscore/menu-item-navi
	 * @secure
	 */
	bscoreMenuItemNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuItemResource[];
				};
			},
			any
		>({
			path: `/api/bscore/menu-item-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於列表頁側邊欄置底的 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreBottomMenuNaviList
	 * @request GET:/api/bscore/bottom-menu-navi
	 * @secure
	 */
	bscoreBottomMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						menus?: MenuResource[];
						menuItems?: MenuItemResource[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/bottom-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_menu
	 * @name BscoreInfoDetail
	 * @summary 提供前端 menu_item_key 的資訊
	 * @request GET:/api/bscore/{menu_item_key}/info
	 * @secure
	 */
	bscoreInfoDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台設定
	 *
	 * @tags bscore
	 * @name BscoreClientSettingsList
	 * @request GET:/api/bscore/client-settings
	 */
	bscoreClientSettingsList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/client-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsEditDetail
	 * @summary 設定編輯頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/settings/edit
	 * @secure
	 */
	bscoreSettingsEditDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsUpdate
	 * @summary 設定編輯頁更新
	 * @request PUT:/api/bscore/{menu_item_key}/settings
	 * @secure
	 */
	bscoreSettingsUpdate = (
		menuItemKey: string,
		data: BscoreSettingsUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListSearchCreate
	 * @summary SubList 一般搜尋
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/search
	 * @secure
	 */
	bscoreSubListSearchCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: SearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreateDetail
	 * @summary SubList 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/create
	 * @secure
	 */
	bscoreSubListCreateDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListEditDetail
	 * @summary SubList 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}/edit
	 * @secure
	 */
	bscoreSubListEditDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreate
	 * @summary SubList 新增
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}
	 * @secure
	 */
	bscoreSubListCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdate
	 * @summary SubList 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListUpdate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDelete
	 * @summary SubList 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListDelete = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDestroyAllCreate
	 * @summary SubList 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/destroy-all
	 * @secure
	 */
	bscoreSubListDestroyAllCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateRowCreate
	 * @summary SubList 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-row
	 * @secure
	 */
	bscoreSubListUpdateRowCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateListCreate
	 * @summary SubList reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-list
	 * @secure
	 */
	bscoreSubListUpdateListCreate = (
		{ menuItemKey, id, subListKey, ...query }: BscoreSubListUpdateListCreateRequestParams,
		data: BscoreSubListUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreTaskDetail
	 * @summary 取得 task 狀態
	 * @request GET:/api/bscore/task/{task}
	 * @secure
	 */
	bscoreTaskDetail = (task: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						status?: 'WAITING' | 'PROCESSING' | 'FINISH' | 'FAILED';
						result?: BscoreExportResult | BscoreImportResult;
					};
				};
			},
			any
		>({
			path: `/api/bscore/task/${task}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUploadUploadUrlCreate
	 * @summary 取得檔案上傳的 url
	 * @request POST:/api/bscore/upload/upload-url
	 * @secure
	 */
	bscoreUploadUploadUrlCreate = (
		data: BscoreUploadUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						filename?: string;
						/** 上傳用 Url */
						uploadUrl?: string;
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/upload/upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台版本資訊
	 *
	 * @tags bscore
	 * @name BscoreVersionList
	 * @request GET:/api/bscore/version
	 * @secure
	 */
	bscoreVersionList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/version`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
}
