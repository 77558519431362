/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Crumbs } from 'components/atoms/Breadcrumb';
import { BannerType, SwitchTabType } from 'layouts/ProductPage/component';
import { Action, createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { Dispatch } from 'react';

import { setItem } from 'util/storage';
import { Media } from 'util/hook/useMedia';
import {
	ProductCollection,
	ProductResource,
	ProductSubSeriesResource,
} from 'util/api/swaggerApi/data-contracts';
import { ImpressionsProps } from 'types/impressions';
import { omniEvent } from 'util/omniEvent';
import { GetState, State as GlobalState } from './reducers';
import { getDiscountEventById } from './discountEvent';
import { getNewReleaseById } from './newRelease';
import { getCombinationDiscountById } from './combination';

type pagePayload = {
	chName: string;
	enName: string;
	crumbs: Crumbs[];
	SwitchTabsData: SwitchTabType[];
	BannerData: BannerType[];
};

export const setPage = createAction('SET_PAGE', (media: Media) => (_: any, getState: GetState) => {
	const {
		routing,
		products: { series },
		combination: { selectDiscount },
		newReleases: { newReleases },
		discountEvents: { selectDiscountEvent },
		member: { userInfo },
	} = getState();
	const query = new URLSearchParams(routing.search);
	console.log('query', query, routing.search);
	console.log(query.has('discountEvent'));

	let result: pagePayload = {
		chName: '',
		enName: '',
		crumbs: [],
		SwitchTabsData: [],
		BannerData: [],
	};

	const impressions: ImpressionsProps[] = [];

	if (query.has('series')) {
		const id = Number(query.get('series'));
		const main = series?.filter(serie => serie.id === id)[0];
		// 主系列表頁
		result = {
			chName: main.name!,
			enName: 'Shop By Category',
			crumbs: [
				{ name: '首頁', link: '/' },
				{ name: '全部商品', link: '/products?all=1' },
				{ name: main.name!, link: `/products?series=${id}` },
			],
			SwitchTabsData: [],
			BannerData: [],
		};

		setItem(
			'breadcrumb',
			JSON.stringify([
				{ name: '全部商品', link: '/products?all=1' },
				{ name: main.name, link: `/products?series=${id}` },
			]),
		);

		main.subSeries?.forEach((serie: ProductSubSeriesResource) => {
			result.SwitchTabsData = [
				...result.SwitchTabsData,
				{ label: serie.name!, index: result.SwitchTabsData.length },
			];

			serie.products?.forEach((p: ProductResource, i: number) => {
				impressions.push({
					name: p.name!, // Name is required.
					id: p.materialNo! ?? p.id!, // ID is required.
					price: p.memberPrice || p.eventPrice!,
					brand: '愛康生技',
					category: serie.name!,
					variant: p.briefDescription!,
					position: i,
				});
			});

			result.BannerData = [
				...result.BannerData,
				{
					imgSrc: media === 'desktop' ? serie.webImage! : serie.mobileImage!,
					imgAlt: serie.name!,
					productsData: serie.products!,
					link: serie.link,
				},
			];
		});
	} else if (query.has('combination')) {
		const id = Number(query.get('combination'));
		const { title, webImage, mobileImage, link, combinations } = selectDiscount;
		result = {
			chName: title!,
			enName: 'Combo Sale',
			crumbs: [
				{ name: '首頁', link: '/' },
				{ name: title!, link: `/products?combination=${id}` },
			],
			SwitchTabsData: [],
			BannerData: [],
		};
		if (query.get('combination') !== 'true') {
			setItem(
				'breadcrumb',
				JSON.stringify({
					name: title!,
					link: `/products?combination=${id}`,
				}),
			);
		}

		combinations?.forEach((p, i) => {
			impressions.push({
				name: p.name!, // Name is required.
				id: p.materialNo! ?? p.id!, // ID is required.
				price: p.price!,
				brand: '愛康生技',
				variant: p.briefDescription!,
				position: i,
			});
		});
		result.BannerData = [
			...result.BannerData,
			{
				imgSrc: media === 'desktop' ? webImage! : mobileImage!,
				imgAlt: title!,
				productsData: combinations || [],
				link,
			},
		];
	} else if (query.has('newRelease')) {
		const id = Number(query.get('newRelease'));
		const { title, webImage, mobileImage, link, products } = newReleases;
		result = {
			chName: title!,
			enName: 'New Arrivals',
			crumbs: [
				{ name: '首頁', link: '/' },
				{ name: title!, link: `/products?newReleases=${id}` },
			],
			SwitchTabsData: [],
			BannerData: [],
		};
		setItem('breadcrumb', JSON.stringify({ name: title!, link: `/products?newRelease=${id}` }));
		products?.forEach((p: ProductCollection, i: number) => {
			impressions.push({
				name: p.name!, // Name is required.
				id: p.materialNo! ?? p.id!, // ID is required.
				price: p.price!,
				brand: '愛康生技',
				variant: p.briefDescription!,
				position: i,
			});
		});
		result.BannerData = [
			...result.BannerData,
			{
				imgSrc: media === 'desktop' ? webImage! : mobileImage!,
				imgAlt: title!,
				productsData: products || [],
				link,
			},
		];
	} else if (query.has('discountEvent')) {
		const id = Number(query.get('discountEvent'));

		const { title, webImage, mobileImage, products, combinations } = selectDiscountEvent;
		result = {
			chName: title!,
			enName: 'ON SALE',
			crumbs: [
				{ name: '首頁', link: '/' },
				{ name: title!, link: `/products?discountEvent=${id}` },
			],
			SwitchTabsData: [],
			BannerData: [],
		};
		setItem('breadcrumb', JSON.stringify({ name: title!, link: `/products?discountEvent=${id}` }));
		products?.forEach((p, i: number) => {
			impressions.push({
				name: p.name!, // Name is required.
				id: p.materialNo! ?? p.id!, // ID is required.
				price: p.price!,
				brand: '愛康生技',
				variant: p.briefDescription!,
				position: i,
			});
		});
		combinations?.forEach((p, i: number) => {
			impressions.push({
				name: p.name!, // Name is required.
				id: p.materialNo! ?? p.id!, // ID is required.
				price: p.price!,
				brand: '愛康生技',
				variant: p.briefDescription!,
				position: i,
			});
		});
		const combinationsAddProp = combinations?.map(item => ({ ...item, combination: true }));
		result.BannerData = [
			...result.BannerData,
			{
				imgSrc: media === 'desktop' ? webImage! : mobileImage!,
				imgAlt: title!,
				productsData: [...products!, ...combinationsAddProp!] || [],
				link: '',
			},
		];
	} else if (query.has('all')) {
		result = {
			chName: '全部商品',
			enName: 'SHOP ALL',
			crumbs: [
				{ name: '首頁', link: '/' },
				{ name: '全部商品', link: '/products?all=1' },
			],
			SwitchTabsData: [],
			BannerData: [],
		};
		setItem('breadcrumb', JSON.stringify({ name: '全部商品', link: '/products?all=1' }));

		series.forEach(serie => {
			result.SwitchTabsData = [
				...result.SwitchTabsData,
				{ label: serie.name!, index: result.SwitchTabsData.length },
			];

			const productsData = [] as ProductResource[];
			serie.subSeries?.forEach(s => {
				productsData.push(...s.products!);

				s.products?.forEach((p, i) => {
					impressions.push({
						name: p.name!, // Name is required.
						id: p.materialNo! ?? p.id!, // ID is required.
						price: p.price!,
						brand: '愛康生技',
						category: serie.name!,
						variant: p.briefDescription!,
						position: i,
					});
				});
			});
			result.BannerData = [
				...result.BannerData,
				{
					imgSrc: media === 'desktop' ? serie.webImage! : serie.mobileImage!,
					imgAlt: serie.name!,
					productsData,
					link: `/products?series=${serie.id}`,
				},
			];
		});
	}

	omniEvent(null); // Clear the previous i13n object.
	omniEvent({
		action: 'ViewContent',
		p: result.chName, // Page Name
		uid: userInfo.id ?? '', //  User ID is required if user has login
		currencyCode: 'TWD',
		impressions,
	});
	return result;
});

const fetchers = {
	newRelease: getNewReleaseById,
	combination: getCombinationDiscountById,
	discountEvent: getDiscountEventById,
};

export const fetchAndSetPage = createAction(
	'FETCH_AND_SET_PAGE',
	(media: Media) => async (dispatch: Dispatch<any>, getState: GetState) => {
		const { routing } = await getState();
		const query = new URLSearchParams(routing.search);

		const key = Object.keys(fetchers).find(k => query.has(k));
		if (key) {
			const id = parseInt(query.get(key)!, 10);
			await dispatch(fetchers[key as keyof typeof fetchers](id));
		}

		dispatch(setPage(media));
	},
);

export interface State {
	loading: boolean;
	chName: string;
	enName: string;
	crumbs: Crumbs[];
	SwitchTabsData: SwitchTabType[];
	BannerData: BannerType[];
}

export const defaultState: State = {
	loading: false,
	chName: '',
	enName: '',
	crumbs: [],
	SwitchTabsData: [],
	BannerData: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	productPage: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			SET_PAGE: (state, action: Action<pagePayload>) => ({
				...state,
				chName: action.payload.chName,
				enName: action.payload.enName,
				crumbs: action.payload.crumbs,
				SwitchTabsData: action.payload.SwitchTabsData,
				BannerData: action.payload.BannerData,
			}),
			FETCH_AND_SET_PAGE_PENDING: state => ({ ...state, loading: true }),
			FETCH_AND_SET_PAGE_FULFILLED: state => ({ ...state, loading: false }),
		},
		defaultState,
	),
};

const productPageActionsMap = {
	setPage,
	fetchAndSetPage,
};

const mapHooksToState = (state: GlobalState) => ({
	chName: state.productPage.chName,
	enName: state.productPage.enName.toUpperCase(),
	crumbs: state.productPage.crumbs,
	SwitchTabsData: state.productPage.SwitchTabsData,
	BannerData: state.productPage.BannerData,
	loading: state.productPage.loading,
});

type ProductPageSelector = ReturnType<typeof mapHooksToState>;
type ProductPageActionsMap = typeof productPageActionsMap;

export const useProductPage = () =>
	useRedux<ProductPageSelector, ProductPageActionsMap>(mapHooksToState, productPageActionsMap);
