import React, { Suspense, useEffect } from 'react';
import { History } from 'history';
import { Store } from 'redux';

import App from 'layouts/App';

import Loading from 'components/atoms/Loading';

import { HistoryContext, useRouting } from 'models/routing';

import { usePageView } from 'models/pageView';

import useRouter, { CustomRoute } from 'util/hook/useRouter';

interface RouterProperty {
	routes: CustomRoute;
	history: History;
	store: Store;
}

const Router: React.FC<RouterProperty> = ({ routes, history, store }) => {
	const { loading, component } = useRouter(routes, history, store);
	const [{ routing }] = useRouting();
	const [, { locationChange }] = usePageView();

	useEffect(() => {
		const fullLocation = `${window.location.origin}${routing.pathname}${routing.search}`;
		locationChange(fullLocation);
	}, [routing]);

	return (
		<HistoryContext.Provider value={history}>
			<App>{loading ? <Loading /> : <Suspense fallback={<Loading />}>{component}</Suspense>}</App>
		</HistoryContext.Provider>
	);
};

export default Router;
