import ProductModel from 'images/product-card-model.png';
import ProductModel1 from 'images/product-card-model1.png';
import ProductModel2 from 'images/product-card-model2.png';
import ProductModel3 from 'images/product-card-model3.png';
import HomeBanner from 'images/home-banner.png';

import Alison from 'images/images/Alison.png';
import ChiYun from 'images/images/ChiYun.png';
import Liu from 'images/images/Liu.png';
import Mika from 'images/images/Mika.png';

import Thunder from 'images/icon/thunder.inline.svg';
import { ProductCardProperty } from 'components/molecules/ProductCard';

const MockBannerData = [
	{
		image: HomeBanner,
		imgAlt: 'image1',
		link: 'https://fox.25sprout.com/',
	},
	{
		image: HomeBanner,
		imgAlt: 'image2',
		link: 'https://fox.25sprout.com/',
	},
	{
		image: HomeBanner,
		imgAlt: 'image3',
		link: 'https://fox.25sprout.com/',
	},
];

const MockProductListData: ProductCardProperty[] = [
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型',
		price: 399,
		hashtag1: 'New',
		images: [ProductModel],
		memberPrice: 100,
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
	},
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型1',
		price: 399,
		hashtag1: 'New',
		hashtag2: '涼感',
		images: [ProductModel1],
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
		eventPrice: 19,
		eventLabelData: [
			{ text: '限時下殺', backgroundColor: '#F27292', size: 'large', Icon: Thunder },
			{
				text: '買一送一',
				backgroundColor: '#8c7dbe',
			},
			{
				text: '滿千折百',
				backgroundColor: '#70BF54',
			},
		],
	},
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型2',
		price: 399,
		hashtag1: 'New',
		images: [ProductModel2],
		memberPrice: 100,
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
	},
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型3',
		price: 399,
		hashtag1: 'New',
		hashtag2: '涼感',
		images: [ProductModel3],
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
		eventPrice: 19,
		eventLabelData: [
			{ text: '限時下殺', backgroundColor: '#F27292', size: 'large', Icon: Thunder },
			{
				text: '買一送一',
				backgroundColor: '#8c7dbe',
			},
			{
				text: '滿千折百',
				backgroundColor: '#70BF54',
			},
		],
	},
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型4',
		price: 399,
		hashtag1: 'New',
		images: [ProductModel],
		memberPrice: 100,
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
	},
	{
		name: '[獨家聯名系列] 奧樂雞涼感棉 日用型5',
		price: 399,
		hashtag1: 'New',
		hashtag2: '涼感',
		images: [ProductModel],
		briefDescription: '護墊*8 + 日用*12 + 夜用*6 + 加長*4',
		eventPrice: 19,
		eventLabelData: [
			{ text: '限時下殺', backgroundColor: '#F27292', size: 'large', Icon: Thunder },
			{
				text: '買一送一',
				backgroundColor: '#8c7dbe',
			},
			{
				text: '滿千折百',
				backgroundColor: '#70BF54',
			},
		],
	},
];

/**
 * 請保持大於十筆資料
 *
 * 目前僅有四個色碼
 * - #e6e3f1
 * - #e3f7fd
 * - #d7edd0
 * - #fcdfe6
 */
const CommentsData = [
	{
		id: 0,
		imgSrc: Alison,
		jobTitle: '久坐美少女',
		name: 'Alison',
		content: '很怕熱的我，試過愛康之後立馬愛上！剛剛好的涼爽感真的舒服多了~',
		backgroundColor: '#e6e3f1',
	},
	{
		id: 1,
		imgSrc: Mika,
		jobTitle: '玩婚主持人',
		name: 'Mika',
		content: '工作較難暫離崗位，好險愛康吸收力蠻強！就算量大也不焦慮有外漏問題。',
		backgroundColor: '#e3f7fd',
	},
	{
		id: 2,
		imgSrc: ChiYun,
		jobTitle: '知知',
		name: 'ChiYun',
		content: '喜歡愛康衛生棉的香氣，又有很多選擇，包裝也很可愛，每次囤貨都好療癒',
		backgroundColor: '#d7edd0',
	},
	{
		id: 3,
		imgSrc: Liu,
		jobTitle: '小美',
		name: 'Liu',
		content: '跟朋友借衛生棉後就入坑，想不到竟然可以那麼乾爽！用過愛康就無法變心了！',
		backgroundColor: '#fcdfe6',
	},
	{
		id: 4,
		imgSrc: Alison,
		jobTitle: '久坐美少女',
		name: 'Alison',
		content: '很怕熱的我，試過愛康之後立馬愛上！剛剛好的涼爽感真的舒服多了~',
		backgroundColor: '#e6e3f1',
	},
	{
		id: 5,
		imgSrc: Mika,
		jobTitle: '玩婚主持人',
		name: 'Mika',
		content: '工作較難暫離崗位，好險愛康吸收力蠻強！就算量大也不焦慮有外漏問題。',
		backgroundColor: '#e3f7fd',
	},
	{
		id: 6,
		imgSrc: ChiYun,
		jobTitle: '知知',
		name: 'ChiYun',
		content: '喜歡愛康衛生棉的香氣，又有很多選擇，包裝也很可愛，每次囤貨都好療癒',
		backgroundColor: '#d7edd0',
	},
	{
		id: 7,
		imgSrc: Liu,
		jobTitle: '小美',
		name: 'Liu',
		content: '跟朋友借衛生棉後就入坑，想不到竟然可以那麼乾爽！用過愛康就無法變心了！',
		backgroundColor: '#fcdfe6',
	},
	{
		id: 8,
		imgSrc: Alison,
		jobTitle: '久坐美少女',
		name: 'Alison',
		content: '很怕熱的我，試過愛康之後立馬愛上！剛剛好的涼爽感真的舒服多了~',
		backgroundColor: '#e6e3f1',
	},
	{
		id: 9,
		imgSrc: Mika,
		jobTitle: '玩婚主持人',
		name: 'Mika',
		content: '工作較難暫離崗位，好險愛康吸收力蠻強！就算量大也不焦慮有外漏問題。',
		backgroundColor: '#e3f7fd',
	},
	{
		id: 10,
		imgSrc: ChiYun,
		jobTitle: '知知',
		name: 'ChiYun',
		content: '喜歡愛康衛生棉的香氣，又有很多選擇，包裝也很可愛，每次囤貨都好療癒',
		backgroundColor: '#d7edd0',
	},
	{
		id: 11,
		imgSrc: Liu,
		jobTitle: '小美',
		name: 'Liu',
		content: '跟朋友借衛生棉後就入坑，想不到竟然可以那麼乾爽！用過愛康就無法變心了！',
		backgroundColor: '#fcdfe6',
	},
];

const officalLineLink = 'https://page.line.me/ahp2024b?openQrModal=true';
const officalFbLink = 'https://www.facebook.com/icon99';
const officalIGLink = 'https://www.instagram.com/icon99tw/';
const officalRecruitmentLink = 'https://www.104.com.tw/company/1a2x6bjsb6';
const telPhone = '0800889933';
const fillFormLink = 'https://www.surveycake.com/s/ll4Ax';

export {
	MockBannerData,
	MockProductListData,
	CommentsData,
	officalLineLink,
	officalFbLink,
	officalIGLink,
	officalRecruitmentLink,
	telPhone,
	fillFormLink,
};
