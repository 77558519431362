/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action, createAction, handleActions } from 'redux-actions';
import dayjs from 'dayjs';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { CouponEventResource, UserCouponResource } from 'util/api/swaggerApi/data-contracts';
import { getImageFromStorage } from 'util/hook/getImageFromStorage';

import { VisibilityRuleType } from 'enums/visibilityRuleType';
import { ApiError } from 'types/apiError';
import { nanoid } from 'nanoid';
import { Dispatch } from 'redux';
import { State as GlobalState } from './reducers';
import { checkErrorStatus } from './signin';
import { toast } from './toast';
import { ModalTypes, openModal } from './modal';

export const MockCouponData: UserCouponResource[] = [
	{
		id: 1,
		status: '',
		note: '',
		archivedAt: '2023-01-01 00:00:00',
		receivedAt: '2023-01-01 00:00:00',
		usedAt: '2023-01-01 00:00:00',
		couponEvent: {
			id: 1,
			name: '優惠券名稱',
			title: '優惠券標題',
			subTitle: '優惠券副標題',
			code: 'CODE123',
			briefDescription: '優惠券簡述',
			hashtag: '優惠券標籤',
			rule: '優惠券使用規則',
			notice: '優惠券使用注意事項',
			typeName: 'TYPE_SHIPPING_FREE',
			discountPrice: 100,
			discountPercent: 80,
			discountPercentLimitPrice: 100,
			minimunPrice: 100,
			count: 1000,
			limitCount: 1,
			receivedCount: 1,
			usedCount: 1,
			currentVisibilityRule: 'ALWAYS_ONLINE',
			eventBeginAt: '2023-01-01',
			eventEndAt: '2023-01-01',
			gifts: [
				{
					id: 1,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
				{
					id: 2,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
			],
		},
	},
	{
		id: 2,
		status: '',
		note: '',
		archivedAt: '2023-01-01 00:00:00',
		receivedAt: '2023-01-01 00:00:00',
		usedAt: '2023-01-01 00:00:00',
		couponEvent: {
			id: 1,
			name: '優惠券名稱2',
			title: '優惠券標題2',
			subTitle: '優惠券副標題',
			code: 'CODE123',
			briefDescription: '優惠券簡述',
			hashtag: '優惠券標籤',
			rule: '優惠券使用規則',
			notice: '優惠券使用注意事項',
			typeName: 'TYPE_SHIPPING_FREE',
			discountPrice: 100,
			discountPercent: 80,
			discountPercentLimitPrice: 100,
			minimunPrice: 100,
			count: 1000,
			limitCount: 1,
			receivedCount: 1,
			usedCount: 1,
			currentVisibilityRule: 'ALWAYS_ONLINE',
			eventBeginAt: '2023-01-01',
			eventEndAt: '2023-01-01',
			gifts: [
				{
					id: 1,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
				{
					id: 2,
					name: '贈品名稱',
					briefDescription: '贈品簡述',
					type: '贈品類型',
					materialNo: '自建料號(Mapping ERP)',
					volume: 1.1,
					price: 100,
					image: 'gifts/xxx.png',
					visibilityRule: 'ALWAYS_ONLINE',
					currentVisibilityRule: 'ONLINE',
				},
			],
		},
	},
];

export const getCouponsList = createAction<Promise<{ couponsList: CouponEventResource[] }>>(
	'GET_COUPONS_LIST',
	async () => {
		try {
			const { v1CouponEventsList } = api;
			const { data } = await v1CouponEventsList();
			let list = data?.data || [];
			list = list.filter(coupon => coupon.currentVisibilityRule === VisibilityRuleType.ONLINE);
			return { couponsList: list as CouponEventResource[] };
		} catch (e) {
			return { couponsList: [] };
		}
	},
);

interface UserConponListPayload {
	userCouponList: UserCouponResource[];
}

// 領取優惠券
export const getCouponByCode = createAction(
	'GET_COUPON_BY_CODE',
	(code: string) => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsReceiveCreate } = api;
			const { status, message } = await v1UserCouponsReceiveCreate({ code });
			if (status === 200) {
				dispatch(getUserCouponList());
				dispatch(setErrMessageEmpty());
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '優惠券領取成功',
						type: 'success',
						color: 'green',
					}),
				);

				return { errMessage: '' };
			}
			dispatch(openModal(ModalTypes.ErrorModal));
			return { errMessage: message };
		} catch (e) {
			dispatch(openModal(ModalTypes.ErrorModal));
			return { errMessage: (e as ApiError).error.message };
		}
	},
);

// 領取並使用優惠券
export const toUseCouponByCode = createAction(
	'TO_USE_COUPON_BY_CODE',
	(code: string) => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsReceiveCreate } = api;
			const { data, status, message } = await v1UserCouponsReceiveCreate({ code });
			if (status === 200) {
				dispatch(getUserCouponList());
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已使用折扣碼',
						type: 'success',
						color: 'green',
					}),
				);
				dispatch(setErrMessageEmpty());

				const resultSelectCoupon = data?.data ? data?.data[0] : {};

				if (resultSelectCoupon.couponEvent) {
					resultSelectCoupon.couponEvent.gifts = resultSelectCoupon.couponEvent.gifts?.map(
						gift => ({
							...gift,
							image: getImageFromStorage(gift.image || ''),
						}),
					);
				}

				return { selectCoupon: resultSelectCoupon, errMessage: '' };
			}
			return { selectCoupon: {}, errMessage: message };
		} catch (e) {
			switch ((e as ApiError).error.errorCode) {
				case 'COUPON_IS_EXPIRED':
				case 'CURRENT_EVENT_COUPONS_ARE_ALL_NOT_USEALBED':
				case 'COUPON_RECEIVE_FALIED':
					return { selectCoupon: {}, errMessage: '折扣碼已失效' };
				case 'COUPON_IS_INVALID':
				case 'CODE_IS_INVALID':
				case 'COUPON_VISIBILITY_RULE_IS_OFFLINE':
					return { selectCoupon: {}, errMessage: '折扣碼輸入錯誤' };
				case 'COUPON_IS_RECEIVED':
					return { selectCoupon: {}, errMessage: '折扣碼已加入，請於下方我的優惠券選擇' };
				case 'COUPON_IS_ARCHIVED':
					return { selectCoupon: {}, errMessage: '折扣碼已失效' };
				case 'COUPON_RECEIVE_COUNT_EXCEEDED_LIMIT':
				case 'GIFT_IS_OUT_OF_STOCK':
					return { selectCoupon: {}, errMessage: '折扣碼已達使用上限' };
				case 'SYSTEM_WAITING':
					return { selectCoupon: {}, errMessage: '系統忙碌中，請稍候再試' };
				case 'COUPON_NOT_START':
					return { selectCoupon: {}, errMessage: '活動即將開始' };
				default:
					return { selectCoupon: {}, errMessage: '折扣碼輸入錯誤' };
			}
		}
	},
);

export const setSelectCoupon = createAction<CouponEventResource, CouponEventResource>(
	'SET_SELECT_COUPON',
	coupon => coupon,
);

/**
 * 取得會員優惠券列表
 */
export const getUserCouponList = createAction(
	'GET_USER_COUPON_LIST',
	() => async (dispatch: Dispatch) => {
		try {
			const { v1UserCouponsList } = api;
			const { data } = await v1UserCouponsList();
			const list = data?.data || [];

			const userCouponList = list.map(coupon => {
				const couponEvent = coupon.couponEvent || {};
				const couponGifts = coupon.couponEvent?.gifts || [];

				const result: UserCouponResource = {
					...coupon,
					couponEvent: {
						...couponEvent,
						eventBeginAt: dayjs(couponEvent.eventBeginAt).isValid()
							? dayjs(couponEvent.eventBeginAt).format('YYYY.MM.DD HH:mm')
							: '',
						eventEndAt: dayjs(couponEvent.eventEndAt).isValid()
							? dayjs(couponEvent.eventEndAt).format('YYYY.MM.DD HH:mm')
							: '',
						gifts: couponGifts
							.filter(gift => gift.currentVisibilityRule === VisibilityRuleType.ONLINE)
							.map(gift => ({
								...gift,
								image: getImageFromStorage(gift.image || ''),
							})),
					},
				};

				return result;
			});

			return { userCouponList };
		} catch (e) {
			dispatch(checkErrorStatus(e as ApiError));
			return { userCouponList: [] };
		}
	},
);
export const setSelectCouponRule = createAction<UserCouponResource, UserCouponResource>(
	'SET_SELECT_COUPON_RULE',
	coupon => coupon,
);

export const setErrMessageEmpty = createAction('SET_ERR_MESSAGE_EMPTY', () => '');

export interface State {
	loading: boolean;
	couponsList: CouponEventResource[];
	selectCoupon: UserCouponResource;
	selectedCouponRule: CouponEventResource;
	errMessage: string;
	userCouponList: UserCouponResource[];
	gettingErrMessage: string;
}

export const defaultState: State = {
	loading: false,
	couponsList: [],
	selectCoupon: {},
	selectedCouponRule: {},
	errMessage: '',
	userCouponList: [],
	gettingErrMessage: '',
};

export const reducer = {
	coupon: handleActions<State, any>(
		{
			GET_COUPONS_LIST_FULFILLED: (state, action) => ({
				...state,
				couponsList: action.payload.couponsList,
				loading: false,
			}),
			TO_USE_COUPON_BY_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			TO_USE_COUPON_BY_CODE_FULFILLED: (state, action) => ({
				...state,
				selectCoupon: action.payload.selectCoupon,
				loading: false,
				errMessage: action.payload.errMessage,
			}),
			GET_COUPON_BY_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_COUPON_BY_CODE_FULFILLED: (state, action) => ({
				...state,
				loading: false,
				gettingErrMessage: action.payload.errMessage,
			}),
			GET_USER_COUPON_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_USER_COUPON_LIST_FULFILLED: (state, action: Action<UserConponListPayload>) => ({
				...state,
				userCouponList: action.payload.userCouponList,
				loading: false,
			}),
			SET_SELECT_COUPON: (state, action) => ({
				...state,
				selectCoupon: action.payload,
			}),
			SET_SELECT_COUPON_RULE: (state, action) => ({
				...state,
				selectedCouponRule: action.payload,
			}),
			SET_ERR_MESSAGE_EMPTY: state => ({
				...state,
				errMessage: '',
			}),
		},
		defaultState,
	),
};

const couponActionsMap = {
	getCouponsList,
	getCouponByCode,
	toUseCouponByCode,
	getUserCouponList,
	setSelectCoupon,
	setSelectCouponRule,
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.coupon.loading,
	couponsList: state.coupon.couponsList,
	selectCoupon: state.coupon.selectCoupon,
	errMessage: state.coupon.errMessage,
	userCouponList: state.coupon.userCouponList,
	selectedCouponRule: state.coupon.selectedCouponRule,
	gettingErrMessage: state.coupon.gettingErrMessage,
});

type couponSelector = ReturnType<typeof mapHooksToState>;
type couponActionsMap = typeof couponActionsMap;

export const useCoupon = () =>
	useRedux<couponSelector, couponActionsMap>(mapHooksToState, couponActionsMap);
