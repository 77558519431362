/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';

import { ToastInfo, useToast } from 'models/toast';
import useToastTop from 'util/hook/useToastTop';

import SuccessIcon from 'images/icon/success-mark.inline.svg';
import WarningIcon from 'images/icon/warning-mark.inline.svg';

import styles from './index.module.css';

interface ToastProperty {
	className?: string;
	top?: number;
}

const ToastItem: React.FC<ToastInfo> = ({ type, color, message }) => {
	const Icon = useMemo(() => {
		switch (type) {
			case 'success':
				return SuccessIcon;
			case 'warn':
				return WarningIcon;
			default:
				return SuccessIcon;
		}
	}, [type]);

	return (
		<div className={classnames(styles.toastItem, color && styles[color])}>
			<Icon />
			<span>{message}</span>
		</div>
	);
};

const Toast: React.FC<ToastProperty> = ({ className }) => {
	const [{ toastList }, { popToast }] = useToast();
	const [{ toastTop }] = useToastTop();

	const refDiv = useRef(document.createElement('div'));

	useLayoutEffect(() => {
		let modalRoot = document.getElementById('modal-root');

		if (modalRoot === null) {
			modalRoot = document.createElement('div');
			modalRoot.setAttribute('id', 'modal-root');
			document.body.appendChild(modalRoot);
		}

		modalRoot.appendChild(refDiv.current);

		return () => {
			if (modalRoot) {
				modalRoot.removeChild(refDiv.current);
			}
		};
	}, []);

	useEffect(() => {
		let timer: NodeJS.Timeout | null = null;

		if (toastList.length) {
			timer = setTimeout(() => {
				popToast();
			}, 3000);
		}

		return () => {
			if (timer) {
				clearTimeout(timer);
			}
		};
	}, [toastList]);

	if (toastList.length <= 0) {
		return null;
	}

	return createPortal(
		<div className={classnames(styles.toast, className)} style={{ top: toastTop }}>
			{toastList.map(toast => (
				<ToastItem key={toast.id} {...toast} />
			))}
		</div>,
		refDiv.current,
	);
};

export default Toast;
