/* eslint-disable @typescript-eslint/no-explicit-any */
declare interface Window {
	dataLayer: { i13n: any }[];
}

export const omniEvent = <T>(obj: T) => {
	const { dataLayer } = window as unknown as Window;
	dataLayer.push({
		i13n: obj,
	});
};

// Omni Add / Remove from Cart
export const omniAddToOrRemovefromCart = (
	number: number,
	name?: string,
	materialNo?: number | string,
	eventPrice?: number,
	memberPrice?: number,
	briefDescription?: string,
	uid?: number,
) => {
	if (!number) return;
	const action = number > 0 ? 'AddToCart' : 'RemoveFromCart';
	omniEvent(null);
	omniEvent({
		action,
		uid, //  User ID is required if user has login
		products: [
			{
				name, // Name is required.
				id: materialNo, // ID is required.
				price: eventPrice || memberPrice,
				brand: '愛康生技',
				variant: briefDescription,
				quantity: Math.abs(number),
			},
		],
	});
};

export const omniNewRegistration = (uid: string, email: string, lineId?: string) => {
	omniEvent(null);
	omniEvent({
		line_id: lineId,
		uid,
		action: 'CompleteRegistration',
		label: {
			email,
		},
	});
};
