import { ProductData } from 'components/molecules/CartTable';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare interface Window {
	dataLayer: any[];
}

export const gtmEvent = <T>(obj: T) => {
	const { dataLayer } = window as unknown as Window;
	if (!dataLayer) return;
	dataLayer.push(obj);
};

export const gtmEcommerceEvent = (
	event: string,
	value: number,
	items: ProductData[],
	otherOptions?: Record<string, string | number>,
) => {
	gtmEvent({ ecommerce: null });
	gtmEvent({
		event,
		ecommerce: {
			currency: 'TWD',
			value,
			items: items.map(p => ({
				item_name: p.productName,
				item_id: p.materialNo,
				price: p.price || p.unitPrice,
				item_brand: '愛康生技',
				item_variant: p.briefDescription,
				quantity: p.count,
				affiliation: '愛康官網',
			})),
			...otherOptions,
		},
	});
};

export const gtmAddToOrRemovefromCart = (
	number: number,
	name?: string,
	materialNo?: number | string,
	eventPrice?: number,
	memberPrice?: number,
	briefDescription?: string,
) => {
	if (!number) return;

	gtmEvent({ ecommerce: null });
	gtmEvent({
		event: number > 0 ? 'add_to_cart' : 'remove_from_cart',
		ecommerce: {
			currency: 'TWD',
			value: (eventPrice || memberPrice || 0) * Math.abs(number),
			items: [
				{
					item_name: name, // Name is required.
					item_id: materialNo, // ID is required.
					price: eventPrice || memberPrice,
					item_brand: '愛康生技',
					item_variant: briefDescription,
					quantity: Math.abs(number),
					affiliation: '愛康官網',
				},
			],
		},
	});
};

export const gtmViewItemList = (items: ProductData[], itemListId: string, itemListName: string) => {
	gtmEvent({ ecommerce: null });
	gtmEvent({
		event: 'view_item_list',
		ecommerce: {
			item_list_id: itemListId,
			item_list_name: itemListName,
			items: items.map(p => ({
				item_name: p.productName,
				item_id: p.materialNo,
				price: p.price || p.unitPrice,
				item_brand: '愛康生技',
				item_variant: p.briefDescription,
				quantity: p.count,
				affiliation: '愛康官網',
			})),
		},
	});
};

export const gtmCancelOrder = (orderId: number, totalPrice: number, items: ProductData[]) => {
	gtmEvent({
		event: 'cancel_order',
		value: {
			total_price: totalPrice,
			order_id: orderId,
			items: items.map(p => ({
				item_name: p.productName,
				item_id: p.materialNo,
				price: p.price,
				item_brand: '愛康生技',
				item_variant: p.briefDescription,
				quantity: p.count,
				affiliation: '愛康官網',
			})),
		},
	});
};

export const gtmClickSeries = (seriesName: string) => {
	gtmEvent({
		event: 'click_series',
		series_name: seriesName,
	});
};

export const gtmCustomClick = (link: string, action: string) => {
	gtmEvent({
		event: 'custom_click',
		custom_click_link: link,
		custom_click_action: action,
	});
};
