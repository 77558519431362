import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';
import Link from '../Link';

interface MessageBarProperty {
	content?: string;
	backgroundColor?: string;
	className?: string;
	link?: string;
}

const MessageBar: React.FC<MessageBarProperty> = ({
	content,
	backgroundColor,
	className,
	link = '',
}) => (
	<Link to={link} type="external" showPointer={!!link}>
		<div className={classnames(styles.messageBar, className)} style={{ backgroundColor }}>
			{content}
		</div>
	</Link>
);

export default MessageBar;
