export const insertScriptToHead = (
	id: string,
	src: string,
	options = { async: true, defer: true },
) => {
	if (document.getElementById(id)) {
		return 1;
	}

	const head = document.getElementsByTagName('head')[0];
	const scriptList = head.getElementsByTagName('script');
	const lastScript = scriptList[scriptList.length - 1];
	const js = document.createElement('script');

	js.id = id;
	js.src = src;

	if (options.async) {
		js.async = true;
	}

	if (options.defer) {
		js.defer = true;
	}

	lastScript.parentNode?.insertBefore(js, lastScript);

	return 0;
};

export const deleteScriptFromHead = (id: string) => {
	const script = document.getElementById(id);
	if (script) {
		script.remove();
	}
};

