/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { ROUTE_PATHS } from 'routes';

import Cart from 'components/atoms/Cart';
import Link from 'components/atoms/Link';
import MessageBar from 'components/atoms/MessageBar';
import HeaderDropdown, { HeaderDropdownData } from 'components/organisms/HeaderDropdown';
import DropdownItem, { DropdownItemData } from 'components/atoms/DropdownItem';
import SideCartList from 'components/molecules/SideCartList';
import DecoratedLink from 'components/molecules/DecoratedLink';
import SideMemberFeatures from 'components/molecules/SideMemberFeatures';

import useLocation from 'util/hook/useLocation';
import useMedia from 'util/hook/useMedia';
import { useProductsForCart } from 'util/hook/useProducts';
import useToastTop from 'util/hook/useToastTop';
import { gtmCustomClick } from 'util/gtmEvent';

import { useHistory } from 'models/routing';
import { useMessageBar } from 'models/messageBar';
import { useProducts } from 'models/products';
import { useCombinations } from 'models/combination';
import { useNewRelease } from 'models/newRelease';
import { useDiscountEvent } from 'models/discountEvent';
import { useMember } from 'models/member';

import Logo from 'images/ci/logo-black.png';
import User from 'images/icon/user.inline.svg';
import Burger from 'images/icon/burger-menu.inline.svg';
import Cross from 'images/icon/cross.inline.svg';
import Arrow from 'images/icon/dropdown.inline.svg';

import pushHistory from 'util/pushHistory';
import { HeaderLinks } from './config';

import styles from './index.module.css';

interface HeaderProperty {
	// eslint-disable-next-line react/no-unused-prop-types
	closeHeader?: () => void;
	className?: string;
	allExpandData: HeaderDropdownData[];
}

const MobileHeaderPanel: React.FC<HeaderProperty> = ({ closeHeader, className, allExpandData }) => {
	const [activeLinkIndex, setActiveLinkIndex] = useState(-1);
	const [openList, setOpenList] = useState<boolean[]>([]);
	const handleClickMobileLink = (expandData: HeaderDropdownData[], index: number) => {
		if (expandData.length) {
			const target = HeaderLinks(allExpandData)[index];
			setOpenList(target.expandData.map(() => false));
			setActiveLinkIndex(index);
		}
	};
	const clickDecoratedLink = (linkName: string, linkTo: string) => {
		if (closeHeader) {
			closeHeader();
		}
		if (linkName === '海外購物') {
			gtmCustomClick(linkTo, '海外購物');
		}
	};

	return (
		<div className={classnames(styles.mobilePanel, className)}>
			{activeLinkIndex < 0 &&
				HeaderLinks(allExpandData).map((link, index) =>
					link.expandData.length ? (
						<button
							className={styles.itemContainer}
							type="button"
							onClick={() => handleClickMobileLink(link.expandData as HeaderDropdownData[], index)}
							key={link.name}
						>
							{link.name}
							<Arrow />
						</button>
					) : (
						<DecoratedLink
							key={link.name}
							to={link.externalLink || link.to}
							name={link.name}
							type={link.externalLink ? 'external' : 'internal'}
							onClick={() => clickDecoratedLink(link.name, link.externalLink)}
							className={styles.itemContainer}
						/>
					),
				)}
			{activeLinkIndex >= 0 && (
				<>
					<button
						className={styles.backToMenu}
						type="button"
						onClick={() => setActiveLinkIndex(-1)}
					>
						<Arrow />
						回主選單
					</button>
					{HeaderLinks(allExpandData)[activeLinkIndex].expandData.map((link, index) => (
						<DropdownItem
							data={link.expandData as DropdownItemData}
							name={link.name}
							handlePanelClick={closeHeader}
							handleMainClick={() => {
								const newList = openList.map(() => false);
								newList[index] = true;
								setOpenList(newList);
							}}
							key={link.name}
							autoClose={false}
							open={openList[index]}
						/>
					))}
				</>
			)}
		</div>
	);
};

const Header: React.FC<HeaderProperty> = ({ className, allExpandData }) => {
	const history = useHistory();
	const location = useLocation(history);
	const media = useMedia();
	const [isMessageBarShow, setIsMessageBarShow] = useState(true);
	const [{ isPanelOpen, isCartOpen, isMemberOpen }, setFeatureMap] = useState({
		isPanelOpen: false,
		isCartOpen: false,
		isMemberOpen: false,
	});
	const [{ messageBars }] = useMessageBar();
	const productsData = useProductsForCart();
	const [{ toastTopWithoutMargin }] = useToastTop();

	const cartProductNum = useMemo(
		() => productsData.reduce((acc, cur) => acc + cur.count, 0),
		[productsData],
	);

	const [{ ecUserToken, showIncompleteHint }] = useMember();

	const closeAllHeader = () => {
		setFeatureMap({
			isPanelOpen: false,
			isCartOpen: false,
			isMemberOpen: false,
		});
		console.log(456);
	};

	useEffect(() => {
		const scrollEvent = () => {
			setTimeout(() => {
				setIsMessageBarShow(media === 'desktop' || window.scrollY === 0);
			}, 100);
		};
		window.addEventListener('scroll', scrollEvent);
		return () => {
			window.removeEventListener('scroll', scrollEvent);
		};
	}, [media]);

	const gtmCustomClickEvent = (linkName: string, linkTo: string) => {
		if (linkName === '海外購物') {
			gtmCustomClick(linkTo, '海外購物');
		}
	};

	return (
		<>
			{isMessageBarShow && (
				<>
					{messageBars.map(messageBar => (
						<MessageBar
							key={messageBar.id}
							content={messageBar.name}
							backgroundColor={messageBar.color}
							link={messageBar.link}
						/>
					))}
				</>
			)}

			{media === 'mobile' && (isPanelOpen || isMemberOpen || isCartOpen) && (
				<div
					className={styles.backdrop}
					style={{
						height: `calc(100vh - ${toastTopWithoutMargin + 10}px)`,
						top: `${toastTopWithoutMargin + 10}px`,
					}}
				/>
			)}
			<div className={classnames(styles.header, className)}>
				<div className={styles.headerInner}>
					<div className={styles.logoContainer}>
						{!isCartOpen && !isMemberOpen ? (
							<button
								className={styles.switch}
								type="button"
								onClick={() => setFeatureMap(pre => ({ ...pre, isPanelOpen: !pre.isPanelOpen }))}
							>
								{isPanelOpen ? <Cross /> : <Burger />}
							</button>
						) : (
							<button className={styles.switch} type="button" onClick={() => closeAllHeader()}>
								<Cross />
							</button>
						)}
						<Link to="/" onClick={() => closeAllHeader()}>
							<img src={Logo} alt="logo" />
						</Link>
					</div>
					{media === 'mobile' && isPanelOpen && (
						<MobileHeaderPanel closeHeader={closeAllHeader} allExpandData={allExpandData} />
					)}
					{media === 'desktop' && (
						<div className={styles.links}>
							{HeaderLinks(allExpandData).map(link =>
								link.expandData.length ? (
									<HeaderDropdown
										key={link.name}
										name={link.name}
										data={link.expandData}
										className={styles.headerDropdown}
									/>
								) : (
									<DecoratedLink
										key={link.name}
										to={link.externalLink || link.to}
										name={link.name}
										type={link.externalLink ? 'external' : 'internal'}
										isActive={location.pathname === link.name}
										className={styles.link}
										onClick={() => gtmCustomClickEvent(link.name, link.externalLink)}
									/>
								),
							)}
						</div>
					)}
					<div className={styles.tools}>
						<button
							type="button"
							className={styles.tool}
							onClick={() => {
								if (ecUserToken) {
									setFeatureMap(pre => ({
										isPanelOpen: false,
										isCartOpen: false,
										isMemberOpen: !pre.isMemberOpen,
									}));
								} else {
									pushHistory(history, `/${ROUTE_PATHS.signin}`);
									window.scrollTo(0, 0);
								}
							}}
						>
							<User />
							{showIncompleteHint && <span className={styles.redDot} />}
						</button>
						<button
							type="button"
							className={styles.tool}
							onClick={() => {
								setFeatureMap(pre => ({
									isPanelOpen: false,
									isCartOpen: !pre.isCartOpen,
									isMemberOpen: false,
								}));
							}}
						>
							<Cart quantity={cartProductNum} />
						</button>
						{isCartOpen && (
							<SideCartList handleList={e => setFeatureMap(pre => ({ ...pre, isCartOpen: e }))} />
						)}
						{isMemberOpen && <SideMemberFeatures closeHeader={closeAllHeader} />}
					</div>
				</div>
			</div>
		</>
	);
};

const HeaderContainer: React.FC = () => {
	const [{ combinationDiscount }] = useCombinations();
	const [{ series }] = useProducts();
	const [{ newReleasesList }] = useNewRelease();
	const [{ discountEventList }] = useDiscountEvent();

	const AllExpandData: HeaderDropdownData[] = [];

	if (series.length) {
		AllExpandData.push({
			id: 1,
			name: '商品類別',
			to: '',
			externalLink: '',
			expandData: [
				{
					id: 1,
					name: '全部商品',
					to: '/products?all=1',
					externalLink: '',
					expandData: [],
				},
				...series
					.map((serie, idx) => ({
						id: idx + 1,
						name: serie.name!,
						to: `/products?series=${serie.id}`,
						externalLink: '',
						expandData: [],
					}))
					.slice(0, 4),
			],
		});
	}

	if (newReleasesList.length) {
		AllExpandData.push({
			id: 2,
			name: '新品上市',
			to: '',
			externalLink: '',
			expandData: newReleasesList
				.map((data, idx) => ({
					id: idx + 1,
					name: data.title!,
					to: `/products?newRelease=${data.id}`,
					externalLink: '',
					expandData: [],
				}))
				.slice(0, 5),
		});
	}

	if (combinationDiscount.length) {
		AllExpandData.push({
			id: 3,
			name: '組合優惠',
			to: '',
			externalLink: '',
			expandData: combinationDiscount
				.map(data => ({
					id: data.id!,
					name: data.title!,
					to: `/products?combination=${data.id}`,
					externalLink: '',
					expandData: [],
				}))
				.slice(0, 5),
		});
	}

	const NeedDisplayNavigationDiscountEvent = discountEventList.filter(
		data => data.isDisplayNavigation,
	);

	if (NeedDisplayNavigationDiscountEvent.length) {
		AllExpandData.unshift({
			id: 4,
			name: '限時搶購',
			to: '',
			externalLink: '',
			expandData: NeedDisplayNavigationDiscountEvent.map(data => ({
				id: data.id!,
				name: data.title!,
				to: `/products?discountEvent=${data.id}`,
				externalLink: '',
				expandData: [],
			})).slice(0, 5),
		});
	}

	return (
		<div className={styles.headerContainer}>
			<Header allExpandData={AllExpandData} />
		</div>
	);
};

export default HeaderContainer;
