export enum VALIDATION_RULES {
	REQUIRED = 'required',
	EMAIL = 'email',
	ACCOUNT = 'account',
	EMAIL_VERIFY = 'emailVerify',
	PASSWORD = 'password',
	LOGIN_PASSWORD = 'loginPassword',
	COMFIRM_PASSWORD = 'comfirmPassword',
	MOBILE = 'mobile',
	TELEPHONE = 'telephoneNumber',
	MOBILEBARCODE = 'mobileBarcode',
	CERTIFICATE = 'certificate',
	TAXID = 'taxId',
	PATTERN = 'pattern',
}

export enum ERROR_MESSAGES {
	REQUIRED = '此欄位必填！',
	EMAIL = '電子信箱格式錯誤',
	ACCOUNT = '帳號格式錯誤',
	EMAIL_VERIFY = '驗證碼錯誤，請重新輸入',
	PASSWORD = '密碼格式錯誤',
	COMFIRM_PASSWORD = '密碼輸入錯誤',
	MOBILE = '手機號碼格式錯誤',
	TELEPHONE = '市話號碼格式錯誤',
	MOBILEBARCODE = '條碼格式錯誤',
	CERTIFICATE = '條碼格式錯誤',
	TAXID = '統編格式錯誤',
	EMAIL_EXISTED = '此電子信箱已存在',
	VERIFY_FAIL = '驗證碼錯誤，請重新輸入',
	VERIFY_ERROR_LIMIT_REACHED = '驗證碼輸入錯誤達 5 次，請重新取得驗證碼',
	USER_NOT_FOUND = '此電子信箱或帳號尚未註冊',
	USER_EMAIL_NOT_FOUND = '此電子信箱尚未註冊',
	CODE_NOT_FOUND = '驗證碼輸入錯誤達 5 次，請重新取得驗證碼',
	PASSWORD_ERROR = '密碼錯誤',
	PATTERN = '格式錯誤',
	LOGIN_FAIL = '帳號或密碼錯誤',
}
