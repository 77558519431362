/**
 * PAYMENT_SUCCESSFUL: 付款成功
 *
 * ORDER_ESTABLISHED: 訂單成立、貨到付款
 *
 * WAITING_FOR_TRANSFER: 等待匯款
 *
 * PAYMENT_FAIL: 付款失敗
 *
 * ORDER_FAIL: 訂單提交失敗
 *
 * @export
 * @enum {number}
 */
export enum OrderConfirmationType {
	PAYMENT_SUCCESSFUL = 'Payment Successful',
	ORDER_ESTABLISHED = 'Order Established',
	WAITING_FOR_TRANSFER = 'Waiting For Transfer',
	PAYMENT_FAIL = 'Payment Fail',
	ORDER_FAIL = 'Order Fail',
}
