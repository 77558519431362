import { BrowserHistory, History } from 'history';

interface QueryParamsBlackListModifier {
	modify(queryParams: URLSearchParams): void;
}

class QueryParamsBlackListProvider implements QueryParamsBlackListModifier {
	private params: string[];

	constructor(params: string[] = []) {
		this.params = params;
	}

	modify(queryParams: URLSearchParams): void {
		this.params.forEach(key => {
			queryParams.delete(key);
		});
	}
}

const defaultQueryParams = new QueryParamsBlackListProvider([
	'all',
	'series',
	'newRelease',
	'combination',
	'discountEvent',
	'tab',
	'result',
	'redirect',
	'no',
	'type',
	'code',
	'store',
	'location',
	'CVSStoreID',
	'CVSStoreName',
	'CVSAddress',
	'email',
	'categoryId',
	'state',
	'ecid',
]);

export const modifiedCurrentUrl = (modifier: QueryParamsBlackListModifier = defaultQueryParams) => {
	const currentUrl = new URL(window.location.href);
	modifier.modify(currentUrl.searchParams);
	return currentUrl;
};

const pushHistory = (
	history: History | BrowserHistory,
	to: string,
	otherParams = '',
	modifier: QueryParamsBlackListModifier = defaultQueryParams,
): void => {
	const currentUrl = modifiedCurrentUrl(modifier);
	const nextUrl = new URL(to, window.location.origin);

	nextUrl.searchParams.forEach((value, key) => {
		currentUrl.searchParams.set(key, value);
	});
	currentUrl.pathname = nextUrl.pathname;
	const { search } = currentUrl;

	if (otherParams) {
		if (search) {
			currentUrl.search = `${search}&${otherParams}`;
		} else {
			currentUrl.search = `?${otherParams}`;
		}
	}

	history.push({ pathname: currentUrl.pathname, search: currentUrl.search });
};

export default pushHistory;
