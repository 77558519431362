/* eslint-disable no-restricted-syntax */
import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { parseQueryString } from 'util/parseQueryString';

import { HeaderDropdownData } from 'components/organisms/HeaderDropdown';

import { memberFeatureList } from 'models/member';

import { MemberFeatureType } from 'enums/memberFeatureType';
import pushHistory from 'util/pushHistory';

export const checkPageExist = (
	type: MemberFeatureType,
	list: HeaderDropdownData[],
): {
	exist: boolean;
	target: HeaderDropdownData | null;
} => {
	let result: {
		exist: boolean;
		target: HeaderDropdownData | null;
	} = {
		exist: false,
		target: null,
	};

	for (const feature of list) {
		if (feature.expandData.length) {
			result = checkPageExist(type, feature.expandData);
		}
		if (feature.id === type) {
			result = {
				exist: true,
				target: feature,
			};
			break;
		}
	}

	return result;
};

const routes: CustomRoute = {
	path: '/member-centre',
	components: () => [import(/* webpackChunkName: 'member-centre' */ './component')],
	render: ([MemberCentre]) => <MemberCentre />,
	onEnter: async ({ next, history }) => {
		const { type } = parseQueryString();
		const { exist } = checkPageExist(parseInt(type, 10), memberFeatureList);
		if (exist) {
			const children = await next();
			return children;
		}

		pushHistory(history, '/member-centre', `type=${MemberFeatureType.INFO}`);

		return null;
	},
};
export default routes;
