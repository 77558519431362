/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';

import { getImageFromStorage } from 'util/hook/getImageFromStorage';

import {
	ProductNewReleaseResource,
	ProductNewReleaseShowResource,
	ProductResource,
} from 'util/api/swaggerApi/data-contracts';
import { VisibilityRuleType } from 'enums/visibilityRuleType';
import { State as GlobalState } from './reducers';

interface NewReleasesPayload {
	newReleasesList: ProductNewReleaseResource[];
}

export const getNewReleases = createAction<Promise<NewReleasesPayload>>(
	'GET_NEW_RELEASES',
	async () => {
		try {
			const { v1ProductNewReleasesList } = api;
			const { data } = await v1ProductNewReleasesList();
			return {
				newReleasesList:
					data?.data?.filter(item => item.currentVisibilityRule === VisibilityRuleType.ONLINE) ||
					[],
			};
		} catch (e) {
			return { newReleasesList: [] };
		}
	},
);

interface SelectReleasePayload {
	selectRelease: ProductNewReleaseShowResource;
}

export const getNewReleaseById = createAction<Promise<SelectReleasePayload>, number>(
	'GET_NEW_RELEASE_BY_ID',
	async id => {
		try {
			const { v1ProductNewReleasesDetail } = api;
			const { data } = await v1ProductNewReleasesDetail(id);

			const selectRelease = {
				...data?.data,
				webImage: data?.data?.webImage && getImageFromStorage(data?.data?.webImage),
				mobileImage: data?.data?.mobileImage && getImageFromStorage(data.data.mobileImage),
				products: data?.data?.products?.map((p: ProductResource) => ({
					...p,
					images: p.images!.map(img => getImageFromStorage(img)),
				})),
			};
			return { selectRelease };
		} catch (e) {
			return { selectRelease: {} };
		}
	},
);

// For Global State usage
export interface State {
	loading: boolean;
	newReleasesList: ProductNewReleaseResource[];
	newReleases: ProductNewReleaseShowResource;
}

export const defaultState: State = {
	loading: false,
	newReleasesList: [],
	newReleases: {},
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	newReleases: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_NEW_RELEASES_FULFILLED: (state, action: Action<NewReleasesPayload>) => ({
				...state,
				newReleasesList: action.payload.newReleasesList,
				loading: false,
			}),
			GET_NEW_RELEASE_BY_ID_FULFILLED: (state, action: Action<SelectReleasePayload>) => ({
				...state,
				newReleases: action.payload.selectRelease,
				loading: false,
			}),
		},
		defaultState,
	),
};

const releaseActionsMap = {
	getNewReleases,
	getNewReleaseById,
};

const mapHooksToState = (state: GlobalState) => ({
	newReleasesList: state.newReleases.newReleasesList,
	newReleases: state.newReleases.newReleases,
});

type ReleaseSelector = ReturnType<typeof mapHooksToState>;
type ReleaseActionsMap = typeof releaseActionsMap;

export const useNewRelease = () =>
	useRedux<ReleaseSelector, ReleaseActionsMap>(mapHooksToState, releaseActionsMap);
