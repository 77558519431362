/**
 * 收件類別之列舉
 *
 * @export
 * @enum {number}
 */
export enum RecipientType {

	/**
	 * 宅配
	 */
	HOME = 'HOME',

	/**
	 * 超商取貨
	 */
	STORE = 'STORE',

}

export const getRecipientTypeText = (recipientType: RecipientType): string => {
	switch (recipientType) {
		case RecipientType.HOME:
			return '黑貓宅配';
		case RecipientType.STORE:
			return '超商取貨';
		default:
			return '';
	}
}
