import { combineReducers } from 'redux';

import * as routing from './routing';
import * as member from './member';
import * as blog from './blog';
import * as banners from './banner';
import * as modal from './modal';
import * as toast from './toast';
import * as messageBars from './messageBar';
import * as flashSales from './flashSales';
import * as videos from './vedio';
import * as products from './products';
import * as combination from './combination';
import * as newReleases from './newRelease';
import * as coupon from './coupon';
import * as signup from './signup';
import * as signin from './signin';
import * as discountEvents from './discountEvent';
import * as productPage from './productPage';
import * as stock from './stock';
import * as auth from './auth';
import * as accountEditForm from './accountEditForm';
import * as cart from './cart';
import * as redirect from './redirect';
import * as order from './order';
import * as system from './system';
import * as pageView from './pageView';
import * as lineEvent from './lineEvent';

// For Global State interface
export interface State {
	blog: blog.State;
	banners: banners.State;
	member: member.State;
	routing: routing.State;
	modal: modal.State;
	toast: toast.State;
	messageBars: messageBars.State;
	flashSales: flashSales.State;
	videos: videos.State;
	products: products.State;
	combination: combination.State;
	newReleases: newReleases.State;
	coupon: coupon.State;
	signup: signup.State;
	signin: signin.State;
	discountEvents: discountEvents.State;
	productPage: productPage.State;
	stock: stock.State;
	auth: auth.State;
	accountEditForm: accountEditForm.State;
	cart: cart.State;
	redirect: redirect.State;
	order: order.State;
	system: system.State;
	pageView: pageView.State;
	lineEvent: lineEvent.State;
}

export type GetState = () => State;

export const defaultState: State = {
	blog: blog.defaultState,
	banners: banners.defaultState,
	member: member.defaultState,
	routing: routing.defaultState,
	modal: modal.defaultState,
	toast: toast.defaultState,
	messageBars: messageBars.defaultState,
	flashSales: flashSales.defaultState,
	videos: videos.defaultState,
	products: products.defaultState,
	combination: combination.defaultState,
	newReleases: newReleases.defaultState,
	coupon: coupon.defaultState,
	signup: signup.defaultState,
	signin: signin.defaultState,
	discountEvents: discountEvents.defaultState,
	productPage: productPage.defaultState,
	stock: stock.defaultState,
	auth: auth.defaultState,
	accountEditForm: accountEditForm.defaultState,
	cart: cart.defaultState,
	redirect: redirect.defaultState,
	order: order.defaultState,
	system: system.defaultState,
	pageView: pageView.defaultState,
	lineEvent: lineEvent.defaultState,
};

const reducers = combineReducers<State>({
	...routing.reducer,
	...member.reducer,
	...blog.reducer,
	...banners.reducer,
	...modal.reducer,
	...toast.reducer,
	...messageBars.reducer,
	...flashSales.reducer,
	...videos.reducer,
	...products.reducer,
	...combination.reducer,
	...newReleases.reducer,
	...coupon.reducer,
	...signup.reducer,
	...signin.reducer,
	...discountEvents.reducer,
	...productPage.reducer,
	...stock.reducer,
	...auth.reducer,
	...accountEditForm.reducer,
	...cart.reducer,
	...redirect.reducer,
	...order.reducer,
	...system.reducer,
	...pageView.reducer,
	...lineEvent.reducer,
});

export default reducers;
