import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';
import { parseQueryString } from 'util/parseQueryString';
import { getLineIdToken } from 'models/auth';
import { StorageKey } from 'enums/storageKey';
import { setItem } from 'util/storage';
import { parseStateFromLineQuery } from 'util/line';

const routes: CustomRoute = {
	path: '/line-auth',
	components: () => [import(/* webpackChunkName: 'lineAuth' */ './component')],
	render: ([LineAuth]) => <LineAuth />,
	onEnter: async ({ next, store }) => {
		const { code } = parseQueryString();
		if (code) {
			const stateDataFromQuery = parseStateFromLineQuery();
			console.log('stateDataFromQuery-------', stateDataFromQuery);
			if (stateDataFromQuery.info) {
				setItem(StorageKey.SIGNIN_CALLBACK_INFO, JSON.stringify(stateDataFromQuery.info));
			}
			await store.dispatch(getLineIdToken(code));
		}
		const children = await next();
		return children;
	},
};
export default routes;
