/**
 * 會員中心功能之列舉
 *
 * @export
 * @enum {number}
 */
export enum MemberFeatureType {

	/**
	 * 我的帳戶
	 */
	ACCOUNT = 1,

	/**
	 * 我的訂單
	 */
	ORDERS,

	/**
	 * 我的優惠券
	 */
	COUPONS,

	/**
	 * 消費累金
	 */
	SPENDING_ACCUMULAYES,

	/**
	 * 會員資訊
	 */
	INFO,

	/**
	 * 常用收件人資料
	 */
	COMMON_RECIPIENT_INFORMATION,

	/**
	 * 常用發票資料
	 */
	COMMON_INVOICE_INFORMATION

}
