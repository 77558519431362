import React from 'react';
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';

import { ProfileEditedType } from 'components/molecules/ProfileEditedModal';

import { api } from 'util/api';
import { useRedux } from 'util/hook/redux';
import { Validators } from 'util/formSystem/validatorFn';
import { FormControl } from 'util/formSystem/formControl';
import {
	isCtrlValid,
	isFormValid,
	patchValue,
	setCtrlValidators,
	setCtrlValue,
	updateCtrlValidity,
	updateValidity,
} from 'util/formSystem/formOperators';
import { regexMap } from 'util/hook/useValidators';
import { UserInvoiceResource, UserRecipientResource } from 'util/api/swaggerApi/data-contracts';
import { ValidatorFnType } from 'util/formSystem/formOption';

import { YesOrNo } from 'enums/yesOrNo';
import { CarrierType } from 'enums/carrierType';
import { InvoiceType } from 'enums/invoiceType';
import { VALIDATION_RULES } from 'enums/validators';
import { RecipientType } from 'enums/recipientType';
import { DeliveryTimeType } from 'enums/deliveryTimeType';

import { GetState, State as GlobalState } from './reducers';
import { updateInvoiceInfo, updateRecipientInfo, updateUserInfo } from './member';

export interface ProfileEditForm {
	birthday: FormControl<string>;
	name: FormControl<string>;
	mobileNumber: FormControl<string>;
	telephoneNumber: FormControl<string>;
	edmStatus: FormControl<YesOrNo>;
	city: FormControl<string>;
	cityZone: FormControl<string>;
	address: FormControl<string>;
}

export interface InvoiceEditForm {
	id: FormControl<number>;
	userId: FormControl<number>;
	type: FormControl<InvoiceType>;
	taxName: FormControl<string>;
	taxNum: FormControl<string>;
	carrierType: FormControl<CarrierType>;
	carrierNo: FormControl<string>;
}

export interface RecipientEditForm {
	id: FormControl<number>;
	type: FormControl<RecipientType>;
	name: FormControl<string>;
	mobileNumber: FormControl<string>;
	telephoneNumber: FormControl<string>;
	city: FormControl<string>;
	cityZone: FormControl<string>;
	address: FormControl<string>;
	expectedDeliveryTime: FormControl<DeliveryTimeType>;
	storeCode: FormControl<string>;
	storeName: FormControl<string>;
	storeAddress: FormControl<string>;
}

// For Global State usage
export interface State {
	loading: boolean;
	profileEditForm: ProfileEditForm;
	invoiceEditForm: InvoiceEditForm;
	recipientEditForm: RecipientEditForm;
	isCarrierNumValid: boolean;
	barcodeIsChecking: boolean;
	commonRecipientInfoTab: number;
}

export const defaultState: State = {
	loading: false,
	profileEditForm: {
		birthday: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		name: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		mobileNumber: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require, Validators.pattern(regexMap.mobile)],
			},
		},
		telephoneNumber: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require, Validators.pattern(regexMap.telephoneNumber)],
			},
		},
		edmStatus: {
			value: YesOrNo.DEFAULT,
			errors: null,
		},
		city: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		cityZone: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		address: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
	},
	invoiceEditForm: {
		id: {
			value: 0,
			errors: null,
		},
		userId: {
			value: 0,
			errors: null,
		},
		type: {
			value: InvoiceType.PERSON,
			errors: null,
		},
		taxName: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		taxNum: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require, Validators.pattern(regexMap[VALIDATION_RULES.TAXID])],
			},
		},
		carrierType: {
			value: CarrierType.PHONE,
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		carrierNo: {
			value: '',
			errors: null,
			options: {
				validators: [
					Validators.require,
					Validators.pattern(regexMap[VALIDATION_RULES.MOBILEBARCODE]),
				],
			},
		},
	},
	recipientEditForm: {
		id: {
			value: 0,
			errors: null,
		},
		type: {
			value: RecipientType.HOME,
			errors: null,
		},
		name: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.require],
			},
		},
		mobileNumber: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.pattern(regexMap.mobile)],
			},
		},
		telephoneNumber: {
			value: '',
			errors: null,
			options: {
				validators: [Validators.pattern(regexMap.telephoneNumber)],
			},
		},
		city: {
			value: '',
			errors: null,
		},
		cityZone: {
			value: '',
			errors: null,
		},
		address: {
			value: '',
			errors: null,
		},
		expectedDeliveryTime: {
			value: DeliveryTimeType.NONE,
			errors: null,
		},
		storeCode: {
			value: '',
			errors: null,
		},
		storeName: {
			value: '',
			errors: null,
		},
		storeAddress: {
			value: '',
			errors: null,
		},
	},
	isCarrierNumValid: false,
	barcodeIsChecking: false,
	commonRecipientInfoTab: 0,
};

const setFormCtrlValue = createAction(
	'PROFILE_EDIT_FORM_SET_CTRL_VALUE',
	<R>(ctrlName: keyof ProfileEditForm, value: R) =>
		(_: Dispatch, getState: GetState) => {
			const {
				accountEditForm: { profileEditForm: form },
			} = getState();
			return setCtrlValue<ProfileEditForm, R>(form, ctrlName, value);
		},
);

const patchFormValue = createAction(
	'PROFILE_EDIT_FORM_PATCH_VALUE',
	(values: Record<keyof ProfileEditForm, unknown>) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { profileEditForm: form },
		} = getState();
		return patchValue<ProfileEditForm>(form, values);
	},
);

const updateFormCtrlValidity = createAction(
	'PROFILE_EDIT_FORM_UPDATE_CTRL_VALIDITY',
	(ctrlName: keyof ProfileEditForm) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { profileEditForm: form },
		} = getState();
		return updateCtrlValidity<ProfileEditForm>(form, ctrlName);
	},
);

const profileEditFormSave = createAction(
	'PROFILE_EDIT_FORM_SAVE',
	(type: ProfileEditedType) => (dispatch: Dispatch, getState: GetState) => {
		dispatch(updateFormCtrlValidity(type));

		const {
			accountEditForm: { profileEditForm: form },
		} = getState();

		const isValid = isCtrlValid(form, type);

		if (!isValid) {
			return;
		}

		switch (type) {
			case 'birthday':
				dispatch(
					updateUserInfo({
						birthday: form.birthday.value,
					}),
				);
				break;
			case 'name':
				dispatch(
					updateUserInfo({
						name: form.name.value,
					}),
				);
				break;
			case 'mobileNumber':
				dispatch(
					updateUserInfo({
						mobile_number: form.mobileNumber.value,
					}),
				);
				break;
			case 'telephoneNumber':
				dispatch(
					updateUserInfo({
						telephone_number: form.telephoneNumber.value,
					}),
				);
				break;
			case 'edmStatus':
				dispatch(
					updateUserInfo({
						edm_status: form.edmStatus.value as any,
					}),
				);
				break;
			case 'address':
				if (form.city.value && form.cityZone.value && form.address.value) {
					dispatch(
						updateUserInfo({
							city: form.city.value,
							city_zone: form.cityZone.value,
							address: form.address.value,
						}),
					);
				}

				break;
			default:
				break;
		}
	},
);

const resetProfileEditForm = createAction('PROFILE_EDIT_FORM_RESET');

const setInvoiceEditFormCtrlValue = createAction(
	'INVOICE_EDIT_FORM_SET_CTRL_VALUE',
	<R>(ctrlName: keyof InvoiceEditForm, value: R) =>
		(_: Dispatch, getState: GetState) => {
			const {
				accountEditForm: { invoiceEditForm: form },
			} = getState();
			return setCtrlValue<InvoiceEditForm, R>(form, ctrlName, value);
		},
);

const patchInvoiceEditFormValue = createAction(
	'INVOICE_EDIT_FORM_PATCH_VALUE',
	(values: Record<keyof InvoiceEditForm, unknown>) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { invoiceEditForm: form },
		} = getState();
		return patchValue<InvoiceEditForm>(form, values);
	},
);

const updateInvoiceEditFormCtrlValidity = createAction(
	'INVOICE_EDIT_FORM_UPDATE_CTRL_VALIDITY',
	(ctrlName: keyof InvoiceEditForm) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { invoiceEditForm: form },
		} = getState();
		return updateCtrlValidity<InvoiceEditForm>(form, ctrlName);
	},
);

const updateInvoiceEditFormValidity = createAction(
	'INVOICE_EDIT_FORM_UPDATE_VALIDITY',
	() => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { invoiceEditForm: form },
		} = getState();
		return updateValidity<InvoiceEditForm>(form);
	},
);

const setInvoiceEditFormValidators = createAction(
	'INVOICE_EDIT_FORM_SET_VALIDATORS',
	(ctrlName: keyof InvoiceEditForm, validators: ValidatorFnType[]) =>
		(_: Dispatch, getState: GetState) => {
			const {
				accountEditForm: { invoiceEditForm: form },
			} = getState();
			return setCtrlValidators<InvoiceEditForm>(form, ctrlName, validators);
		},
);

const resetInvoiceEditForm = createAction('INVOICE_EDIT_FORM_RESET');

const prepareInvoiceEditForm = createAction(
	'PREPARE_INVOICE_EDIT_FORM',
	(invoiceInfo: UserInvoiceResource) => (dispatch: Dispatch) => {
		const { type, carrierType } = invoiceInfo;
		dispatch(resetInvoiceEditForm());
		if (type === InvoiceType.PERSON && carrierType === CarrierType.NATURAL_PERSON) {
			dispatch(
				setInvoiceEditFormValidators('carrierNo', [
					Validators.require,
					Validators.pattern(regexMap[VALIDATION_RULES.CERTIFICATE]),
				]),
			);
		}
		if (type === InvoiceType.COMPANY) {
			dispatch(setInvoiceEditFormValidators('taxName', [Validators.require]));
			dispatch(
				setInvoiceEditFormValidators('taxNum', [
					Validators.require,
					Validators.pattern(regexMap[VALIDATION_RULES.TAXID]),
				]),
			);
			dispatch(setInvoiceEditFormValidators('carrierType', []));
			dispatch(setInvoiceEditFormValidators('carrierNo', []));
		}
		dispatch(patchInvoiceEditFormValue(invoiceInfo as Record<keyof InvoiceEditForm, unknown>));
	},
);

/**
 * 常用發票編輯視窗點擊儲存
 */
const invoiceEditFormSave = createAction(
	'INVOICE_EDIT_FORM_SAVE',
	() => (dispatch: Dispatch, getState: GetState) => {
		dispatch(updateInvoiceEditFormValidity());

		const {
			accountEditForm: { invoiceEditForm: form },
		} = getState();

		const isValid = isFormValid(form);

		if (!isValid) {
			return;
		}

		dispatch(
			updateInvoiceInfo({
				invoiceId: form.id.value,
				type: form.type.value,
				carrier_type: form.carrierType.value,
				carrier_no: form.carrierNo.value,
				tax_name: form.taxName.value,
				tax_num: form.taxNum.value,
			}),
		);
	},
);

const checkMobileBarcode = createAction(
	'CHECK_MOBILE_BARCODE',
	(barcode: string) => async (dispatch: Dispatch) => {
		const { v1UserEcpayInvoiceCheckMobileBarcodeList } = api;
		try {
			const { status, data } = await v1UserEcpayInvoiceCheckMobileBarcodeList(
				{
					carrier_no: barcode,
				},
				{ secure: true },
			);
			if (status === 200) {
				dispatch(setCarrierNumValid(data?.data?.isExist === 'Y'));
			}
		} catch (error) {
			console.log(error);
		}
	},
);

const setCarrierNumValid = createAction<boolean, boolean>(
	'SET_CARRIER_NUM_VALID',
	isValid => isValid,
);

const resetRecipientEditForm = createAction('RECIPIENT_EDIT_FORM_RESET');

const setRecipientEditFormValidators = createAction(
	'RECIPIENT_EDIT_FORM_SET_VALIDATORS',
	(ctrlName: keyof RecipientEditForm, validators: ValidatorFnType[]) =>
		(_: Dispatch, getState: GetState) => {
			const {
				accountEditForm: { recipientEditForm: form },
			} = getState();
			return setCtrlValidators<RecipientEditForm>(form, ctrlName, validators);
		},
);

export const prepareRecipientEditForm = createAction(
	'PREPARE_RECIPIENT_EDIT_FORM',
	(recipientInfo: UserRecipientResource) => (dispatch: Dispatch) => {
		const { type } = recipientInfo;
		dispatch(resetRecipientEditForm());
		if (type === RecipientType.STORE) {
			dispatch(setRecipientEditFormValidators('storeCode', [Validators.require]));
			dispatch(setRecipientEditFormValidators('storeName', [Validators.require]));
			dispatch(setRecipientEditFormValidators('storeAddress', [Validators.require]));
			dispatch(setRecipientEditFormValidators('city', []));
			dispatch(setRecipientEditFormValidators('cityZone', []));
			dispatch(setRecipientEditFormValidators('address', []));
		}
		dispatch(
			patchRecipientEditFormValue(recipientInfo as Record<keyof RecipientEditForm, unknown>),
		);
	},
);

const setRecipientEditFormCtrlValue = createAction(
	'RECIPIENT_EDIT_FORM_SET_CTRL_VALUE',
	<R>(ctrlName: keyof RecipientEditForm, value: R) =>
		(_: Dispatch, getState: GetState) => {
			const {
				accountEditForm: { recipientEditForm: form },
			} = getState();
			return setCtrlValue<RecipientEditForm, R>(form, ctrlName, value);
		},
);

const patchRecipientEditFormValue = createAction(
	'RECIPIENT_EDIT_FORM_PATCH_VALUE',
	(values: Record<keyof RecipientEditForm, unknown>) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { recipientEditForm: form },
		} = getState();
		return patchValue<RecipientEditForm>(form, values);
	},
);

const updateRecipientEditFormValidity = createAction(
	'RECIPIENT_EDIT_FORM_UPDATE_VALIDITY',
	() => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { recipientEditForm: form },
		} = getState();
		return updateValidity<RecipientEditForm>(form);
	},
);

const updateRecipientEditFormCtrlValidity = createAction(
	'RECIPIENT_EDIT_FORM_UPDATE_CTRL_VALIDITY',
	(ctrlName: keyof RecipientEditForm) => (_: Dispatch, getState: GetState) => {
		const {
			accountEditForm: { recipientEditForm: form },
		} = getState();
		return updateCtrlValidity<RecipientEditForm>(form, ctrlName);
	},
);

const recipientEditFormSave = createAction(
	'RECIPIENT_EDIT_FORM_SAVE',
	() => (dispatch: Dispatch, getState: GetState) => {
		dispatch(updateRecipientEditFormValidity());
		const {
			accountEditForm: { recipientEditForm: form },
		} = getState();

		const isValid = isFormValid(form);

		if (!isValid) {
			return;
		}

		dispatch(
			updateRecipientInfo({
				recipientId: form.id.value,
				type: form.type.value,
				name: form.name.value,
				mobile_number: form.mobileNumber.value,
				telephone_number: form.telephoneNumber.value,
				city: form.city.value,
				city_zone: form.cityZone.value,
				address: form.address.value,
				expected_delivery_time: form.expectedDeliveryTime.value,
				store_code: form.storeCode.value,
				store_name: form.storeName.value,
				store_address: form.storeAddress.value,
			}),
		);
	},
);

export const setCommonRecipientInfoTab = createAction(
	'SET_COMMON_RECIPIENT_INFO_TAB',
	(tab: number) => tab,
);

export const reducer = {
	accountEditForm: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			PROFILE_EDIT_FORM_SET_CTRL_VALUE: (state, action: Action<Partial<ProfileEditForm>>) => ({
				...state,
				profileEditForm: {
					...state.profileEditForm,
					...action.payload,
				},
			}),
			PROFILE_EDIT_FORM_PATCH_VALUE: (state, action: Action<ProfileEditForm>) => ({
				...state,
				profileEditForm: {
					...action.payload,
				},
			}),
			PROFILE_EDIT_FORM_UPDATE_CTRL_VALIDITY: (
				state,
				action: Action<Partial<ProfileEditForm>>,
			) => ({
				...state,
				profileEditForm: {
					...state.profileEditForm,
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_SET_CTRL_VALUE: (state, action: Action<Partial<InvoiceEditForm>>) => ({
				...state,
				invoiceEditForm: {
					...state.invoiceEditForm,
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_PATCH_VALUE: (state, action: Action<InvoiceEditForm>) => ({
				...state,
				invoiceEditForm: {
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_UPDATE_VALIDITY: (state, action: Action<InvoiceEditForm>) => ({
				...state,
				invoiceEditForm: {
					...state.invoiceEditForm,
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_UPDATE_CTRL_VALIDITY: (
				state,
				action: Action<Partial<InvoiceEditForm>>,
			) => ({
				...state,
				invoiceEditForm: {
					...state.invoiceEditForm,
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_SET_VALIDATORS: (state, action: Action<InvoiceEditForm>) => ({
				...state,
				invoiceEditForm: {
					...state.invoiceEditForm,
					...action.payload,
				},
			}),
			INVOICE_EDIT_FORM_RESET: state => ({
				...state,
				invoiceEditForm: {
					...defaultState.invoiceEditForm,
				},
			}),
			SET_CARRIER_NUM_VALID: (state, action: Action<boolean>) => ({
				...state,
				isCarrierNumValid: action.payload,
			}),
			CHECK_MOBILE_BARCODE_PENDING: state => ({
				...state,
				barcodeIsChecking: true,
			}),
			CHECK_MOBILE_BARCODE_FULFILLED: state => ({
				...state,
				barcodeIsChecking: false,
			}),
			RECIPIENT_EDIT_FORM_SET_CTRL_VALUE: (state, action: Action<Partial<RecipientEditForm>>) => ({
				...state,
				recipientEditForm: {
					...state.recipientEditForm,
					...action.payload,
				},
			}),
			RECIPIENT_EDIT_FORM_PATCH_VALUE: (state, action: Action<RecipientEditForm>) => ({
				...state,
				recipientEditForm: {
					...action.payload,
				},
			}),
			RECIPIENT_EDIT_FORM_UPDATE_CTRL_VALIDITY: (
				state,
				action: Action<Partial<RecipientEditForm>>,
			) => ({
				...state,
				recipientEditForm: {
					...state.recipientEditForm,
					...action.payload,
				},
			}),
			RECIPIENT_EDIT_FORM_RESET: state => ({
				...state,
				recipientEditForm: {
					...defaultState.recipientEditForm,
				},
			}),
			RECIPIENT_EDIT_FORM_SET_VALIDATORS: (state, action: Action<RecipientEditForm>) => ({
				...state,
				recipientEditForm: {
					...state.recipientEditForm,
					...action.payload,
				},
			}),
			SET_COMMON_RECIPIENT_INFO_TAB: (state, action: Action<number>) => ({
				...state,
				commonRecipientInfoTab: action.payload,
			}),
			PROFILE_EDIT_FORM_RESET: state => ({
				...state,
				profileEditForm: {
					...defaultState.profileEditForm,
				},
			}),
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.accountEditForm.loading,
	profileEditForm: state.accountEditForm.profileEditForm,
	invoiceEditForm: state.accountEditForm.invoiceEditForm,
	recipientEditForm: state.accountEditForm.recipientEditForm,
	isCarrierNumValid: state.accountEditForm.isCarrierNumValid,
	barcodeIsChecking: state.accountEditForm.barcodeIsChecking,
	commonRecipientInfoTab: state.accountEditForm.commonRecipientInfoTab,
});

const accountEditFormActionsMap = {
	setFormCtrlValue,
	patchFormValue,
	updateFormCtrlValidity,
	profileEditFormSave,
	setInvoiceEditFormCtrlValue,
	patchInvoiceEditFormValue,
	updateInvoiceEditFormCtrlValidity,
	invoiceEditFormSave,
	prepareInvoiceEditForm,
	setRecipientEditFormCtrlValue,
	prepareRecipientEditForm,
	updateRecipientEditFormCtrlValidity,
	checkMobileBarcode,
	recipientEditFormSave,
	setCommonRecipientInfoTab,
	setCarrierNumValid,
	resetProfileEditForm,
};

type AccountEditFormSelector = ReturnType<typeof mapHooksToState>;
type AccountEditFormActionsMap = typeof accountEditFormActionsMap;

/**
 * @description 我的帳戶編輯資料表單 hook
 * @returns
 */
export const useAccountEditForm = () =>
	useRedux<AccountEditFormSelector, AccountEditFormActionsMap>(
		mapHooksToState,
		accountEditFormActionsMap,
	);
