/* eslint-disable react/button-has-type */
import React from 'react';
import classnames from 'classnames';
import Arrow from 'images/icon/arrow.inline.svg';
import Plus from 'images/icon/plus.inline.svg';
import Loading from 'images/icon/loading.inline.svg';

import styles from './index.module.css';

interface ButtonProperty {
	size?: 'small' | 'medium' | 'large';
	pattern?: 'fill' | 'primaryOutline' | 'secondaryOutline';
	name: string;
	disabled?: boolean;
	handleClick: () => void;
	Icon?: 'plus' | 'arrow' | string;
	iconPosition?: 'left' | 'right';
	className?: string;
	type?: 'submit';
	loading?: boolean;
}

const Button: React.FC<ButtonProperty> = ({
	size = 'medium',
	pattern = 'primaryOutline',
	name,
	handleClick = () => {},
	Icon,
	iconPosition = 'right',
	disabled,
	className,
	type,
	loading = false,
}) => {
	const buttonStyleClass = disabled ? styles.disabled : styles[pattern];
	// eslint-disable-next-line no-nested-ternary
	const IconComponent = Icon === 'plus' ? Plus : Icon === 'arrow' ? Arrow : Icon;
	return (
		<button
			type={type || 'button'}
			className={classnames(
				styles.button,
				styles[size],
				styles[iconPosition],
				buttonStyleClass,
				loading && styles.loading,
				className,
			)}
			onClick={handleClick}
			disabled={disabled || loading}
		>
			{!loading && name}
			{IconComponent && <IconComponent />}
			{loading && (
				<span style={{ height: '23px' }} className={styles.loadingSvg}>
					<Loading />
				</span>
			)}
		</button>
	);
};

export default Button;
