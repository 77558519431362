import React, { useEffect } from 'react';

import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import CookieCard from 'components/atoms/CookieCard';
import Toast from 'components/atoms/Toast';

import { useRouting } from 'models/routing';
import { usePageView } from 'models/pageView';

import { gtmEvent } from 'util/gtmEvent';

interface AppProperty {
	children: React.ReactNode;
}

const App: React.FC<AppProperty> = ({ children }) => {
	const [{ routing }] = useRouting();
	const [{
		pageView: {
			browserHistory,
		}
	}] = usePageView();

	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	// 處理 GTM scroll 事件
	useEffect(() => {
		const handleScroll = () => {
			const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
			if (scrollTop + clientHeight >= scrollHeight * 0.9) {
				// 取消監聽
				window.removeEventListener('scroll', handleScroll);
				// Trigger GTM event
				gtmEvent({
					event: 'scroll',
					eventModel: {
						percent_scrolled: 90,
					},
				});
			}
		};

		// 檢查是否是第一次進入本頁面
		const currentLocation = `${window.location.origin}${routing.pathname}${routing.search}`;
		if (!browserHistory.includes(currentLocation)) {
			// 監聽是否向下 scroll 達 90% 的深度
			window.addEventListener('scroll', handleScroll);
		}

		return () => window.removeEventListener('scroll', handleScroll);
	}, [browserHistory.length]);

	return (
		<>
			<Header />
			{children}
			<Footer />
			<Toast />
			<CookieCard />
		</>
	)
};

export default App;
