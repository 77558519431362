/* eslint-disable no-shadow */
import { Dispatch } from 'redux';
import { handleActions, createAction } from 'redux-actions';
import history from 'store/history';
import { ROUTE_PATHS } from 'routes';

import { useRedux } from 'util/hook/redux';
import { parseQueryString } from 'util/parseQueryString';
import { UserRecipientResource } from 'util/api/swaggerApi/data-contracts';
import { getEcUserByStorage, getItem, removeItem, setItem } from 'util/storage';

import { StorageKey } from 'enums/storageKey';
import { OrderConfirmationType } from 'enums/orderConfirmationType';

import pushHistory from 'util/pushHistory';
import { State as GlobalState } from './reducers';
import { prepareRecipientEditForm, setCommonRecipientInfoTab } from './accountEditForm';
import { ModalTypes, openModal } from './modal';
import { handleEcPayMap, setConfirmationStatus } from './cart';
import { SigninSuccessCallbackInfo } from './signin';

export enum RedirectType {
	DEFAULT = '',

	/**
	 * 綠界地圖
	 */
	MAP = 'map',

	/**
	 * line pay 付款
	 */
	LINE_PAY = 'linePay',

	/**
	 * 國泰信用卡付款
	 */
	CATHAY_CREDIT_CARD_PAY = 'cathayCreditCardPay',
}

/**
 * 轉址應用功能頁面之列舉
 *
 * @export
 * @enum {number}
 */
export enum RedirectFnType {
	COMMON_RECIPIENT_EDIT = 'commonRecipientEdit',
	ORDER_FORM_SELECT = 'orderFormSelect',
	MEMBER_ORDER_DETAIL = 'memberOrderDetail',
	ORDER_CONFIRMATION = 'orderConfirmation',
}

/**
 * 轉址需要的資訊
 *
 * @export
 * @interface RedirectInfo
 * @template T
 */
export interface RedirectInfo<T> {
	type: RedirectType;
	featureType: RedirectFnType;
	backPath: string;
	data?: T;
	otherParams?: string;
}

export interface State {
	type: RedirectType;
}

export const defaultState: State = {
	type: RedirectType.DEFAULT,
};

/**
 * @link ref: https://developers.ecpay.com.tw/?p=8795
 */
const openEcPayMap = createAction<void>('OPEN_ECPAY_MAP', () => {
	const createHiddenInput = (form: HTMLFormElement, name: string, value: string) => {
		const hiddenField = document.createElement('input');
		hiddenField.type = 'hidden';
		hiddenField.name = name;
		hiddenField.value = value;
		form.appendChild(hiddenField);
	};
	const { API_ENDPOINT, EC_PAY_MERCHANT_ID, EC_PAY_MAP_URL } = process.env;
	const form = document.createElement('form');
	form.method = 'POST';
	form.action = EC_PAY_MAP_URL || '';
	createHiddenInput(form, 'MerchantID', EC_PAY_MERCHANT_ID || '');
	createHiddenInput(form, 'LogisticsType', 'CVS');
	createHiddenInput(form, 'LogisticsSubType', 'UNIMART');
	createHiddenInput(form, 'IsCollection', 'Y');
	createHiddenInput(form, 'ServerReplyURL', `${API_ENDPOINT}/backstage/api/v1/ecpay-map/callback`);
	document.body.appendChild(form);
	form.submit();
});

const handleRedirect = createAction<void>('HANDLE_REDIRECT', () => (dispatch: Dispatch) => {
	const storageData = getItem(StorageKey.REDIRECT_INFO);
	if (!storageData) {
		return;
	}

	const redirectInfo: RedirectInfo<unknown> = JSON.parse(storageData);
	const { type } = redirectInfo;

	switch (type) {
		case RedirectType.MAP:
			dispatch(handleRedirectByMap(redirectInfo));
			break;

		case RedirectType.LINE_PAY:
			dispatch(handleRedirectByLinePay(redirectInfo));
			break;

		case RedirectType.CATHAY_CREDIT_CARD_PAY:
			dispatch(handleRedirectByCathayCreditCardPay(redirectInfo));
			break;

		default:
			break;
	}
});

const handleRedirectByMap = createAction<void, RedirectInfo<unknown>>(
	'HANDLE_REDIRECT_BY_MAP',
	redirectInfo => (dispatch: Dispatch) => {
		const query = parseQueryString();
		const { CVSStoreID, CVSStoreName, CVSAddress } = query;
		const { featureType, backPath, otherParams } = redirectInfo;

		switch (featureType) {
			case RedirectFnType.COMMON_RECIPIENT_EDIT: {
				const storeData = redirectInfo.data as UserRecipientResource;
				storeData.storeCode = CVSStoreID;
				storeData.storeName = CVSStoreName;
				storeData.storeAddress = CVSAddress;
				dispatch(prepareRecipientEditForm(storeData));
				dispatch(openModal(ModalTypes.ReceivingInfoEditedModal));
				dispatch(setCommonRecipientInfoTab(1));
				pushHistory(history, backPath, otherParams);
				removeItem(StorageKey.REDIRECT_INFO);
				break;
			}

			case RedirectFnType.ORDER_FORM_SELECT:
				dispatch(handleEcPayMap(CVSStoreID, CVSStoreName, CVSAddress));
				pushHistory(history, backPath, otherParams);
				removeItem(StorageKey.REDIRECT_INFO);
				break;

			default:
				break;
		}
	},
);

export const pushAuthGuardRoute = createAction<void, string>('PUSH_AUTH_GUARD_ROUTE', path => {
	let targetPath = path;

	// 未登入情況下，讓使用者回到登入頁面，登入後再返回指定頁面
	if (!getEcUserByStorage()) {
		// 儲存導頁需要的路徑與相關資料
		const info: SigninSuccessCallbackInfo = {
			backPath: path,
		};
		setItem(StorageKey.SIGNIN_CALLBACK_INFO, JSON.stringify(info));
		targetPath = `/${ROUTE_PATHS.signin}`;
	}
	pushHistory(history, targetPath);
	window.scrollTo(0, 0);
});

const handleRedirectByLinePay = createAction<void, RedirectInfo<unknown>>(
	'HANDLE_REDIRECT_BY_LINE_PAY',
	redirectInfo => (dispatch: Dispatch) => {
		// 取得 Line pay redirect 回來需要的 url query
		const query = parseQueryString();
		const { result } = query;
		const { featureType, backPath, otherParams } = redirectInfo;

		// 根據各個應用頁面，來處理轉址後需要處理的事情
		switch (featureType) {
			case RedirectFnType.MEMBER_ORDER_DETAIL: {
				pushHistory(history, backPath, otherParams);
				removeItem(StorageKey.REDIRECT_INFO);
				break;
			}

			case RedirectFnType.ORDER_CONFIRMATION: {
				if (result === 'successed') {
					dispatch(setConfirmationStatus(OrderConfirmationType.PAYMENT_SUCCESSFUL));
				} else {
					dispatch(setConfirmationStatus(OrderConfirmationType.PAYMENT_FAIL));
				}
				pushHistory(history, backPath, `redirect=true${otherParams && `&${otherParams}`}`);
				removeItem(StorageKey.REDIRECT_INFO);
				break;
			}

			default:
				break;
		}
	},
);

const handleRedirectByCathayCreditCardPay = createAction<void, RedirectInfo<unknown>>(
	'HANDLE_REDIRECT_BY_CATHAY_CREDIT_CARD_PAY',
	redirectInfo => (dispatch: Dispatch) => {
		// 取得國泰信用卡 redirect 回來需要的 url query
		const query = parseQueryString();
		const { result } = query;
		const { featureType, backPath, otherParams } = redirectInfo;

		// 根據各個應用頁面，來處理轉址後需要處理的事情
		switch (featureType) {
			case RedirectFnType.MEMBER_ORDER_DETAIL: {
				pushHistory(history, backPath, otherParams);
				removeItem(StorageKey.REDIRECT_INFO);
				break;
			}

			case RedirectFnType.ORDER_CONFIRMATION: {
				if (result === 'successed') {
					dispatch(setConfirmationStatus(OrderConfirmationType.PAYMENT_SUCCESSFUL));
				} else {
					dispatch(setConfirmationStatus(OrderConfirmationType.PAYMENT_FAIL));
				}
				pushHistory(history, backPath, `redirect=true${otherParams && `&${otherParams}`}`);
				removeItem(StorageKey.REDIRECT_INFO);
				break;
			}

			default:
				break;
		}
	},
);

export const reducer = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	redirect: handleActions<State, any>({}, defaultState),
};

const mapHooksToState = (state: GlobalState) => state.modal;

const redirectActionMap = {
	handleRedirect,
	openEcPayMap,
	pushAuthGuardRoute,
};

type RedirectSelector = ReturnType<typeof mapHooksToState>;
type RedirectActionsMap = typeof redirectActionMap;

export const useRedirect = () =>
	useRedux<RedirectSelector, RedirectActionsMap>(mapHooksToState, redirectActionMap);
