import React from 'react';
import history from 'store/history';
import { ROUTE_PATHS } from 'routes';

import {
	selectInvoice,
	selectRecipient,
	setCartData,
	setConfirmationStatus,
	setOrderToDefault,
} from 'models/cart';
import { getCouponsList, getUserCouponList } from 'models/coupon';
import {
	getFirstPurchaseEvents,
	getFreeShippingEventList,
	getThresholdRewardsList,
} from 'models/discountEvent';
import { getAllGiftStock } from 'models/stock';

import { CustomRoute } from 'util/hook/useRouter';
import { getEcUserByStorage, removeItem } from 'util/storage';
import { getUserInfo } from 'models/member';
import pushHistory from 'util/pushHistory';

export const sleep = (time: number): Promise<undefined> =>
	new Promise(resolve => setTimeout(resolve, time));

const routes: CustomRoute = {
	path: '/cart',
	components: () => [import(/* webpackChunkName: 'cart' */ './component')],
	render: ([Cart]) => <Cart />,
	onEnter: async ({ next, store }) => {
		const ecUserToken = getEcUserByStorage();

		if (!ecUserToken) {
			pushHistory(history, `/${ROUTE_PATHS.signin}`);
			window.scrollTo(0, 0);
			return null;
		}
		removeItem('paypage');
		store.dispatch(getUserInfo());
		await store.dispatch(setCartData());
		store.dispatch(getThresholdRewardsList());
		store.dispatch(getFreeShippingEventList());
		store.dispatch(getAllGiftStock());
		store.dispatch(selectInvoice(null));
		store.dispatch(selectRecipient(null));
		store.dispatch(getCouponsList());
		store.dispatch(getUserCouponList());
		store.dispatch(setConfirmationStatus(undefined));
		store.dispatch(setOrderToDefault());
		store.dispatch(getFirstPurchaseEvents());

		const children = await next();
		return children;
	},
};
export default routes;
