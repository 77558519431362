import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/new-member',
	components: () => [import(/* webpackChunkName: 'newMember' */ './component')],
	render: ([NewMember]) => <NewMember />,
};
export default routes;
