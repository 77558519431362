/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';

import { ARTICLE_CATEGORIES } from 'enums/blog';

import { api } from 'util/api';
import {
	NewsCategoriesResource,
	NewsCategoriesCollection,
	NewsResource,
	V1NewsListRequestParams,
	V1NewsDetailRequestParams,
} from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

type NewsType = {
	id: number;
	category: string;
	title: string;
	subTitle: string;
	image: string;
	contents: string;
	beginAt: string | null;
	endAt: string | null;
	visibilityRule: string;
	weight: number;
};

export type NewsListType = {
	[key in ARTICLE_CATEGORIES]: NewsType[] | [];
};

export const getNewsCategories = createAction<
	Promise<{ newsCategories: NewsCategoriesResource[] }>
>('GET_NEWS_CATEGORIES', async () => {
	try {
		const { v1NewsCategoriesNavList } = api;
		const { data } = await v1NewsCategoriesNavList();
		const list = data?.data as NewsCategoriesResource[];
		const result = [
			{
				id: 0,
				name: '全部消息',
				status: '上架',
				weight: list.length ? (list[0].weight || 0) + 1 : 1,
			},
			...list,
		];
		return { newsCategories: result };
	} catch (e) {
		return { newsCategories: [] };
	}
});

export const getNewsList = createAction<Promise<{ newsList: NewsCategoriesCollection[] }>>(
	'GET_HOME_NEWS_LIST',
	async () => {
		try {
			const { v1NewsCategoriesList } = api;
			const { data } = await v1NewsCategoriesList();
			return { newsList: data?.data as NewsCategoriesCollection[] };
		} catch (e) {
			return { newsList: [] };
		}
	},
);

export const getLatestNews = createAction<Promise<{ latestNews: NewsResource }>>(
	'GET_LATEST_NEWS',
	async () => {
		try {
			const { v1NewsNewestList } = api;
			const { data } = await v1NewsNewestList();
			const response = (data?.data as NewsResource) || {};
			const latestNews: NewsResource = {
				...response,
				beginAt: dayjs(response.beginAt).isValid()
					? dayjs(response.beginAt).format('YYYY.MM.DD')
					: '',
			};
			return { latestNews };
		} catch (e) {
			return { latestNews: {} };
		}
	},
);

export const getBlogNewsList = createAction<
	Promise<{ blogNewsList: NewsResource[] }>,
	V1NewsListRequestParams
>('GET_BLOG_NEWS_LIST', async params => {
	try {
		const { v1NewsList } = api;
		if (!params.category_id) {
			delete params.category_id;
		}
		const { data } = await v1NewsList(params);
		const list = data?.data as NewsResource[];
		list.forEach(news => {
			news.beginAt = dayjs(news.beginAt).format('YYYY.MM.DD');
		});
		const total = data?.meta?.total || 0;
		const paginationCount = Math.ceil(total / 4);
		return {
			blogNewsList: list,
			paginationCount,
		};
	} catch (e) {
		return { blogNewsList: [] };
	}
});

export const getBlogDetailById = createAction<
	Promise<{ currentNews: NewsResource }>,
	V1NewsDetailRequestParams
>('GET_BLOG_DETAIL_BY_ID', async params => {
	try {
		const { v1NewsDetail } = api;
		const { data } = await v1NewsDetail(params);
		const detail = data?.data?.news as NewsResource;
		detail.beginAt = dayjs(detail.beginAt).format('YYYY.MM.DD');
		return {
			currentNews: detail,
			prevNewsId: data?.data?.previous || 0,
			nextNewsId: data?.data?.next || 0,
		};
	} catch (e) {
		return {
			currentNews: {},
			prevNewsId: 0,
			nextNewsId: 0,
		};
	}
});

export interface State {
	loading: boolean;

	/**
	 * 文章類別列表
	 *
	 * @type {NewsCategoriesResource[]}
	 * @memberof State
	 */
	newsCategories: NewsCategoriesResource[];

	/**
	 * 最新文章
	 *
	 * @type {NewsResource}
	 * @memberof State
	 */
	latestNews: NewsResource;

	/**
	 * 部落格列表頁文章列表
	 *
	 * @type {NewsResource[]}
	 * @memberof State
	 */
	blogNewsList: NewsResource[];

	/**
	 * 目前查看的消息
	 *
	 * @type {NewsResource}
	 * @memberof State
	 */
	currentNews: NewsResource;

	prevNewsId: number;

	nextNewsId: number;

	/**
	 * 首頁部落格文章列表
	 *
	 * @type {NewsCategoriesCollection[]}
	 * @memberof State
	 */
	newsList: NewsCategoriesCollection[];

	/**
	 * 分頁按鈕數量
	 *
	 * @type {number}
	 * @memberof State
	 */
	paginationCount: number;
}

export const defaultState: State = {
	loading: false,
	newsCategories: [],
	latestNews: {},
	blogNewsList: [],
	newsList: [],
	currentNews: {},
	prevNewsId: 0,
	nextNewsId: 0,
	paginationCount: 1,
};

export const reducer = {
	blog: handleActions<State, any>(
		{
			GET_NEWS_CATEGORIES_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_NEWS_CATEGORIES_FULFILLED: (state, action) => ({
				...state,
				newsCategories: action.payload.newsCategories,
				loading: false,
			}),
			GET_HOME_NEWS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_HOME_NEWS_LIST_FULFILLED: (state, action) => ({
				...state,
				newsList: action.payload.newsList,
				loading: false,
			}),
			GET_BLOG_NEWS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_BLOG_NEWS_LIST_FULFILLED: (state, action) => ({
				...state,
				blogNewsList: action.payload.blogNewsList,
				paginationCount: action.payload.paginationCount,
				loading: false,
			}),
			GET_LATEST_NEWS_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LATEST_NEWS_FULFILLED: (state, action) => ({
				...state,
				latestNews: action.payload.latestNews,
				loading: false,
			}),
			GET_BLOG_DETAIL_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_BLOG_DETAIL_BY_ID_FULFILLED: (state, action) => ({
				...state,
				...action.payload,
				loading: false,
			}),
		},
		defaultState,
	),
};

const blogActionsMap = {
	getNewsCategories,
	getNewsList,
	getBlogNewsList,
	getBlogDetailById,
};

const mapHooksToState = (state: GlobalState) => ({
	newsCategories: state.blog.newsCategories,
	newsList: state.blog.newsList,
	blogNewsList: state.blog.blogNewsList,
	latestNews: state.blog.latestNews,
	paginationCount: state.blog.paginationCount,
	currentNews: state.blog.currentNews,
	prevNewsId: state.blog.prevNewsId,
	nextNewsId: state.blog.nextNewsId,
});

// 首頁部落格文章分類為死的，尚有一項樣待新增
const selectedNewsList = createSelector([state => state.blog.newsList], newsList => {
	// 展開列表
	const flatNewsList = newsList.reduce(
		(prev: NewsType[], item: NewsCategoriesCollection) => [...prev, ...(item.news as NewsType[])],
		[],
	);
	// 整理排序
	const sortNewsListById = flatNewsList.sort((a: NewsType, b: NewsType) => {
		if (b.weight === a.weight) {
			return b.id - a.id;
		}
		return b.weight - a.weight;
	});
	// 整理分類（有順序性）
	const combinedItem: NewsListType = {
		[ARTICLE_CATEGORIES.ALL]: [],
		[ARTICLE_CATEGORIES.LATEST]: [],
		[ARTICLE_CATEGORIES.MEMBER_CAMPAIGN]: [],
		[ARTICLE_CATEGORIES.MEDIA]: [],
		[ARTICLE_CATEGORIES.SECRET_BASE]: [],
		[ARTICLE_CATEGORIES.GENDER_EQUITY]: [],
	};
	sortNewsListById.forEach((item: NewsType) => {
		const category = item.category as ARTICLE_CATEGORIES;
		// 判斷各類分類長度數量：最多為四項，id 越高越前面
		if (combinedItem[ARTICLE_CATEGORIES.ALL].length < 4) {
			combinedItem[ARTICLE_CATEGORIES.ALL] = [
				...combinedItem[ARTICLE_CATEGORIES.ALL],
				{ ...(item as NewsType), beginAt: dayjs((item as NewsType).beginAt).format('YYYY.MM.DD') },
			];
		}
		if (combinedItem[category]?.length < 4) {
			combinedItem[category] = [
				...combinedItem[category],
				{ ...(item as NewsType), beginAt: dayjs((item as NewsType).beginAt).format('YYYY.MM.DD') },
			];
		}
	});
	return {
		newsList: combinedItem,
	};
});

type BlogSelector = ReturnType<typeof mapHooksToState>;
type BlogActionsMap = typeof blogActionsMap;
type SelectedNewsList = ReturnType<typeof selectedNewsList>;

/**
 * @description 部落格相關資訊的 hook
 * @returns
 */
export const useBlog = () =>
	useRedux<BlogSelector, BlogActionsMap>(mapHooksToState, blogActionsMap);

export const useNewsList = () => useRedux<SelectedNewsList, any>(selectedNewsList, {});
