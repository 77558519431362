import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/email-verify',
	components: () => [import(/* webpackChunkName: 'emailVerify' */ './component')],
	render: ([EmailVerify]) => <EmailVerify />,
};
export default routes;
