import React, { useState } from 'react';
import classnames from 'classnames';

import DropdownItem, { DropdownItemData } from 'components/atoms/DropdownItem';
import DecoratedLink from 'components/molecules/DecoratedLink';

import { memberFeatureList } from 'models/member';
import { useSignin } from 'models/signin';

import useMedia from 'util/hook/useMedia';

import styles from './index.module.css';

interface SideMemberFeaturesProperty {
	className?: string;
	closeHeader?: () => void;
}

const SideMemberFeatures: React.FC<SideMemberFeaturesProperty> = ({
	className,
	closeHeader = () => {},
}) => {
	const media = useMedia();
	const [, { logout }] = useSignin();
	const [openList, setOpenList] = useState<boolean[]>(() => memberFeatureList.map(() => false));
	return (
		<div className={classnames(styles.sideMemberFeatures, className)}>
			{media === 'desktop' ? (
				<>
					{memberFeatureList.map(feature => (
						<DecoratedLink
							key={feature.name}
							to={feature.to}
							name={feature.name}
							onClick={closeHeader}
							className={styles.itemContainer}
						/>
					))}
				</>
			) : (
				<>
					{memberFeatureList.map((feature, index) =>
						feature.expandData.length ? (
							<DropdownItem
								data={feature.expandData as DropdownItemData}
								name={feature.name}
								handlePanelClick={closeHeader}
								handleMainClick={() => {
									const newList = openList.map(() => false);
									newList[index] = true;
									setOpenList(newList);
								}}
								key={feature.name}
								autoClose={false}
								open={openList[index]}
							/>
						) : (
							<DecoratedLink
								key={feature.name}
								to={feature.to}
								name={feature.name}
								onClick={closeHeader}
								className={styles.itemContainer}
							/>
						),
					)}
				</>
			)}
			<DecoratedLink
				key="登出"
				name="登出"
				onClick={() => {
					closeHeader();
					logout();
				}}
				className={styles.itemContainer}
			/>
		</div>
	);
};

export default SideMemberFeatures;
