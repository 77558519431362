import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';
import { getCookie } from 'util/handleCookies';
import {
	V1LineShopTrackingEventsNormalCreateRequestPayload,
	V1LineShopTrackingEventsProductsCreateRequestPayload,
} from 'util/api/swaggerApi/data-contracts';

import { State as GlobalState } from './reducers';

export interface State {
	loading: boolean;
}

export enum LineEventType {
	NORMAL = 'normal',
	PRODUCTS = 'products',
}

export enum LineEventId {
	MEMBER_REGISTRATION = 1,
	PAGE_VIEW = 2,
	ADD_TO_CART = 4,
	PRODUCT_BROWSING = 5,
}

interface ProductDetails {
	product_id: string;
	product_name: string;
	product_type: LineEventType;
	product_amount: number;
}

export const defaultState: State = {
	loading: false,
};

export const getProductIdForLineEvent = (materialNo: string, isCombination = false) => {
	const prefix = isCombination ? 'combination' : 'product';
	return `${prefix}_${materialNo || ''}`;
};

export const sendLineEvent = createAction(
	'SEND_LINE_EVENT',
	async (
		eventData:
			| V1LineShopTrackingEventsNormalCreateRequestPayload
			| V1LineShopTrackingEventsProductsCreateRequestPayload,
		type: LineEventType,
	) => {
		const { v1LineShopTrackingEventsNormalCreate } = api;
		const { v1LineShopTrackingEventsProductsCreate } = api;

		console.log('eventData------------------', eventData);
		const apiFunc =
			type === LineEventType.NORMAL
				? v1LineShopTrackingEventsNormalCreate
				: v1LineShopTrackingEventsProductsCreate;
		try {
			apiFunc(eventData as V1LineShopTrackingEventsProductsCreateRequestPayload);
		} catch (error) {
			console.error('Error:', error);
		}
	},
);

export const triggerAddToCartByLineEvent = createAction('TRIGGER_ADD_TO_CART_BY_LINE_EVENT', async (productDetails: ProductDetails[]) => (dispatch: Dispatch) => {
	const ecid = getCookie('ecid') || '';
	if (!ecid) return;

	const eventData: V1LineShopTrackingEventsProductsCreateRequestPayload = {
		event_id: LineEventId.ADD_TO_CART,
		ecid,
		event_time: Math.floor(Date.now() / 1000),
		redirect_url: `${process.env.API_ENDPOINT}/cart`,
		products: productDetails,
	};
	dispatch(sendLineEvent(eventData, LineEventType.PRODUCTS));
});

export const triggerProductBrowsingByLineEvent = createAction('TRIGGER_PRODUCT_BROWSING_BY_LINE_EVENT', async (productDetails: ProductDetails[]) => (dispatch: Dispatch) => {
	const ecid = getCookie('ecid') || '';
	if (!ecid) return;
	setTimeout(() => {
		const eventData: V1LineShopTrackingEventsProductsCreateRequestPayload = {
			event_id: LineEventId.PRODUCT_BROWSING,
			ecid,
			event_time: Math.floor(Date.now() / 1000),
			redirect_url: `${process.env.API_ENDPOINT}/cart`,
			products: productDetails,
		};
		dispatch(sendLineEvent(eventData, LineEventType.PRODUCTS));
	}, 3000);
});

export const triggerMemberRegistrationByLineEvent = createAction('TRIGGER_MEMBER_REGISTRATION_BY_LINE_EVENT', async () => (dispatch: Dispatch) => {
	const ecid = getCookie('ecid') || '';
	if (!ecid) return;
	const eventData: V1LineShopTrackingEventsNormalCreateRequestPayload = {
		event_id: LineEventId.MEMBER_REGISTRATION,
		ecid,
		event_time: Math.floor(Date.now() / 1000),
		redirect_url: `${process.env.API_ENDPOINT}`,
	};
	dispatch(sendLineEvent(eventData, LineEventType.NORMAL));
});

export const triggerPageViewByLineEvent = createAction('TRIGGER_PAGE_VIEW_BY_LINE_EVENT', async (pageUrl: string) => (dispatch: Dispatch) => {
	const ecid = getCookie('ecid') || '';
	if (!ecid) return;
	setTimeout(() => {
		const eventData: V1LineShopTrackingEventsNormalCreateRequestPayload = {
			event_id: LineEventId.PAGE_VIEW,
			ecid: getCookie('ecid') || '',
			event_time: Math.floor(Date.now() / 1000),
			redirect_url: pageUrl,
		};
		dispatch(sendLineEvent(eventData, LineEventType.NORMAL));
	}, 3000);
});

export const reducer = {
	lineEvent: handleActions<State, string>({}, defaultState),
};

const mapHooksToState = (state: GlobalState) => ({
	loading: state.lineEvent.loading,
});

const lineEventActionsMap = {
	sendLineEvent,
	triggerAddToCartByLineEvent,
	triggerProductBrowsingByLineEvent,
	triggerMemberRegistrationByLineEvent,
	triggerPageViewByLineEvent,
};

type LineEventSelector = ReturnType<typeof mapHooksToState>;
type LineEventActionsMap = typeof lineEventActionsMap;

export const useLineEvent = () =>
	useRedux<LineEventSelector, LineEventActionsMap>(mapHooksToState, lineEventActionsMap);
