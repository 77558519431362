/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { nanoid } from 'nanoid';
import { useRedux } from 'util/hook/redux';
import history from 'store/history';

import { toast } from 'models/toast';
import { ModalTypes, openModal } from 'models/modal';
import { getLineNewMemberLink } from 'models/auth';
import { triggerMemberRegistrationByLineEvent } from 'models/lineEvent';

import { api } from 'util/api';
import { omniNewRegistration } from 'util/omniEvent';
import { gtmEvent } from 'util/gtmEvent';

import { ERROR_CODE } from 'enums/form';

import { ROUTE_PATHS } from 'routes';
import pushHistory from 'util/pushHistory';
import { GetState, State as GlobalState } from './reducers';

interface BasePayload {
	status: number;
	errorCode?: string | null;
}

interface Error {
	error: BasePayload;
}

interface GetNormalSignupResponse {
	userId: number;
}

interface CheckLineSignupUserEmailResponse {
	verifiedStatus: boolean;
}

export const getNormalSignup = createAction(
	'NORMAL_SIGNUP',
	(email: string, password: string) => async () => {
		try {
			const { v1AuthRegisterCreate } = api;
			const { status, data } = await v1AuthRegisterCreate({ email, password });
			if (status === 200) {
				const { userId } = data?.data as GetNormalSignupResponse;
				omniNewRegistration(userId.toString(), '');
				gtmEvent({
					event: 'sign_up',
					method: 'normal',
				});
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const sendVerificationEmail = createAction(
	'SEND_VERIFICATION_EMAIL',
	(email: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthSendUserVerificationEmailCreate } = api;
			const { status } = await v1AuthSendUserVerificationEmailCreate({ email });
			if (status === 200) {
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '已發送驗證信件',
						type: 'success',
						color: 'purple',
					}),
				);
			}
			return { status };
		} catch (e) {
			dispatch(openModal(ModalTypes.ErrorModal));
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const verifySignupEmailCode = createAction(
	'VERIFY_SIGNUP_EMAIL_CODE',
	(email: string, code: string) => async (dispatch: Dispatch<any>, getState: GetState) => {
		try {
			const {
				discountEvents: { registrationEvent },
			} = getState();
			const { v1AuthVerifyEmailCodeCreate } = api;
			const { status, data } = await v1AuthVerifyEmailCodeCreate({
				email,
				code,
				use_for: 'register',
			});
			if (status === 200) {
				const { receivedRegistrationStatus } = data?.data;
				if (receivedRegistrationStatus && registrationEvent.length > 0) {
					// 註冊禮券領取成功
					dispatch(openModal(ModalTypes.SignUpGiftModal));
				}

				const id = nanoid();
				dispatch(triggerMemberRegistrationByLineEvent());
				dispatch(
					toast({
						id,
						message: '驗證成功，請登入會員',
						type: 'success',
						color: 'green',
					}),
				);

				if (receivedRegistrationStatus === false) {
					// 註冊禮券領取失敗
					return { status, errorCode: 'received_registration_failed' };
				}

				pushHistory(history, `/${ROUTE_PATHS.signin}`);
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const checkLineSignupUserEmail = createAction(
	'CHECK_LINE_SIGNUP_USER_EMAIL',
	(email: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthCheckUserEmailCreate } = api;
			const { status, data } = await v1AuthCheckUserEmailCreate({ email });
			if (status === 200) {
				const { verifiedStatus } = data?.data as CheckLineSignupUserEmailResponse;
				if (verifiedStatus) {
					window.location.href = `/bind?email=${email}`;
					return { status };
				}
				window.location.href = `/email-verify?email=${encodeURIComponent(email)}&type=signup`;
			}
			return { status };
		} catch (e) {
			// 查無此會員
			if ((e as Error).error.errorCode === ERROR_CODE.USER_NOT_FOUND) {
				dispatch(getLineNewMemberLink(email));
				return { status: 200 };
			}
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export interface State {
	loading: boolean;
	normalSignupStatus: BasePayload;
	verificationEmailStatus: BasePayload;
	verifySignupEmailCodeStatus: BasePayload;
	checkLineSignupUserEmailStatus: BasePayload;
}

export const defaultState: State = {
	loading: false,
	normalSignupStatus: {
		status: 0,
		errorCode: null,
	},
	verificationEmailStatus: {
		status: 0,
		errorCode: null,
	},
	verifySignupEmailCodeStatus: {
		status: 0,
		errorCode: null,
	},
	checkLineSignupUserEmailStatus: {
		status: 0,
		errorCode: null,
	},
};

export const reducer = {
	signup: handleActions<State, any>(
		{
			NORMAL_SIGNUP_PENDING: state => ({
				...state,
				loading: true,
			}),
			NORMAL_SIGNUP_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				normalSignupStatus: action.payload,
				loading: false,
			}),
			NORMAL_SIGNUP_REJECTED: state => ({
				...state,
				loading: false,
			}),
			SEND_VERIFICATION_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			SEND_VERIFICATION_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verificationEmailStatus: action.payload,
				loading: false,
			}),
			SEND_VERIFICATION_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verifySignupEmailCodeStatus: action.payload,
				loading: false,
			}),
			VERIFY_SIGNUP_EMAIL_CODE_REJECTED: state => ({
				...state,
				loading: false,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_PENDING: state => ({
				...state,
				loading: true,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				checkLineSignupUserEmailStatus: action.payload,
				loading: false,
			}),
			CHECK_LINE_SIGNUP_USER_EMAIL_REJECTED: state => ({
				...state,
				loading: false,
			}),
		},
		defaultState,
	),
};

const signupActionsMap = {
	getNormalSignup,
	sendVerificationEmail,
	verifySignupEmailCode,
	checkLineSignupUserEmail,
};

const mapHooksToState = (state: GlobalState) => ({
	normalSignupStatus: state.signup.normalSignupStatus,
	sendVerificationEmailStatus: state.signup.verificationEmailStatus,
	verifySignupEmailCodeStatus: state.signup.verifySignupEmailCodeStatus,
	checkLineSignupUserEmailStatus: state.signup.checkLineSignupUserEmailStatus,
});

type SignupSelector = ReturnType<typeof mapHooksToState>;
type SignupActionsMap = typeof signupActionsMap;

export const useSignup = () =>
	useRedux<SignupSelector, SignupActionsMap>(mapHooksToState, signupActionsMap);
