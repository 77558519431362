import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getLevelRules } from 'models/member';


const routes: CustomRoute = {
	path: '/member-benefits',
	components: () => [import(/* webpackChunkName: 'memberBenefits' */ './component')],
	render: ([MemberBenefits]) => <MemberBenefits />,
	onEnter: async ({ store }) => {
		await store.dispatch(getLevelRules());
	},
};
export default routes;
