import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getBlogNewsList, getLatestNews, getNewsCategories } from 'models/blog';

import BlogDetailRoute from './BlogDetail';

const routes: CustomRoute = {
	path: '/blog',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'blog' */ './component')],
			render: ([Blog]) => <Blog />,
			onEnter: async ({ next, store }) => {
				await store.dispatch(getLatestNews());
				store.dispatch(getNewsCategories());
				store.dispatch(getBlogNewsList({ page: 1 }));

				const children = await next();
				return children;
			},
		},
		BlogDetailRoute,
	],
};
export default routes;
