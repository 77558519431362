import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { usePageView } from 'models/pageView';

interface SEOHelmetProperty {
	title?: string;
	description?: string;
	img?: string;
	defaultDescriptionType?: DescriptionType;
	script?: { type: 'application/ld+json'; innerHTML: string }[];
}

export const DEFAULT_TITLE = '愛康衛生棉 ICON | 涼感衛生棉領導品牌';

const DEFAULT_IMAGE = `${process.env.STORAGE_ENDPOINT}/icon_og_image.jpg`;

export enum DescriptionType {
	DEFAULT,
	SERIES,
	GENDER_EQUALITY,
}

const defaultDescription = {
	[DescriptionType.DEFAULT]:
		'ICON愛康官網獨家優惠，涼感衛生棉 - 薄荷精油擺脫悶熱、抑菌衛生棉 - 獨家抑菌配方抑制99.9%細菌、褲型衛生棉 - 褲痕神隱360°防漏，體驗不一樣的生理期！',
	[DescriptionType.SERIES]:
		'涼感衛生棉、抑菌衛生棉、褲型棉、隨身包，完整規格任選，24cm日用衛生棉、28cm夜用衛生棉、41cm超長衛生棉、褲型棉(M/L)。官網會員享專屬折扣！',
	[DescriptionType.GENDER_EQUALITY]:
		'2019年愛康開啟性平之路，走訪全台超過70間學校，將「性別尊重、數位性暴力、情感教育」等知識帶入校園。教材使用詢問、校園性平講座、採購校園衛生棉物資，填寫聯絡表單。',
};

const SEOHelmet: React.FC<SEOHelmetProperty> = ({
	title, script,
	description,
	img,
	defaultDescriptionType = DescriptionType.DEFAULT,
}) => {
	const [, { titleChange }] = usePageView();

	const fullTitle = `${title}｜愛康衛生棉 ICON`;

	useEffect(() => {
		titleChange(title ? fullTitle : DEFAULT_TITLE);
	}, [title]);

	return (
		<Helmet script={script}>
			<title>{title ? fullTitle : DEFAULT_TITLE}</title>
			<meta
				name="description"
				content={description || defaultDescription[defaultDescriptionType]}
			/>
			<meta property="og:title" content={title ? fullTitle : DEFAULT_TITLE} />
			<meta
				property="og:description"
				content={description || defaultDescription[defaultDescriptionType]}
			/>
			<meta property="og:url" content={window.location.href} />
			<meta property="og:image" content={img || DEFAULT_IMAGE} />
			<meta property="og:image:url" content={img || DEFAULT_IMAGE} />
			<meta property="og:image:secure_url" content={img || DEFAULT_IMAGE} />
		</Helmet>
	);
};

export default SEOHelmet;
