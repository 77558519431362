/* eslint-disable arrow-body-style */
/* eslint-disable indent */
import { createAction, handleActions } from 'redux-actions';
import { Dispatch } from 'redux';

import { useRedux } from 'util/hook/redux';
import { gtmEvent } from 'util/gtmEvent';

import { DEFAULT_TITLE } from 'components/atoms/SEOHelmet';
import { GetState, State as GlobalState } from './reducers';
import { triggerPageViewByLineEvent } from './lineEvent';

export type State = {
	title: string;
	location: string;
	lastLocation: string;
	browserHistory: string[];
};

export const defaultState: State = {
	title: DEFAULT_TITLE,
	location: '',
	lastLocation: '',
	browserHistory: [],
};

export const titleChange = createAction(
	'TRIGGER_TITLE_CHANGE',
	(title: string) => (dispatch: Dispatch, getState: GetState) => {
		dispatch(pageViewTitleChange(title));
		dispatch(triggerPageViewEvent());
		const {
			pageView: { location },
		} = getState();
		dispatch(triggerPageViewByLineEvent(location));
	},
);

export const pageViewTitleChange = createAction<string, string>(
	'PAGE_VIEW_TITLE_CHANGE',
	title => title,
);

export const triggerPageViewEvent = createAction(
	'TRIGGER_PAGE_VIEW_EVENT',
	() => (_: Dispatch, getState: GetState) => {
		const {
			pageView: { title, location, lastLocation },
		} = getState();
		gtmEvent({
			event: 'page_view',
			page_title: title,
			page_location: location,
			page_referrer: lastLocation,
		});
	},
);

export const locationChange = createAction<string, string>(
	'PAGE_VIEW_LOCATION_CHANGE',
	(location: string) => location,
);

export const reducer = {
	pageView: handleActions<State, string>(
		{
			PAGE_VIEW_TITLE_CHANGE: (state, action) => {
				const newState = {
					...state,
					title: action.payload,
				};
				return newState;
			},
			PAGE_VIEW_LOCATION_CHANGE: (state, action) => {
				const isSameLocation = state.location === action.payload;
				if (isSameLocation) {
					return state;
				}

				const browserHistory = [...state.browserHistory];
				if (!!state.location && !browserHistory.includes(state.location)) {
					browserHistory.push(state.location);
				}

				return {
					...state,
					location: action.payload,
					lastLocation: isSameLocation ? state.lastLocation : state.location,
					browserHistory,
				};
			},
		},
		defaultState,
	),
};

const mapHooksToState = (state: GlobalState) => ({
	pageView: state.pageView,
});

const pageViewActionsMap = {
	titleChange,
	locationChange,
};

type PageViewSelector = ReturnType<typeof mapHooksToState>;
type PageViewActionsMap = typeof pageViewActionsMap;

export const usePageView = () =>
	useRedux<PageViewSelector, PageViewActionsMap>(mapHooksToState, pageViewActionsMap);
