import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/reset-password',
	components: () => [import(/* webpackChunkName: 'resetPassword' */ './component')],
	render: ([ResetPassword]) => <ResetPassword />,
};
export default routes;
