import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/test-report',
	components: () => [import(/* webpackChunkName: 'testReport' */ './component')],
	render: ([TestReport]) => <TestReport />,
};
export default routes;
