/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'react';
import { createAction, handleActions, Action } from 'redux-actions';
import { nanoid } from 'nanoid';

import history from 'store/history';

import { openModal, ModalTypes } from 'models/modal';
import { toast } from 'models/toast';

import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { getItem, setItem } from 'util/storage';
import { omniNewRegistration } from 'util/omniEvent';
import { gtmEvent } from 'util/gtmEvent';
import pushHistory from 'util/pushHistory';
import { parseStateFromLineQuery } from 'util/line';

import { StorageKey } from 'enums/storageKey';

import { GetState, State as GlobalState } from './reducers';
import { signInSuccessCallBack } from './signin';
import { triggerMemberRegistrationByLineEvent } from './lineEvent';

const { PROXY } = process.env;

interface BasePayload {
	status: number;
	errorCode?: string | null;
}

interface Error {
	error: BasePayload;
}

interface GetLineIdTokenResponse {
	idToken: string;
}

interface GetLineMemberInfoResponse {
	email?: string;
	iconUser?: boolean;
	token?: string;
	socialiteToken?: string;
	userVerifiedStatus?: string;
}

interface GetLineNewMemberLinkResponse {
	token: string;
	lineId: string;
	userId: string;
}

interface GetLineOldMemberLinkResponse {
	key: string;
}

export const getLineNewMemberLink = createAction(
	'GET_LINE_NEW_MEMBER_LINK',
	(email: string) => async (dispatch: Dispatch<any>, getState: GetState) => {
		try {
			const socialiteToken = getItem(StorageKey.LINE_BIND);
			if (socialiteToken) {
				const { v1AuthLineLinkCreate } = api;
				const { status, data } = await v1AuthLineLinkCreate({
					email,
					key: '',
					socialite_token: socialiteToken,
				});
				const {
					discountEvents: { registrationEvent },
				} = getState();

				if (status === 200) {
					const { token, lineId, userId } = data?.data as GetLineNewMemberLinkResponse;
					omniNewRegistration(userId, '', lineId);
					if (token) {
						gtmEvent({
							event: 'sign_up',
							method: 'line',
						});
						dispatch(
							signInSuccessCallBack({
								token,
								message: '註冊成功',
								method: 'line',
							}),
						);
						if (registrationEvent.length > 0) {
							dispatch(openModal(ModalTypes.SignUpGiftModal));
						}
						return { status };
					}
					pushHistory(history, `/email-verify?email=${encodeURIComponent(email)}&type=signup`)
				}
				return { status };
			}
			return { status: 0 };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const getLineOldMemberLink = createAction(
	'GET_LINE_OLD_MEMBER_LINK',
	(email: string) => async (dispatch: Dispatch<any>) => {
		try {
			const socialiteToken = getItem(StorageKey.LINE_BIND);
			const verifyPasswordKey = getItem(StorageKey.LINE_VERIFY_PASSWORD);
			if (!socialiteToken || !verifyPasswordKey) {
				dispatch(openModal(ModalTypes.ErrorModal));
				return { status: 0 };
			}
			const { v1AuthLineLinkCreate } = api;
			const { status, data } = await v1AuthLineLinkCreate({
				email,
				key: verifyPasswordKey,
				socialite_token: socialiteToken,
			});
			if (status === 200) {
				const { token } = data?.data as GetLineNewMemberLinkResponse;
				if (token) {
					dispatch(
						signInSuccessCallBack({
							token,
							message: '帳戶綁定成功！',
							method: 'line',
						}),
					);
				}
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const getLineMemberInfo = createAction(
	'GET_LINE_MEMBER_INFO',
	() => async (dispatch: Dispatch<any>) => {
		try {
			const idToken = getItem(StorageKey.LINE_AUTH);
			if (!idToken) {
				dispatch(openModal(ModalTypes.ErrorModal));
				return { status: 0 };
			}
			const { v1AuthLineVerifyCreate } = api;
			const { status, data } = await v1AuthLineVerifyCreate({ id_token: idToken as string });
			const userInfo = data?.data as GetLineMemberInfoResponse;
			const stateDataFromQuery = parseStateFromLineQuery();

			if (status === 200 && userInfo !== null) {
				if (userInfo.token) {
					gtmEvent({
						event: 'login',
						method: 'line',
					});
					dispatch(
						signInSuccessCallBack({
							token: userInfo.token,
							message: '登入成功',
							method: 'line',
						}),
					);
					return { status };
				}
				// 無 access Token 情境:
				if (userInfo.socialiteToken) {
					setItem(StorageKey.LINE_BIND, userInfo.socialiteToken);
					if (userInfo.email) {
						if (userInfo.iconUser) {
							if (userInfo.userVerifiedStatus) {
								// 走綁定流程
								pushHistory(history, `/bind?email=${userInfo.email}`, stateDataFromQuery.query);
								return { status };
							}
							// 走信箱驗證流程這一動要調整 email 驗證來源為 LINE 後，驗證正確需要跳 「請重新綁定 LINE 帳號」
							pushHistory(history,
								`/email-verify?email=${encodeURIComponent(userInfo.email)}&type=line-bind`,
								stateDataFromQuery.query);
							return { status };
						}
						// 自動帶入 email 走新增會員流程
						pushHistory(history, `/new-member?email=${userInfo.email}`, stateDataFromQuery.query);
						return { status };
					}
					// 無 email 走新增會員流程
					pushHistory(history, `/new-member`, stateDataFromQuery.query);
				}
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const getLineIdToken = createAction(
	'GET_LINE_ID_TOKEN',
	(code: string) => async (dispatch: Dispatch<any>) => {
		try {
			if (PROXY === 'develop') {
				dispatch(getLineMemberInfo());
				return { status: 0 };
			}
			const { v1AuthLineGetTokenCreate } = api;
			const { status, data } = await v1AuthLineGetTokenCreate({ code });
			if (status === 200) {
				// 將 id_token 存在 localStorage 中
				setItem(StorageKey.LINE_AUTH, (data?.data as GetLineIdTokenResponse).idToken);
				dispatch(getLineMemberInfo());
				return { status };
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const verifyPassword = createAction(
	'VERIFY_PASSWORD',
	(email: string, password: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthVerifyPasswordCreate } = api;
			const { status, data } = await v1AuthVerifyPasswordCreate({ email, password });
			if (status === 200) {
				// 將密鑰 key 存在 localStorage 中
				setItem(StorageKey.LINE_VERIFY_PASSWORD, (data?.data as GetLineOldMemberLinkResponse).key);
				dispatch(getLineOldMemberLink(email));
				return { status };
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export const verifyLineBindEmailCode = createAction(
	'VERIFY_LINE_BIND_EMAIL_CODE',
	(email: string, code: string) => async (dispatch: Dispatch<any>) => {
		try {
			const { v1AuthVerifyEmailCodeCreate } = api;
			const { status } = await v1AuthVerifyEmailCodeCreate({ email, code, use_for: 'register' });
			if (status === 200) {
				const id = nanoid();
				dispatch(
					toast({
						id,
						message: '請重新綁定 LINE 帳號',
						type: 'success',
						color: 'green',
					}),
				);
				window.location.href = '/signup';
			}
			return { status };
		} catch (e) {
			return { status: (e as Error).error.status, errorCode: (e as Error).error.errorCode };
		}
	},
);

export interface State {
	loading: boolean;
	getLineIdTokenStatus: BasePayload;
	getLineMemberInfoStatus: BasePayload;
	getLineNewMemberLinkStatus: BasePayload;
	verifyPasswordStatus: BasePayload;
	getLineOldMemberLinkStatus: BasePayload;
	verifyLineBindEmailCodeStatus: BasePayload;
}

export const defaultState: State = {
	loading: false,
	getLineIdTokenStatus: {
		status: 0,
		errorCode: null,
	},
	getLineMemberInfoStatus: {
		status: 0,
		errorCode: null,
	},
	getLineNewMemberLinkStatus: {
		status: 0,
		errorCode: null,
	},
	verifyPasswordStatus: {
		status: 0,
		errorCode: null,
	},
	getLineOldMemberLinkStatus: {
		status: 0,
		errorCode: null,
	},
	verifyLineBindEmailCodeStatus: {
		status: 0,
		errorCode: null,
	},
};

export const reducer = {
	auth: handleActions<State, any>(
		{
			GET_LINE_ID_TOKEN_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_ID_TOKEN_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineMemberInfoStatus: action.payload,
				loading: false,
			}),
			GET_LINE_ID_TOKEN_REJECTED: state => ({
				...state,
				loading: false,
			}),
			GET_LINE_MEMBER_INFO_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_MEMBER_INFO_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineIdTokenStatus: action.payload,
				loading: false,
			}),
			GET_LINE_MEMBER_INFO_REJECTED: state => ({
				...state,
				loading: false,
			}),
			GET_LINE_NEW_MEMBER_LINK_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_NEW_MEMBER_LINK_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineNewMemberLinkStatus: action.payload,
				loading: false,
			}),
			GET_LINE_NEW_MEMBER_LINK_REJECTED: state => ({
				...state,
				loading: false,
			}),
			VERIFY_PASSWORD_PENDING: state => ({
				...state,
				loading: true,
			}),
			VERIFY_PASSWORD_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verifyPasswordStatus: action.payload,
				loading: false,
			}),
			VERIFY_PASSWORD_REJECTED: state => ({
				...state,
				loading: false,
			}),
			GET_LINE_OLD_MEMBER_LINK_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_LINE_OLD_MEMBER_LINK_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				getLineOldMemberLinkStatus: action.payload,
				loading: false,
			}),
			GET_LINE_OLD_MEMBER_LINK_REJECTED: state => ({
				...state,
				loading: false,
			}),
			VERIFY_LINE_BIND_EMAIL_CODE_PENDING: state => ({
				...state,
				loading: true,
			}),
			VERIFY_LINE_BIND_EMAIL_CODE_FULFILLED: (state, action: Action<BasePayload>) => ({
				...state,
				verifyLineBindEmailCodeStatus: action.payload,
				loading: false,
			}),
			VERIFY_LINE_BIND_EMAIL_CODE_REJECTED: state => ({
				...state,
				loading: false,
			}),
		},
		defaultState,
	),
};

const authActionsMap = {
	getLineIdToken,
	getLineMemberInfo,
	getLineNewMemberLink,
	verifyPassword,
	getLineOldMemberLink,
	verifyLineBindEmailCode,
};

const mapHooksToState = (state: GlobalState) => ({
	getLineIdTokenStatus: state.auth.getLineIdTokenStatus,
	getLineMemberInfoStatus: state.auth.getLineMemberInfoStatus,
	getLineNewMemberLinkStatus: state.auth.getLineNewMemberLinkStatus,
	verifyPasswordStatus: state.auth.verifyPasswordStatus,
	getLineOldMemberLinkStatus: state.auth.getLineOldMemberLinkStatus,
	verifyLineBindEmailCodeStatus: state.auth.verifyLineBindEmailCodeStatus,
});

type AuthSelector = ReturnType<typeof mapHooksToState>;
type AuthActionsMap = typeof authActionsMap;

export const useAuth = () =>
	useRedux<AuthSelector, AuthActionsMap>(mapHooksToState, authActionsMap);
