/**
 * 付款方式之列舉
 *
 * @export
 * @enum {number}
 */
export enum PaymentType {
	/**
	 * ATM轉帳
	 */
	CATHAY_ATM = 'CATHAY_ATM',

	/**
	 * 國泰金流
	 */
	CATHAY_CREDIT_CARD = 'CATHAY_CREDIT_CARD',

	/**
	 * LINE_PAY
	 */
	LINE_PAY = 'LINE_PAY',

	/**
	 * 貨到付款
	 */
	HOME_CASH = 'HOME_CASH',
}

export const getPaymentTypeText = (paymentType: PaymentType): string => {
	switch (paymentType) {
		case PaymentType.CATHAY_ATM:
			return 'ATM轉帳';
		case PaymentType.CATHAY_CREDIT_CARD:
			return '信用卡';
		case PaymentType.LINE_PAY:
			return 'LINE PAY';
		case PaymentType.HOME_CASH:
			return '貨到付款';
		default:
			return '';
	}
};
