/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';

import { api } from 'util/api';

import { getImageFromStorage } from 'util/hook/getImageFromStorage';

import {
	IndexSeriesResource,
	ProductResource,
	ProductSeriesResource,
	ProductSubSeriesResource,
} from 'util/api/swaggerApi/data-contracts';

import { VisibilityRuleType } from 'enums/visibilityRuleType';

import { State as GlobalState } from './reducers';

const defaulteProduct: ProductResource = {
	id: -1,
	name: 'name',
	briefDescription: '商品簡述',
	materialNo: '自建料號',
	internationalBarcode: '國際條碼',
	price: 100,
	memberPrice: 70,
	hashtag1: 'hashtag1',
	hashtag2: 'hashtag2',
	description: '商品特色描述',
	spec: '商品規格',
	notice: '注意事項',
	images: [],
	introduction: '商品介紹',
};

interface ProductsPayload {
	products: ProductResource[];
}

export const getProducts = createAction<Promise<ProductsPayload>>('GET_PRODUCTS', async () => {
	try {
		const { v1ProductsList } = api;
		const { data } = await v1ProductsList();
		const products = data?.data
			?.filter(product => product.currentVisibilityRule === VisibilityRuleType.ONLINE)
			.map((product: ProductResource) => ({
				...product,
				images: product?.images?.map(img => getImageFromStorage(img)),
			})) as ProductResource[];

		return { products };
	} catch (e) {
		return { products: [] };
	}
});

interface ProductPayload {
	product: ProductResource;
}

export const getProductById = createAction<Promise<ProductPayload>, number>(
	'GET_PRODUCT_BY_ID',
	async (id: number) => {
		try {
			const { v1ProductsDetail } = api;
			const { data } = await v1ProductsDetail(id);
			const product = {
				...data?.data,
				images: data?.data?.images?.map((img: string) => getImageFromStorage(img)),
				webIntroduction:
					data?.data?.webIntroduction && getImageFromStorage(data?.data?.webIntroduction),
				mobileIntroduction:
					data?.data?.mobileIntroduction && getImageFromStorage(data?.data?.mobileIntroduction),
				relateSelves: data?.data?.relateSelves?.map((p: ProductResource) => ({
					...p,
					images: p?.images?.map((img: string) => getImageFromStorage(img)),
				})),
			};

			return { product };
		} catch (e) {
			return { product: defaulteProduct };
		}
	},
);

export const removeSelectProduct = createAction('REMOVE_SELECT_PRODUCT');

interface ExclusiveSalePayload {
	exclusiveSale: ProductResource[];
}

export const getExclusiveSaleProducts = createAction<Promise<ExclusiveSalePayload>>(
	'GET_EXCLUSIVE_SALE_PRODUCTS',
	async () => {
		try {
			const { v1ExclusiveSalesList } = api;
			const { data } = await v1ExclusiveSalesList();

			const exclusiveSale = data?.data
				?.map((product: ProductResource) => ({
					...product,
					images: product?.images?.map(img => getImageFromStorage(img)),
				}))
				.filter(
					sale => sale.currentVisibilityRule === VisibilityRuleType.ONLINE,
				) as ProductResource[];

			return { exclusiveSale };
		} catch (e) {
			return { exclusiveSale: [] };
		}
	},
);

interface ProductSeriesPayload {
	subSeries: {
		[seriesId: number]: ProductSubSeriesResource[];
	};
	series: ProductSeriesResource[];
}

export const getProductSeriesList = createAction<Promise<ProductSeriesPayload>>(
	'GET_PRODUCT_SERIES_LIST',
	async () => {
		try {
			const { v1ProductSeriesList } = api;
			const { data } = await v1ProductSeriesList();

			const seriesResult: ProductSeriesResource[] = [];
			const subSeriesResult: { [seriesId: number]: ProductSubSeriesResource[] } = {};

			data?.data?.series
				?.filter(s => s.currentVisibilityRule === VisibilityRuleType.ONLINE)
				.forEach((s: ProductSeriesResource) => {
					s.mobileImage = s.mobileImage && getImageFromStorage(s.mobileImage);
					s.webImage = s.webImage && getImageFromStorage(s.webImage);
					s.subSeries = s.subSeries
						?.filter(subs => subs.currentVisibilityRule === VisibilityRuleType.ONLINE)
						.map(sub => {
							const newSub: ProductSubSeriesResource = {
								...sub,
								mobileImage: sub.mobileImage && getImageFromStorage(sub.mobileImage),
								webImage: sub.webImage && getImageFromStorage(sub.webImage),
								products: sub.products
									?.filter(ele => ele.currentVisibilityRule === VisibilityRuleType.ONLINE)
									?.map(p => ({
										...p,
										images: p?.images?.map((img: string) => getImageFromStorage(img)),
									})),
							};
							return newSub;
						});
					subSeriesResult[s.id as number] = s.subSeries!;
					seriesResult.push(s);
				});

			return { series: seriesResult, subSeries: subSeriesResult };
		} catch (e) {
			return { series: [], subSeries: [] };
		}
	},
);

interface IndexSeriesPayload {
	indexSeries: IndexSeriesResource[];
}

export const getIndexSeries = createAction<Promise<IndexSeriesPayload>>(
	'GET_INDEX_SERIES',
	async () => {
		try {
			const { v1IndexSeriesList } = api;
			const { data } = await v1IndexSeriesList();

			const indexSeries = data?.data
				?.map((product: IndexSeriesResource) => ({
					...product,
					indexImage: product.indexImage && getImageFromStorage(product.indexImage),
				}))
				.filter(
					product => product.currentVisibilityRule === VisibilityRuleType.ONLINE,
				) as IndexSeriesResource[];

			return { indexSeries };
		} catch (e) {
			return { indexSeries: [] };
		}
	},
);

// For Global State usage
export interface State {
	loading: boolean;
	products: ProductResource[];
	exclusiveSale: ProductResource[];
	select: ProductResource;
	series: ProductSeriesResource[];
	subSeries: {
		[seriesId: number]: ProductSubSeriesResource[];
	};
	indexSeries: IndexSeriesResource[];
}

export const defaultState: State = {
	loading: false,
	products: [],
	exclusiveSale: [],
	select: {},
	series: [],
	subSeries: {},
	indexSeries: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	products: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_PRODUCTS_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_PRODUCTS_FULFILLED: (state, action: Action<ProductsPayload>) => ({
				...state,
				products: [...action.payload.products],
				loading: false,
			}),
			GET_PRODUCT_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),
			GET_PRODUCT_BY_ID_FULFILLED: (state, action: Action<ProductPayload>) => ({
				...state,
				select: action.payload.product,
				loading: false,
			}),
			GET_EXCLUSIVE_SALE_PRODUCTS_FULFILLED: (state, action: Action<ExclusiveSalePayload>) => ({
				...state,
				exclusiveSale: action.payload.exclusiveSale,
				loading: false,
			}),
			GET_PRODUCT_SERIES_LIST_FULFILLED: (state, action: Action<ProductSeriesPayload>) => ({
				...state,
				series: action.payload.series,
				subSeries: action.payload.subSeries,
				loading: false,
			}),
			GET_INDEX_SERIES_FULFILLED: (state, action: Action<IndexSeriesPayload>) => ({
				...state,
				indexSeries: action.payload.indexSeries,
				loading: false,
			}),
			REMOVE_SELECT_PRODUCT: state => ({
				...state,
				select: {},
			}),
		},
		defaultState,
	),
};

const productsActionsMap = {
	getProducts,
	removeSelectProduct,
};

const mapHooksToState = (state: GlobalState) => ({
	products: state.products.products,
	exclusiveSale: state.products.exclusiveSale,
	select: state.products.select,
	series: state.products.series,
	subSeries: state.products.subSeries,
	indexSeries: state.products.indexSeries,
	loading: state.products.loading,
});

type productsSelector = ReturnType<typeof mapHooksToState>;
type productsActionsMap = typeof productsActionsMap;

export const useProducts = () =>
	useRedux<productsSelector, productsActionsMap>(mapHooksToState, productsActionsMap);
