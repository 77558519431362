export enum DeliveryTimeType {
	/**
	 * 未指定
	 */
	NONE = 'NONE',

	/**
	 * 13點前
	 */
	BEFORE_13_HOURS = 'BEFORE_13_HOURS',

	/**
	 * 14點後
	 */
	AFTER_14_HOURS = 'AFTER_14_HOURS',
}

export const getDeliveryTimeText = (deliveryTimeType: DeliveryTimeType): string => {
	switch (deliveryTimeType) {
		case DeliveryTimeType.NONE:
			return '不指定';
		case DeliveryTimeType.BEFORE_13_HOURS:
			return '13 時前';
		case DeliveryTimeType.AFTER_14_HOURS:
			return '14-18 時';
		default:
			return '';
	}
};
