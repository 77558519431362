/* eslint-disable no-mixed-operators */
import { useEffect, useState } from 'react';
import { useMessageBar } from 'models/messageBar';
import useMedia from './useMedia';

const useToastTop = () => {
	const media = useMedia();
	const [{ messageBars }] = useMessageBar();
	const [toastTopWithoutMargin, setToastTopWithoutMargin] = useState(40 * messageBars.length + 68);
	const [toastTop, setToastTop] = useState(40 * messageBars.length + 68 + 16);

	useEffect(() => {
		const calculateToastTop = () => {
			let messageBarHeight = messageBars.length * 40;
			// 84px 為 header 高度; 68px 為 mobile header 高度
			const headerHeight = media === 'desktop' ? 84 : 68;

			// 因為手機版，scroll 時會隱藏 messageBar，故需調整 toast 高度
			if (media === 'mobile' && window.scrollY > 0) {
				messageBarHeight = 0;
			}

			// 16px 為 toast 的 margin-top
			setToastTop(messageBarHeight + headerHeight + 16);
			setToastTopWithoutMargin(messageBarHeight + headerHeight);
		};
		calculateToastTop();
		// 因為 messageBar 在 mobile scroll往下會收起，故需調整 toast 高度
		window.addEventListener('scroll', calculateToastTop);
		return () => {
			window.removeEventListener('scroll', calculateToastTop);
		};
	}, [messageBars, media]);
	return [{ toastTop, toastTopWithoutMargin }];
};

export default useToastTop;
