export const formatTime = (time: string): string => {
	if (!time) return '';
	const date = new Date(time.replaceAll('.', '-').replace(' ', 'T'));

	const year = date.getFullYear();
	let month: string | number = date.getMonth() + 1;
	let day: string | number = date.getDate();
	const hours = date.getHours();
	const minutes = date.getMinutes();

	if (month < 10) {
		month = `0${month}`;
	}
	if (day < 10) {
		day = `0${day}`;
	}

	const formattedDateTime = `${year}年${month}月${day}日 ${hours}:${
		minutes < 10 ? '0' : ''
	}${minutes}`;

	return formattedDateTime;
};
