import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';


const routes: CustomRoute = {
	path: '/terms-of-service',
	components: () => [import(/* webpackChunkName: 'termsOfService' */ './component')],
	render: ([TermsOfService]) => <TermsOfService />,
};
export default routes;
