/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useProducts } from 'models/products';
import {
	DiscountLabelResource,
	GiftResource,
	ProductCombinationResource,
	ProductResource,
} from 'util/api/swaggerApi/data-contracts';
import { ProductState } from 'components/molecules/CartTable';
import { useCombinations } from 'models/combination';
import { useStock } from 'models/stock';
import { useCart } from 'models/cart';
import { CartData } from 'util/storeProductQuantity';

export interface ProductData {
	id: number;
	image: string;
	productName: string;
	briefDescription: string;
	unitPrice: number;
	count: number;
	salePrice: number;
	price?: number;
	productState: ProductState;
	max: number;
	isCombination: boolean;
	productLabel?: string;
	discountTitle?: string;
	volume: number;
	discount?: DiscountLabelResource[];
	materialNo?: string;
}

export type InStorageProductData = {
	combination: {
		[key: string]: number;
	};
	normal: {
		[key: string]: number;
	};
};

const mapGiftProductState = (productLabel: string|undefined) => {
	switch (productLabel) {
		case '滿額贈':
			return 'thresholdReward';
		case '首購禮':
			return 'firstPurchaseGift';
		default:
			return 'freebie';
	}
};

export const mapGiftResourceToProductData = (
	resource: GiftResource,
	stock: Map<number, number>,
	giftDataFromApi: Record<string, number> | null,
	lable?: { productLabel: string; discountTitle: string },
): ProductData | undefined => {
	let productState: ProductState = mapGiftProductState(lable?.productLabel);
	let count = 1;

	if (
		resource.id &&
		(stock.get(resource.id) || stock.get(resource.id) === 0) &&
		(stock.get(resource.id) || 0) <= 0
	) {
		productState = 'giveOut';
		count = 0;
	}
	return {
		id: resource.id || 0,
		image: resource.image || '',
		productName: resource.name || '',
		briefDescription: resource.briefDescription || '',
		unitPrice: resource.price || 0,
		count,
		salePrice: 0,
		productState,
		isCombination: false,
		max: giftDataFromApi
			? (stock.get(resource.id || 0) ?? 0) + giftDataFromApi[resource.id || 0]
			: stock.get(resource.id || 0) ?? 0,
		productLabel: lable?.productLabel || '',
		discountTitle: lable?.discountTitle || '',
		volume: resource.volume || 0,
	};
};

const updateProductStateAndCount = (count: number, max: number) => {
	let productState = 'normal';
	let updatedCount = count;

	if (max <= 0) {
		productState = 'soldOut';
		updatedCount = 0;
	} else if (count >= max) {
		productState = 'purchaseLimit';
		updatedCount = max;
	}

	return { productState, updatedCount };
};

const mapProductResourceToProductData = (
	resource: ProductResource,
	count: number,
	stock: Map<number, number>,
	apiData: CartData | null,
): ProductData | undefined => {
	const MaxSafe = Number.MAX_SAFE_INTEGER;
	const countFromApi = apiData?.normal[resource.id!] ?? 0;
	const stockNum = stock.get(resource.id || 0) || 0;

	const max = Math.min(stockNum + countFromApi, resource.purchaseLimit || MaxSafe);
	const { productState, updatedCount } = updateProductStateAndCount(count, max);

	return {
		id: resource.id || 0,
		image: resource.images ? resource.images[0] : '',
		productName: resource.name || '',
		briefDescription: resource.briefDescription || '',
		unitPrice: resource.eventPrice || resource.memberPrice || 0,
		count: updatedCount,
		salePrice: resource.eventPrice! * updatedCount || resource.memberPrice! * updatedCount || 0,
		productState: productState as ProductState,
		isCombination: false,
		max,
		productLabel: resource.discounts?.length ? '優惠活動' : '',
		discount: resource.discounts,
		volume: resource.volume || 0,
		materialNo: resource.materialNo || '',
	};
};

const mapProductCombinationResourceToProductData = (
	resource: ProductCombinationResource,
	count: number,
	stock: Map<number, number>,
	apiData: CartData | null,
): ProductData | undefined => {
	const MaxSafe = Number.MAX_SAFE_INTEGER;
	const countFromApi = apiData?.combination[resource.id!] ?? 0;
	const maxStock = resource.products?.reduce((acc, cur) => {
		const productStock = stock.get(cur) ?? 0;
		return Math.min(acc, productStock + countFromApi);
	}, MaxSafe);

	const max = Math.min(maxStock || 0, resource.purchaseLimit || MaxSafe);

	const { productState, updatedCount } = updateProductStateAndCount(count, max);

	const item: Omit<ProductData, 'max'> = {
		id: resource.id || 0,
		image: resource.images ? resource.images[0] : '',
		productName: resource.name || '',
		briefDescription: resource.briefDescription || '',
		unitPrice: resource.eventPrice || resource.memberPrice || 0,
		count: updatedCount,
		salePrice: resource.eventPrice! * updatedCount || resource.memberPrice! * updatedCount || 0,
		productState: productState as ProductState,
		isCombination: true,
		productLabel: resource.discounts?.length ? '優惠活動' : '',
		discount: resource.discounts,
		volume: resource.volume || 0,
		materialNo: resource.materialNo || '',
	};

	return {
		...item,
		max: max ?? 0,
	};
};

export const useProductsForCart = () => {
	const [{ cartData: localProducts, apiData }, { storeCartProduct }] = useCart();
	// apiData 不為 null 表示資料由暫存訂單來，因圈存的原因，庫存數 + 已選購數才是該客戶的可選購庫存上限
	const [{ products }] = useProducts();
	const [{ combinations }] = useCombinations();
	const [{ allProductStock }] = useStock();

	return useMemo(() => {
		const productMap = new Map<number, ProductResource>(products.map(p => [p.id || 0, p]));

		const combinationMap = new Map<number, ProductCombinationResource>(
			combinations.map(c => [c.id || 0, c]),
		);

		const productsData = Object.keys(localProducts?.normal ?? {}).map(id => {
			const product = productMap.get(Number(id));
			if (!product) {
				return null;
			}

			const count = localProducts?.normal?.[id] ?? 0;
			const result = mapProductResourceToProductData(product, count, allProductStock, apiData);
			if (result?.id && result?.count !== count) {
				storeCartProduct(result.id, result.count, false);
			}
			return result;
		});

		const combinationsData = Object.keys(localProducts?.combination ?? {}).map(id => {
			const combination = combinationMap.get(Number(id));
			if (!combination) {
				return null;
			}
			const count = localProducts?.combination?.[id] ?? 0;
			const result = mapProductCombinationResourceToProductData(
				combination,
				count,
				allProductStock,
				apiData,
			);
			if (result?.id && result?.count !== count) {
				storeCartProduct(result.id, result.count, true);
			}
			return result;
		});

		return [...productsData, ...combinationsData].filter(Boolean) as ProductData[];
	}, [localProducts, products, combinations, allProductStock, apiData]);
};
