/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Dispatch } from 'redux';
import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { api } from 'util/api';
import { getImageFromStorage } from 'util/hook/getImageFromStorage';
import {
	DiscountEventResource,
	FirstPurchaseEventResource,
	FreeShippingEventResource,
	RegistrationCouponEventResource,
	ThresholdRewardResource,
} from 'util/api/swaggerApi/data-contracts';

import { VisibilityRuleType } from 'enums/visibilityRuleType';
import { ProductApplicableType } from 'enums/productApplicableType';

import { getStartEvent } from 'util/getStartEvent';
import { GetState, State as GlobalState } from './reducers';
import { getProducts } from './products';
import { getProductCombinations } from './combination';

interface DiscountEventsPayload {
	discountEventList: DiscountEventResource[];
	startEventList: DiscountEventResource[];
}

export const getDiscountEvents = createAction<Promise<DiscountEventsPayload>>(
	'GET_DISCOUNT_EVENTS',
	async () => {
		try {
			const { v1DiscountEventsList } = api;
			const { data } = await v1DiscountEventsList({});
			const list = data?.data || [];

			const startEventList = list.filter(event =>
				getStartEvent(event?.eventBeginAt || '', event?.eventEndAt || ''),
			);
			// discountEventList為已發布的折扣活動，startEventList則是活動已開始
			return {
				discountEventList: list.filter(
					discount => discount.currentVisibilityRule === VisibilityRuleType.ONLINE,
				),
				startEventList,
			};
		} catch (e) {
			return { discountEventList: [], startEventList: [] };
		}
	},
);

interface SelectDiscountEventPayload {
	selectDiscountEvent: DiscountEventResource;
}

type Flatten<T extends any[]> = T extends any[] ? T[number] : never;

export const getDiscountEventById = createAction<
	(dispatch: Dispatch, getState: GetState) => Promise<SelectDiscountEventPayload>,
	number
>('GET_DISCOUNT_EVENT_BY_ID', id => async (dispatch: Dispatch, getState: GetState) => {
	try {
		const { v1DiscountEventsDetail } = api;
		const { data } = await v1DiscountEventsDetail(id);

		let newProducts = data?.data?.products?.map(p => ({
			...p,
			images: p?.images?.map(img => getImageFromStorage(img)),
		}));

		let newCombinations = data?.data?.combinations?.map(
			(p: Flatten<Exclude<DiscountEventResource['combinations'], undefined>>) => ({
				...p,
				images: p?.images?.map(img => getImageFromStorage(img)),
			}),
		);

		if (data?.data?.applicable === ProductApplicableType.ALL_PRODUCTS) {
			await dispatch(getProducts());
			await dispatch(getProductCombinations());
			const {
				products: { products },
				combination: { combinations },
			} = getState();
			newProducts = [...products] as any;
			newCombinations = [...combinations];
		}

		const selectDiscountEvent = {
			...data?.data,
			webImage: data?.data?.webImage && getImageFromStorage(data?.data?.webImage),
			mobileImage: data?.data?.mobileImage && getImageFromStorage(data.data.mobileImage),
			products: newProducts,
			combinations: newCombinations,
		};
		return { selectDiscountEvent };
	} catch (e) {
		return { selectDiscountEvent: {} };
	}
});

interface ThresholdRewardsListPayload {
	thresholdRewardsList: ThresholdRewardResource[];
}

export const getThresholdRewardsList = createAction<Promise<ThresholdRewardsListPayload>>(
	'GET_THRESHOLD_REWARDS_LIST',
	async () => {
		try {
			const { v1ThresholdRewardsList } = api;
			const { data } = await v1ThresholdRewardsList({});
			const result = data?.data
				?.filter(item => item.currentVisibilityRule === VisibilityRuleType.ONLINE)
				.map((item: ThresholdRewardResource) => ({
					...item,
					gifts: item?.gifts?.map((gift: any) => ({
						...gift,
						image: getImageFromStorage(gift.image),
					})),
				}));
			return { thresholdRewardsList: result || [] };
		} catch (e) {
			return { thresholdRewardsList: [] };
		}
	},
);

interface FreeShippingEventListPayload {
	freeShippingEventList: FreeShippingEventResource[];
}

export const getFreeShippingEventList = createAction<Promise<FreeShippingEventListPayload>>(
	'GET_FREE_SHIPPING_EVENT_LIST',
	async () => {
		try {
			const { v1FreeShippingEventsList } = api;
			const { data } = await v1FreeShippingEventsList({});
			return {
				freeShippingEventList:
					data?.data?.filter(item => item.currentVisibilityRule === VisibilityRuleType.ONLINE) ||
					[],
			};
		} catch (e) {
			return { freeShippingEventList: [] };
		}
	},
);

interface FirstPurchaseEventListPayload {
	firstPurchaseEventList: FirstPurchaseEventResource[];
}

export const getFirstPurchaseEvents = createAction('GET_FIRST_PURCHASE_EVENTS', async () => {
	try {
		const { v1FirstPurchaseEventsList } = api;
		const { data } = await v1FirstPurchaseEventsList({});
		return {
			firstPurchaseEventList:
				data?.data
					?.filter(event => getStartEvent(event.eventBeginAt || '', event.eventEndAt || ''))
					.map(event => ({
						...event,
						gifts: event?.gifts?.map((gift: any) => ({
							...gift,
							image: getImageFromStorage(gift.image),
						})),
					})) || [],
		};
	} catch (e) {
		return { firstPurchaseEventList: [] };
	}
});

interface RegistrationCouponEventPayload {
	registrationEvent: RegistrationCouponEventResource[];
}

export const getRegistrationEvent = createAction('GET_REGISTRATION_EVENT', async () => {
	try {
		const { v1RegistrationCouponEventsList } = api;
		const { data } = await v1RegistrationCouponEventsList();
		return {
			registrationEvent: data?.data || {},
		};
	} catch (e) {
		return { registrationEvent: {} };
	}
});

// For Global State usage
export interface State {
	loading: boolean;
	discountEventList: DiscountEventResource[];
	selectDiscountEvent: DiscountEventResource;
	thresholdRewardsList: ThresholdRewardResource[];
	freeShippingEventList: FreeShippingEventResource[];
	startEventList: DiscountEventResource[];
	firstPurchaseEventList: FirstPurchaseEventResource[];
	registrationEvent: RegistrationCouponEventResource[];
}

// discountEventList為已發布的折扣活動(其餘顯示)，startEventList則是活動已開始(購物車)
export const defaultState: State = {
	loading: false,
	discountEventList: [],
	selectDiscountEvent: {},
	thresholdRewardsList: [],
	freeShippingEventList: [],
	startEventList: [],
	firstPurchaseEventList: [],
	registrationEvent: [],
};

export const reducer = {
	// Workaround: HandleActions 目前定義無法支援多種 action 形式
	discountEvents: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_DISCOUNT_EVENTS_FULFILLED: (state, action: Action<DiscountEventsPayload>) => ({
				...state,
				discountEventList: action.payload.discountEventList,
				startEventList: action.payload.startEventList,
				loading: false,
			}),
			GET_DISCOUNT_EVENT_BY_ID_FULFILLED: (state, action: Action<SelectDiscountEventPayload>) => ({
				...state,
				selectDiscountEvent: action.payload.selectDiscountEvent,
				loading: false,
			}),
			GET_THRESHOLD_REWARDS_LIST_FULFILLED: (
				state,
				action: Action<ThresholdRewardsListPayload>,
			) => ({
				...state,
				thresholdRewardsList: action.payload.thresholdRewardsList,
				loading: false,
			}),
			GET_FREE_SHIPPING_EVENT_LIST_FULFILLED: (
				state,
				action: Action<FreeShippingEventListPayload>,
			) => ({
				...state,
				freeShippingEventList: action.payload.freeShippingEventList,
				loading: false,
			}),
			GET_FIRST_PURCHASE_EVENTS_FULFILLED: (
				state,
				action: Action<FirstPurchaseEventListPayload>,
			) => ({
				...state,
				firstPurchaseEventList: action.payload.firstPurchaseEventList,
				loading: false,
			}),
			GET_REGISTRATION_EVENT_FULFILLED: (
				state,
				action: Action<RegistrationCouponEventPayload>,
			) => ({
				...state,
				registrationEvent: action.payload.registrationEvent,
				loading: false,
			}),
		},
		defaultState,
	),
};

const discountActionsMap = {
	getDiscountEvents,
	getDiscountEventById,
	getThresholdRewardsList,
	getFreeShippingEventList,
	getFirstPurchaseEvents,
	getRegistrationEvent,
};

const mapHooksToState = (state: GlobalState) => ({
	discountEventList: state.discountEvents.discountEventList,
	selectDiscountEvent: state.discountEvents.selectDiscountEvent,
	thresholdRewardsList: state.discountEvents.thresholdRewardsList,
	freeShippingEventList: state.discountEvents.freeShippingEventList,
	startEventList: state.discountEvents.startEventList,
	firstPurchaseEventList: state.discountEvents.firstPurchaseEventList,
	registrationEvent: state.discountEvents.registrationEvent,
});

type DiscountSelector = ReturnType<typeof mapHooksToState>;
type DiscountActionsMap = typeof discountActionsMap;

export const useDiscountEvent = () =>
	useRedux<DiscountSelector, DiscountActionsMap>(mapHooksToState, discountActionsMap);
