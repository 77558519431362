/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';

import { StorageKey } from 'enums/storageKey';

import { CustomRoute } from 'util/hook/useRouter';

import { getMessageBar } from 'models/messageBar';
import { getFlashSales } from 'models/flashSales';
import { getVideo, initYouTubeIframeAPI } from 'models/vedio';
import { getBanners } from 'models/banner';
import {
	getExclusiveSaleProducts,
	getIndexSeries,
	getProductSeriesList,
	getProducts,
} from 'models/products';
import { getNewsCategories, getNewsList } from 'models/blog';
import { getCombinationDiscounts, getProductCombinations } from 'models/combination';
import { getNewReleases } from 'models/newRelease';
import { getCouponsList } from 'models/coupon';
import { getDiscountEvents, getRegistrationEvent } from 'models/discountEvent';
import { getAllProductStock } from 'models/stock';
import { getUserInfo, setEcUserToken } from 'models/member';

import { googleTagManager } from 'util/adTagScript.js';
import { getEcUserByStorage, getItem, removeItem, setEcUserByStorage } from 'util/storage';

import { setCartData } from 'models/cart';
import { gtmEvent } from 'util/gtmEvent';
import { titleChange } from 'models/pageView';
import { parseQueryString } from 'util/parseQueryString';
import { setCookie } from 'util/handleCookies';
import ProductsRoute from './Products';
import CheckoutRoutes from './Checkout';
import SignupRoute from './Signup';
import LineAuthRoute from './LineAuth';
import SigninRoute from './Signin';
import MemberCentreRoutes from './MemberCentre';
import EmailVerifyRoute from './EmailVerify';
import ForgotPasswordRoute from './ForgotPassword';
import ResetPasswordRoute from './ResetPassword';
import BindRoute from './Bind';
import NewMemberRoute from './NewMember';
import BlogRoute from './Blog';
import CartRoutes from './Cart';
import MemberOrderDetialRoutes from './MemberOrderDetail';
import RoadToGenderEqualityRoutes from './RoadToGenderEquality';
import AboutUsRoutes from './AboutUs';
import TermsOfServiceRoute from './TermsOfService';
import PrivacyStatementRoute from './PrivacyStatement';
import TestReportRoute from './TestReport';
import RedirectRoute from './Redirect';
import CouponReceiveRoute from './CouponReceive';
import MemberBenefitsRoute from './MemberBenefits';
import ShippingMethodRoute from './ShippingMethod';
import FrequentlyAskedQuestionsRoute from './FrequentlyAskedQuestions';

let isFirstInit = true;

/**
 * 路由定義檔
 */
export const ROUTE_PATHS = {
	home: '/',

	/**
	 * 部落格
	 */
	blog: 'blog',

	/**
	 * 購物車
	 */
	cart: 'cart',

	/**
	 * 結帳頁
	 */
	checkout: 'checkout',

	/**
	 * 註冊
	 */
	signup: 'signup',

	/**
	 * 登入
	 */
	signin: 'signin',

	/**
	 * 會員中心
	 */
	memberCentre: 'member-centre',

	/**
	 * 訂單明細
	 */
	memberOrderDetail: 'member-order-detail',

	/**
	 * 領取優惠券
	 */
	couponReceive: 'coupon-receive',

	/**
	 * 會員服務條款
	 */
	termsOfService: 'terms-of-service',

	/**
	 * 隱私權聲明
	 */
	privacyStatement: 'privacy-statement',
};

const childrenHomeRoute: CustomRoute = {
	path: '',
	components: () => [import(/* webpackChunkName: 'home' */ './Home')],
	render: ([Home]) => <Home />,
	onEnter: async ({ next, store }) => {
		store.dispatch(getFlashSales());
		store.dispatch(getVideo());
		store.dispatch(getBanners());
		store.dispatch(getExclusiveSaleProducts());
		store.dispatch(getIndexSeries());
		store.dispatch(getCouponsList());
		store.dispatch(getNewsCategories());
		store.dispatch(getNewsList());

		const {
			pageView: { title },
		} = store.getState();
		store.dispatch(titleChange(title));

		const children = await next();
		return children;
	},
};

const routes: CustomRoute = {
	path: '/',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ next, store }) => {
		/** 手機版 Line pay 進入結帳程序時，會另開視窗，若使用者沒有勾選記住我功能，會導致登入狀態無法同步
		所以在進入首頁時，會先將暫存的登入資訊同步至正式的登入資訊
		同步後，將暫存的登入資訊刪除 **/
		const ecUserTokenTemp = getItem(StorageKey.EC_USER_TEMP) || '';
		if (ecUserTokenTemp) {
			setEcUserByStorage(ecUserTokenTemp);
		}
		removeItem(StorageKey.EC_USER_TEMP);
		const ecUserToken = getEcUserByStorage();
		await store.dispatch(setEcUserToken(ecUserToken));

		if (parseQueryString().ecid) {
			setCookie('ecid', parseQueryString().ecid, 1);
		}

		store.dispatch(getProducts());
		store.dispatch(getProductCombinations());

		await store.dispatch(getAllProductStock([]));

		store.dispatch(getMessageBar());
		await store.dispatch(getProductSeriesList());
		store.dispatch(getCombinationDiscounts());
		store.dispatch(getNewReleases());
		store.dispatch(getDiscountEvents());
		store.dispatch(setCartData());
		store.dispatch(getRegistrationEvent());
		/* for cart in navbar */

		googleTagManager(process.env.GTM_ID);

		if (isFirstInit) {
			isFirstInit = false;
			store.dispatch(initYouTubeIframeAPI());
		}
		// bingTag(process.env.BING_ID);
		// dcardTag(process.env.DCARD_ID);
		// fbTag();
		// Fbq(process.env.FACEBOOK_ID);
		// gTag(process.env.GOOGLE_ADS_ID); // google ads
		// gTag(process.env.GA_ID); // GA4
		// tiktokTag(process.env.TIKTOK_ID);
		// lineTag();
		// lt(process.env.LINE_ADS_ID);
		if (ecUserToken) {
			await store.dispatch(getUserInfo());
			const {
				member: {
					userInfo: { id: userId },
				},
			} = store.getState();
			gtmEvent({ user_id: String(userId) });
		}

		// Omni PageView Beacon
		(window as unknown as Window).i13nData = {
			tagId: process.env.OMNI_TID || '',
			uid: '', // User ID is required if user has login
		};

		const children = await next();
		return children;
	},
	children: [
		ProductsRoute,
		childrenHomeRoute,
		CheckoutRoutes,
		SignupRoute,
		LineAuthRoute,
		MemberCentreRoutes,
		SigninRoute,
		EmailVerifyRoute,
		ForgotPasswordRoute,
		ResetPasswordRoute,
		BindRoute,
		NewMemberRoute,
		BlogRoute,
		CartRoutes,
		MemberOrderDetialRoutes,
		RoadToGenderEqualityRoutes,
		AboutUsRoutes,
		TermsOfServiceRoute,
		PrivacyStatementRoute,
		TestReportRoute,
		RedirectRoute,
		CouponReceiveRoute,
		MemberBenefitsRoute,
		ShippingMethodRoute,
		FrequentlyAskedQuestionsRoute,
	],
};

export default routes;
