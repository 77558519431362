import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

const routes: CustomRoute = {
	path: '/redirect',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'redirect' */ './component')],
			render: ([Redirect]) => <Redirect />,
			onEnter: async ({ next }) => {
				const children = await next();
				return children;
			},
		},
	],
};

export default routes;
