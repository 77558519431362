import React from 'react';
import classnames from 'classnames';

import Link, { LinkType } from 'components/atoms/Link';

import useMedia from 'util/hook/useMedia';

import {
	officalLineLink,
	officalFbLink,
	officalIGLink,
	officalRecruitmentLink,
	telPhone,
	fillFormLink,
} from 'routes/Home/config';

import Logo from 'images/ci/logo-white.png';
import Facebook from 'images/icon/social-fb.inline.svg';
import Instagram from 'images/icon/social-ig.inline.svg';
import Line from 'images/icon/social-line.inline.svg';
import PageTop from 'images/icon/page-top.inline.svg';
import LinkTo from 'images/icon/linkto.inline.svg';
import { gtmCustomClick } from 'util/gtmEvent';
import styles from './index.module.css';

export interface FooterItemsProperty {
	title: string;
	data: { item: string; link?: string; linkType?: LinkType; Icon?: string; onClick?: () => void }[];
	className?: string;
}

const FooterItems: React.FC<FooterItemsProperty> = ({ title, data, className }) => (
	<div className={classnames(styles.footerItems, className)}>
		<p className={styles.title}>{title}</p>
		{data.map(itemData => {
			const { Icon } = itemData;
			return (
				<Link
					className={styles.item}
					key={itemData.item}
					to={itemData.link}
					type={itemData.linkType}
					onClick={itemData.onClick}
				>
					{Icon && <Icon />}
					{itemData.item}
				</Link>
			);
		})}
	</div>
);

const PageTopButton = () => {
	const moveToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<button type="button" className={styles.pageTopButton} onClick={moveToTop}>
			<PageTop />
			PAGE TOP
		</button>
	);
};

interface FooterProperty {
	className?: string;
}

const Footer: React.FC<FooterProperty> = ({ className }) => {
	const media = useMedia();
	const openLink = (link: string) => {
		window.open(link, '_blank');
	};
	return (
		<footer className={classnames(styles.footer, className)}>
			<div className={styles.wrapper}>
				<div className={styles.logoContainer}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.itemsContainer}>
					{/* 顧客服務 */}
					<FooterItems
						title="顧客服務"
						data={[
							{ item: '會員權益', link: '/member-benefits' },
							{ item: '運送方式', link: '/shipping-method' },
							{ item: '常見問答', link: '/faq' },
							{
								item: '聯絡我們',
								linkType: 'external',
								link: fillFormLink,
								onClick: () => {
									gtmCustomClick(fillFormLink, '聯絡我們');
								},
							},
						]}
					/>
					{/* 關於愛康 */}
					<FooterItems
						title="關於愛康"
						data={[
							{ item: '品牌價值', link: '/about-us?tab=0' },
							{ item: '測試報告書', link: '/test-report' },
							{ item: '銷售據點', link: '/about-us?tab=1' },
							{ item: '企業招募', linkType: 'external', link: officalRecruitmentLink },
						]}
					/>
					{/* Follow Us */}
					<div className={classnames(styles.footerItems, styles.follow)}>
						<p className={styles.title}>Follow Us</p>
						<div className={styles.socials}>
							<div
								onKeyDown={() => {}}
								role="button"
								tabIndex={-1}
								className={classnames(styles.item, styles.social)}
								onClick={() => {
									openLink(officalFbLink);
									gtmCustomClick(officalFbLink, 'Facebook');
								}}
							>
								<Link className={styles.socialBtn}>
									<Facebook />
								</Link>
								{media === 'desktop' && 'Facebook'}
							</div>
							<div
								onKeyDown={() => {}}
								role="button"
								tabIndex={-1}
								className={classnames(styles.item, styles.social)}
								onClick={() => {
									openLink(officalIGLink);
									gtmCustomClick(officalIGLink, 'Instagram');
								}}
							>
								<Link className={styles.socialBtn}>
									<Instagram />
								</Link>
								{media === 'desktop' && 'Instagram'}
							</div>
							<div
								onKeyDown={() => {}}
								role="button"
								tabIndex={-1}
								className={classnames(styles.item, styles.social)}
								onClick={() => {
									openLink(officalLineLink);
									gtmCustomClick(officalLineLink, 'LINE');
								}}
							>
								<Link className={styles.socialBtn}>
									<Line />
								</Link>
								{media === 'desktop' && 'LINE'}
							</div>
						</div>
					</div>
					{/* 服務時間 */}
					<FooterItems
						title="服務時間"
						data={[{ item: '週一至週五' }, { item: '08:30-12:00' }, { item: '13:00-17:30' }]}
						className={styles.service}
					/>
					{/* 客服電話 */}
					<div className={classnames(styles.footerItems, styles.phone)}>
						<p className={styles.title}>客服電話</p>
						<a
							href={`tel:${telPhone}`}
							className={classnames(styles.item, styles.callTo)}
							onClick={() => gtmCustomClick(telPhone, '客服電話')}
						>
							0800-889-933
							{media === 'mobile' && <LinkTo />}
						</a>
					</div>
				</div>
			</div>
			<div className={styles.bottomContainer}>
				<div className={styles.links}>
					<Link to="/terms-of-service">會員服務條款</Link>
					<span>|</span>
					<Link to="/privacy-statement">隱私權聲明</Link>
				</div>
				<p className={styles.copyright}>© ICON {new Date().getFullYear()} 愛康生物科技有限公司｜統一編號 28558273</p>
			</div>
			<PageTopButton />
		</footer>
	);
};

export default Footer;
