import React, { useState, useRef, useEffect } from 'react';
import useOnClickOutside from 'use-onclickoutside';

export interface MainComponentProperty {
	isOpen: boolean;
}

export interface PanelComponentProperty {
	onClosePanel: () => void;
}

interface DropDownProperty {
	defaultOpen?: boolean;
	renderMainComponent: (props: MainComponentProperty) => JSX.Element;
	renderPanelComponent: (props: PanelComponentProperty) => JSX.Element;
	open?: boolean;
	className?: string;
	autoClose?: boolean;
}

const DropDown: React.FC<DropDownProperty> = ({
	defaultOpen = false,
	renderMainComponent,
	renderPanelComponent,
	open,
	className,
	autoClose = true,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(defaultOpen);
	const mainRef = useRef<HTMLButtonElement>(null);
	const panelRef = useRef(null);

	// 點擊 dropdown 外部時關閉 dropdown
	useOnClickOutside(panelRef, (e: Event) => {
		const target = e.target as HTMLElement;
		if (!autoClose) {
			return;
		}
		if (target !== mainRef.current && !mainRef.current?.contains(target)) {
			setIsDropdownOpen(false);
		}
	});

	const handleToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	useEffect(() => {
		setIsDropdownOpen(open as boolean);
	}, [open]);

	return (
		<div className={className}>
			<button ref={mainRef} type="button" onClick={handleToggle} style={{ width: '100%' }}>
				{renderMainComponent({ isOpen: isDropdownOpen })}
			</button>
			{isDropdownOpen && (
				<div ref={panelRef} style={{ position: 'relative' }}>
					{renderPanelComponent({ onClosePanel: () => setIsDropdownOpen(false) })}
				</div>
			)}
		</div>
	);
};

export default DropDown;
