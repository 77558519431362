/**
 * 建立或更新暫存訂單錯誤之列舉
 *
 * @export
 * @enum {number}
/**
 *
 *
 * @export
 * @enum {number}
 */
export enum ShoppingCartErrorType {
	/**
	 * 查無優惠券資料
	 */
	COUPON_NOT_FOUND = 'COUPON_NOT_FOUND',

	/**
	 * 該優惠券非本人所持有
	 */
	COUPON_NOT_BELONG_USER = 'COUPON_NOT_BELONG_USER',

	/**
	 * 該優惠券已使用過
	 */
	COUPON_IS_USED = 'COUPON_IS_USED',

	/**
	 * 該優惠券是否已作廢
	 */
	COUPON_IS_ARCHIVED = 'COUPON_IS_ARCHIVED',

	/**
	 * 該優惠券已過期
	 */
	COUPON_IS_EXPIRED = 'COUPON_IS_EXPIRED',

	/**
	 * 該優惠券對應活動已作廢
	 */
	COUPON_EVENT_IS_ARRCHIVED = 'COUPON_EVENT_IS_ARRCHIVED',

	/**
	 * 商品庫存不足
	 */
	PRODUCT_IS_OUT_OF_STOCK = 'PRODUCT_IS_OUT_OF_STOCK',

	/**
	 * 贈品庫存不足
	 */
	GIFT_IS_OUT_OF_STOCK = 'GIFT_IS_OUT_OF_STOCK',

	/**
	 * 圈存出現異常
	 */
	LOCK_STOCKS_ARE_FAILED = 'LOCK_STOCKS_ARE_FAILED',

	/**
	 * 暫存訂單前置作業發生異常
	 */
	PRE_PROCESS_TEMP_ORDER_FAILED = 'PRE_PROCESS_TEMP_ORDER_FAILED',

	/**
	 * 暫存訂單失敗
	 */
	TEMP_ORDER_FAILED = 'TEMP_ORDER_FAILED',

	/**
	 * 暫存訂單失敗
	 */
	TOKEN_INVALID = 'TOKEN_INVALID',

	/**
	 * 訂單已逾時, 圈存逾期
	 */
	LOCK_STOCKS_ARE_EXPIRED = 'LOCK_STOCKS_ARE_EXPIRED',

	/**
	 * 服務維護中
	 */
	SERVICE_IS_MAINTAINING = 'SERVICE_IS_MAINTAINING',

	/**
	 * 查無此訂單
	 */
	ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',

	/**
	 * 訂單已付款完成
	 */
	ORDER_ALREADY_PAYED = 'ORDER_ALREADY_PAYED',

	/**
	 * 訂單付款已逾期
	 */
	ORDER_PAYMENT_IS_EXPIRED = 'ORDER_PAYMENT_IS_EXPIRED',

	/**
	 * 訂單付款次數已達上限
	 */
	ORDER_PAYMENT_COUNT_EXCEEDED_LIMIT = 'ORDER_PAYMENT_COUNT_EXCEEDED_LIMIT',

	/**
	 * 訂單付款失敗
	 */
	ORDER_PAYMENT_FAILED = 'ORDER_PAYMENT_FAILED',

	/**
	 * 暫存訂單品項數量不得小於 1
	 */
	TEMP_ORDER_ITEMS_QUANTITY_HAS_ERROR = 'TEMP_ORDER_ITEMS_QUANTITY_HAS_ERROR',

	/**
	 * 此地址無法配送
	 */
	SHIP_ADDRESS_IS_NOT_SUPPORT = 'SHIP_ADDRESS_IS_NOT_SUPPORT',

	/**
	 *此地址無法宅配
	 */
	SHIP_TYPE_HOME_IS_NOT_SUPPORT = 'SHIP_TYPE_HOME_IS_NOT_SUPPORT',

	/**
	 * 超過每⽉限購1000單位
	 * */
	ORDER_ITEM_QUANTITY_EXCEEDED_LIMIT = 'ORDER_ITEM_QUANTITY_EXCEEDED_LIMIT',

	/**
	 * 優惠券尚未開始
	 * */
	COUPON_IS_NOT_START = 'COUPON_IS_NOT_START',

	/**
	 * Line 訂單發生異常
	 */
	LINE_SHOP_ORDER_ERROR = 'LINE_SHOP_ORDER_ERROR',
}
