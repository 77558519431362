/* eslint-disable indent */
import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import { useHistory } from 'models/routing';
import { History } from 'history';

import pushHistory from 'util/pushHistory';
import styles from './index.module.css';

export type LinkType = 'internal' | 'external';

const onClickHandler =
	(
		callback: (e: MouseEvent<HTMLAnchorElement>) => void,
		history: History,
		type: LinkType,
		to?: string,
	) =>
	(e: MouseEvent<HTMLAnchorElement>): void => {
		e.preventDefault();
		if (type === 'internal' && to) {
			pushHistory(history, to);
			window.scrollTo(0, 0);
		}
		if (type === 'external' && to) {
			if (to.includes('http://') || to.includes('https://')) {
				window.open(to, '_blank');
			} else {
				window.open(`https://${to}`, '_blank');
			}
		}
		callback(e);
	};

interface LinkProperty extends React.HTMLAttributes<HTMLAnchorElement> {
	className?: string;
	to?: string;
	type?: LinkType;
	onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
	children: React.ReactNode;
	style?: React.CSSProperties;
	showPointer?: boolean;
}

const Link: React.FC<LinkProperty> = ({
	className,
	to,
	type = 'internal',
	style = {},
	onClick = () => {},
	children,
	showPointer = true,
}) => {
	const history = useHistory();

	return (
		<a
			className={classNames(className, !showPointer && styles.noPointer)}
			style={style}
			href={to}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, type, to)}
			onKeyPress={() => {}}
		>
			{children}
		</a>
	);
};

export default Link;
